import { middlewareContext } from '@/router/index'

export default function middlewarePipeline (context: middlewareContext, middleware: void, index: number): () => void {
  const nextMiddleware = middleware[index]
  if (!nextMiddleware) {
    return context.next
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1)

    nextMiddleware({ ...context, nextMiddleware: nextPipeline })
  }
}
