




























import Vue from 'vue'

import { mixin as clickaway } from 'vue-clickaway'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'HeaderAccountBlock',
  mixins: [clickaway],
  computed: {
    ...mapGetters('profile', [
      'fullName'
    ]),
    dropdownClass () {
      return this.dropdownOpen ? 'open' : 'close'
    }
  },
  data () {
    return {
      dropdownOpen: false
    }
  },
  methods: {
    ...mapActions('notifications', [
      'addExceptions',
      'addNotification'
    ]),
    ...mapActions(['changeVision']),
    toProfile () {
      this.$router.push({ name: 'Profile' })
      this.dropdownOpen = false
      if (this.$store.state.sidebarControl.width < 1025) {
        this.changeVision()
      }
    },
    toggleDropdown () {
      this.dropdownOpen = !this.dropdownOpen
    },
    closeMenu () {
      this.changeVision()
    },
    logout () {
      this.$store.dispatch('auth/logout')
        .then(() => this.$router.push({ name: 'SignIn' }))
        .catch(er => {
          if (er.response?.data?.errors) {
            this.addExceptions(er.response.data)
          } else {
            this.addNotification({
              type: 'danger',
              body: 'Oops! Something went wrong! Please try later'
            })
          }
        })
    },
    away () {
      this.dropdownOpen = false
    }
  }
})
