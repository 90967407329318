import adsCampaign from '@/services/api/AdsManagerCampaignService'
import { SET_AD_PREVIEW, SET_LIST_PREVIEW } from '@/store/mutationsConstants/adsManagerMutations/mutationsAllAds'

interface stateAllAds {
  adPreview: any,
  list: any
}

interface requestBody {
  campaignId: string
  requestBody: {
    ad_id: string
    ad_format: string
  }
}

const initialState = (): stateAllAds => ({
  adPreview: null,
  list: {}
})

const getters = {
  getAdPreview (state: stateAllAds) {
    return state.adPreview
  },
  getListPreview (state: stateAllAds) {
    return state.list
  }
}

const mutations = {
  [SET_AD_PREVIEW] (state: stateAllAds, adPreview): void {
    state.adPreview = adPreview
  },
  [SET_LIST_PREVIEW] (state: stateAllAds, list): void {
    state.list = list
  }
}

const actions = {
  async load_ad_preview ({ dispatch }, data: requestBody) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_ad_preview(data)
        .then((response) => {
          dispatch('_setAdPreview', response.data.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
          error.response.data.errors.forEach(err => {
            dispatch('notifications/addNotification',
              { id: Date.now(), body: err.detail[0], type: 'danger' },
              { root: true })
          })
        })
    })
  },
  async load_list_preview ({ dispatch }, rq: any): Promise<void> {
    try {
      const response = await adsCampaign.get_ad_format(rq.campaignId, rq.payload)
      dispatch('_setListPreview', response.data.data)
    } catch (e) {
      console.log(e)
    }
  },
  _setAdPreview (context, adPreview) {
    context.commit(SET_AD_PREVIEW, adPreview)
  },
  _setListPreview (context, list) {
    context.commit(SET_LIST_PREVIEW, list)
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
