import IInvoices from '@/models/IInvoices'
import { ActionTree, MutationTree } from 'vuex'
import BillingService from '@/services/api/BillingService'
import { SET_INVOICE, SET_INVOICE_PAGINATION } from '@/store/mutationsConstants/mutationInvoices'

const initialState = (): IInvoices => ({
  data: null,
  meta: null
})

const mutations = <MutationTree<IInvoices>>{
  [SET_INVOICE] (state: IInvoices, data: any) {
    state.data = data
  },
  [SET_INVOICE_PAGINATION] (state: IInvoices, data: any) {
    state.meta = data
  }
}

const actions = <ActionTree<any, any>>{
  getBillingInvoices (context: any, data: any) {
    return new Promise((resolve, reject) => {
      BillingService.get_billing_invoices(data)
        .then(res => {
          context.commit(SET_INVOICE, res.data.data)
          context.commit(SET_INVOICE_PAGINATION, res.data.meta)
          resolve(res)
        })['catch'](er => reject(er))
    })
  }
}

const getters = {
  getInvoices (state: IInvoices) {
    return state.data
  },
  getPaginationInvoices (state: IInvoices) {
    return state.meta
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
