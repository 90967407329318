import IContactUsState from '@/models/IContactUsState'
import ContactUsService from '@/services/api/ContactUsService'
import { ActionTree } from 'vuex'
import { SET_TOPICS, SET_STATE_FIELD, SET_ERRORS, REMOVE_ERROR } from '@/store/mutationsConstants/mutationContactUs'
import Vue from 'vue'

const initialState = (): IContactUsState => ({
  first_name: '',
  last_name: '',
  email: '',
  topic: '',
  message: '',
  terms: true,
  captcha_value: '',
  topics: [],
  errors: [],
  result: false
})
const getters = {
  getErrors (state) {
    return state.errors
  },
  getTopics (state) {
    return state.topics
  },
  sendData (state) {
    return {
      name: state.first_name + ' ' + state.last_name,
      email: state.email,
      topic: state.topic.key,
      message: state.message,
      terms: state.terms,
      'g-recaptcha-response': state.captcha_value
    }
  }
}
const mutations = {
  [SET_ERRORS] (state, data) {
    state.errors = data
  },
  [REMOVE_ERROR] (state, data) {
    state.errors = state.errors.filter(error => error.source.parameter !== data)
  },
  [SET_TOPICS] (state, data) {
    state.topics = []
    Object.keys(data).forEach((item, index) => {
      state.topics.push({
        key: item,
        value: data[item]
      })
    })
  },
  [SET_STATE_FIELD] (state, { field, value }) {
    Vue.set(state, field, value)
  }
}
const actions = <ActionTree<IContactUsState, any>>{
  get_topics ({ dispatch, commit, state }:any) {
    return new Promise((resolve, reject) => {
      ContactUsService.get_topics()
        .then((response) => {
          commit(SET_TOPICS, response.data.data)
          commit(SET_STATE_FIELD, {
            field: 'topic',
            value: state.topics[0]
          })
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create_ticket ({ dispatch, commit, state, getters }) {
    return new Promise((resolve, reject) => {
      ContactUsService.create_ticket(getters.sendData)
        .then((response) => {
          state.result = true
          resolve(response)
        })
        .catch(error => {
          if (error.response) {
            commit(SET_ERRORS, error.response.data.errors)
          }
          reject(error)
        })
    })
  }
}
export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
