import { MutationTree, ActionTree } from 'vuex'
import { SET_SERVICE } from '@/store/mutationsConstants/mutationAdService'
import {
  FACEBOOK_SERVICE,
  FACEBOOK_SERVICE_INCLUDE,
  FACEBOOK_SERVICE_TITLE,
  GOOGLE_SERVICE,
  GOOGLE_SERVICE_INCLUDE,
  GOOGLE_SERVICE_TITLE,
  LINKEDIN_SERVICE,
  LINKEDIN_SERVICE_INCLUDE,
  LINKEDIN_SERVICE_TITLE,
  SLACK_SERVICE,
  SLACK_SERVICE_TITLE,
  SNAPCHAT_SERVICE,
  SNAPCHAT_SERVICE_INCLUDE,
  SNAPCHAT_SERVICE_TITLE,
  TIKTOK_SERVICE,
  TIKTOK_SERVICE_INCLUDE,
  TIKTOK_SERVICE_TITLE,
  TWITTER_SERVICE, TWITTER_SERVICE_INCLUDE,
  TWITTER_SERVICE_TITLE
} from '@/constants/FbAutomatedRule'

interface IAdService {
  service: string | null
}

const initialState = (): IAdService => ({
  service: 'facebook'
})

const mutations = <MutationTree<IAdService>>{
  [SET_SERVICE] (state: IAdService, service: string) {
    state.service = service
  }
}

const actions = <ActionTree<IAdService, string>> {
  setService ({ commit }, service: string) {
    commit(SET_SERVICE, service)
  }
}

const getters = {
  getService (state: IAdService): string {
    return state.service
  },
  getServiceTitle (state: IAdService): string {
    switch (state.service) {
      case FACEBOOK_SERVICE:
        return FACEBOOK_SERVICE_TITLE
      case SNAPCHAT_SERVICE:
        return SNAPCHAT_SERVICE_TITLE
      case GOOGLE_SERVICE:
        return GOOGLE_SERVICE_TITLE
      case TIKTOK_SERVICE:
        return TIKTOK_SERVICE_TITLE
      case LINKEDIN_SERVICE:
        return LINKEDIN_SERVICE_TITLE
      case TWITTER_SERVICE:
        return TWITTER_SERVICE_TITLE
      case SLACK_SERVICE:
        return SLACK_SERVICE_TITLE
      default:
        return ''
    }
  },
  getServiceTitles (state, rootState, getters, rootGetters): Record<string, string> {
    const services = {
      [FACEBOOK_SERVICE]: FACEBOOK_SERVICE_TITLE,
      [SNAPCHAT_SERVICE]: SNAPCHAT_SERVICE_TITLE,
      [GOOGLE_SERVICE]: GOOGLE_SERVICE_TITLE,
      [TIKTOK_SERVICE]: TIKTOK_SERVICE_TITLE,
      [LINKEDIN_SERVICE]: LINKEDIN_SERVICE_TITLE,
      [TWITTER_SERVICE]: TWITTER_SERVICE_TITLE
    }
    const displayedServices = {}
    for (const service in services) {
      if (rootGetters['get_sidebar_views']['rules'][service]) {
        displayedServices[service] = services[service]
      }
    }
    return displayedServices
  },
  getServiceIconSrc (state: IAdService): string {
    switch (state.service) {
      case FACEBOOK_SERVICE:
        return require('@/assets/icons/social/facebook-icon.svg')
      case SNAPCHAT_SERVICE:
        return require('@/assets/icons/social/snapchat.svg')
      case TIKTOK_SERVICE:
        return require('@/assets/img/tiktok-icon.svg')
      case LINKEDIN_SERVICE:
        return require('@/assets/img/linkedin-icon.svg')
      case GOOGLE_SERVICE:
        return require('@/assets/icons/social/google.svg')
      case TWITTER_SERVICE:
        return require('@/assets/img/twitter-icon.svg')
      default:
        return ''
    }
  },
  getServiceInclude (state: IAdService): string {
    switch (state.service) {
      case FACEBOOK_SERVICE:
        return FACEBOOK_SERVICE_INCLUDE
      case SNAPCHAT_SERVICE:
        return SNAPCHAT_SERVICE_INCLUDE
      case TIKTOK_SERVICE:
        return TIKTOK_SERVICE_INCLUDE
      case LINKEDIN_SERVICE:
        return LINKEDIN_SERVICE_INCLUDE
      case GOOGLE_SERVICE:
        return GOOGLE_SERVICE_INCLUDE
      case TWITTER_SERVICE:
        return TWITTER_SERVICE_INCLUDE
      default:
        return ''
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
