import { FACEBOOK_SERVICE } from '@/constants/FbAutomatedRule'
import { middlewareContext } from '@/router'

export default function checkAdAccount ({ next, store, nextMiddleware, to }: middlewareContext): void | Promise<unknown> {
  let service = FACEBOOK_SERVICE
  if (to.params?.service) {
    service = to.params.service
  } else {
    service = to.meta?.service || FACEBOOK_SERVICE
  }
  if (store.getters['adAccounts/getLoader'] || store.getters['adAccounts/getService'] !== service) { // if ad accounts not loaded
    store.dispatch('adAccounts/resetState').then(() => {
      store.dispatch('adAccounts/loadAccounts', {
        search: {
          service: service,
          target: 'ads_management'
        }
      })
        .then(() => {
          if (store.state.adAccounts.data.length === 0) {
            return next({ name: 'WithoutAdAccount', params: { service } })
          } else {
            return nextMiddleware()
          }
        }).catch(e => {
          store.dispatch('notifications/addNotification', {
            type: 'danger',
            body: 'Something went wrong! Please retry later'
          })
        })
    })
  } else {
    if (store.state.adAccounts.data.length === 0) {
      return next({ name: 'WithoutAdAccount', params: { service } })
    } else {
      return nextMiddleware()
    }
  }
}
