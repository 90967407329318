import { ActionTree, MutationTree } from 'vuex'
import { SET_VERSION, SET_MAINTENANCE_MODE } from '@/store/mutationsConstants/mutationApp'
import AppConfigService from '@/services/api/AppConfigService'
import { AxiosResponse } from 'axios'

interface AppConfigState {
  version: string,
  maintenance_mode: boolean
}

const initialState = (): AppConfigState => ({
  version: process.env.PACKAGE_VERSION || '0',
  maintenance_mode: process.env.VUE_APP_MAINTENANCE_MODE === 'true' || false
})

const mutations = <MutationTree<AppConfigState>>{
  [SET_VERSION] (state: AppConfigState, version: string) {
    state.version = version
  },
  [SET_MAINTENANCE_MODE] (state: AppConfigState, mode: boolean) {
    state.maintenance_mode = mode
  }
}

const actions = <ActionTree<AppConfigState, any>>{
  setVersion ({ commit }, version) {
    commit(SET_VERSION, version)
  },
  setMaintenanceMode ({ commit }, mode) {
    commit(SET_MAINTENANCE_MODE, mode)
  },
  async getVersionFromServer (_): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      AppConfigService.getVersionFile()
        .then(response => {
          resolve(response.data?.version)
        })
        .catch(err => reject(err))
    })
  },
  async getMaintenanceModeFromServer ({ dispatch }): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      AppConfigService.getVersionFile()
        .then(response => {
          if (response.data?.maintenance_mode !== null) {
            dispatch('setMaintenanceMode', response.data.maintenance_mode)
          }
          resolve(response.data?.maintenance_mode)
        })
        .catch(e => reject(e))
    })
  }
}

const getters = {
  getVersion (state: AppConfigState): string {
    return state.version
  },
  getMaintenanceMode (state: AppConfigState): boolean {
    return state.maintenance_mode
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
