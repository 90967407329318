import http from '@/http-common'
import { AxiosResponse } from 'axios'

export default {
  getVersionFile (): Promise<AxiosResponse> {
    return http.get('/version.json', {
      baseURL: '/',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      _withoutToken: true
    })
  }
}
