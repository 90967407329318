import router, { middlewareContext } from '@/router'
import { FACEBOOK_SERVICE } from '@/constants/FbAutomatedRule'

export default function checkPermissions ({ from, store, to, nextMiddleware }: middlewareContext): void | Promise<unknown> {
  let service: string
  if (to.params?.service) {
    service = to.params.service
  } else {
    service = to.meta?.service || FACEBOOK_SERVICE
  }
  if (!to.meta.permissionCheckKey || (store.getters['profile/getUserPermissions'] && store.getters['profile/getUserPermissions'][to.meta.permissionCheckKey][service])) {
    return nextMiddleware()
  } else {
    const user = store.getters['profile/currentUser']
    if (user && user.plan_is_expired) {
      store.commit('profile/setDiscardedPermission', to.meta.permissionCheckKey)
      if (from.name !== 'TrialExpired') {
        return router.push({ name: 'TrialExpired' })
      }
    } else if (user) {
      if (from.name !== 'FeatureUnavailable') {
        return router.push({ name: 'FeatureUnavailable' })
      }
    }
    return null
  }
}
