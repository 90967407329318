import IPlansState from '@/models/IPlansState'
import { ActionTree, MutationTree } from 'vuex'
import Jsona from 'jsona'
import BillingService from '@/services/api/BillingService'
import { SET_PLANS, RESET_PLANS } from '@/store/mutationsConstants/mutationPlans'

const initialState = () : IPlansState => ({
  plans: null
})

const mutations = <MutationTree<IPlansState>> {
  [SET_PLANS] (state: IPlansState, data: any) {
    state.plans = data
  },
  [RESET_PLANS] (state: IPlansState) {
    state.plans = null
  }
}

const actions = <ActionTree<any, any>> {
  async getBillingInformation (context: any) {
    const response = await BillingService.get_available_plans().then((res) => {
      const dataFormatter = new Jsona()
      context.commit(SET_PLANS, dataFormatter.deserialize(res.data))
    })
  },
  async subscribe (context: any, { ps, frequency, plan_id }) {
    return await BillingService.subscribe(ps, frequency, plan_id)
  },
  async executeSubscription (context: any, { hash, token }) {
    return await BillingService.execute_subscription(hash, token)
  },
  async unsubscribe (context: any, { reason, reason_text }) {
    return await BillingService.unsubscribe(reason, reason_text)
  },
  resetPlans ({ dispatch }) {
    dispatch(RESET_PLANS)
  }
}

const getters = {
  getPlans (state: IPlansState) {
    return state
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
