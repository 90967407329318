import { middlewareContext } from '@/router'

export default function emailConfirmed ({ next, store, nextMiddleware }: middlewareContext): void | Promise<unknown> {
  if (!store.getters['profile/currentUser'].id) {
    store
      .dispatch('profile/getProfile')
      .then(() => {
        if (store.getters['profile/currentUser'].email_confirmed) {
          return nextMiddleware()
        } else {
          return next({ name: 'Activate' })
        }
      }).catch(e => {
        if (e.response.status === 401) {
          store.dispatch('auth/resetSession')
          return next({ name: 'SignIn' })
        } else {
          store.dispatch('notifications/addNotification', { type: 'danger', body: 'Something went wrong! Please retry later' })
        }
      })
  } else {
    if (store.getters['profile/currentUser'].email_confirmed) {
      return nextMiddleware()
    } else {
      return next({ name: 'Activate' })
    }
  }
}
