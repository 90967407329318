import IProfileState from '@/models/IProfileState'
import IUserUses from '@/models/IUserUses'
import { ActionTree, MutationTree } from 'vuex'
import UserService from '@/services/api/UserService'
import Jsona from 'jsona'
import INotificationChange from '@/models/INotificationChange'
import IRegisterUser from '@/models/IRegisterUser'
import BillingService from '@/services/api/BillingService'
import {
  SET_PROFILE,
  SET_PERMISSIONS,
  CLEAR_PROFILE,
  SET_DISCARDED_PERMISSION,
  SET_USES,
  SET_REASONS_LIST
} from '@/store/mutationsConstants/mutationProfile'
import IUserPermissions from '@/models/IUserPermissions'
import { SET_VIEWS } from '@/store/mutationsConstants/mutationSidebarControl'

const initialState = () : IProfileState => ({
  id: null,
  name: null,
  last_name: null,
  email: null,
  phone: null,
  email_confirmed: null,
  account_type: null,
  email_key: null,
  confirm_status: null,
  current_plan: null,
  billing_info: null,
  summary: null,
  agreement: null,
  plan_boost: null,
  email_news: null,
  email_system: null,
  email_analyze: null,
  has_deleted_push_endpoint: null,
  paypal_account_id: null,
  stripe_account_id: null,
  permissions: null,
  uses: null,
  discardedPermission: null,
  fb_login_url: null,
  snap_login_url: null,
  tiktok_login_url: null,
  linkedin_login_url: null,
  google_login_url: null,
  twitter_login_url: null,
  slack_login_url: null,
  plan_expires_date: null,
  plan_is_expired: false,
  reasons_list: [],
  deletion_requested: null,
  remaining_integrations: 0
})

const mutations = <MutationTree<IProfileState>> {
  [SET_PROFILE] (state: IProfileState, data: Record<any, any>) {
    state.id = data.id
    state.account_type = data.account_type
    state.name = data.name
    state.last_name = data.last_name
    state.email = data.email
    state.phone = data.phone
    state.email_confirmed = data.email_confirmed
    state.email_news = data.email_news
    state.email_system = data.email_system
    state.email_analyze = data.email_analyze
    state.confirm_status = data.confirm_status
    data.current_plan && (state.current_plan = data.current_plan)
    state.billing_info = data.billing_info
    state.summary = data.summary
    state.agreement = data.agreement
    state.plan_boost = data.plan_boost
    state.has_deleted_push_endpoint = data.has_deleted_push_endpoint
    state.paypal_account_id = data.paypal_account_id
    state.stripe_account_id = data.stripe_account_id
    state.fb_login_url = data.fb_login_url
    state.snap_login_url = data.snap_login_url
    state.tiktok_login_url = data.tiktok_login_url
    state.linkedin_login_url = data.linkedin_login_url
    state.google_login_url = data.google_login_url
    state.twitter_login_url = data.twitter_login_url
    state.slack_login_url = data.slack_login_url
    state.plan_expires_date = data.plan_expires_date
    state.plan_is_expired = data.plan_is_expired
    state.deletion_requested = data.deletion_requested
    state.remaining_integrations = data.remaining_integrations
  },
  [SET_USES] (state: IProfileState, uses: IUserUses) {
    state.uses = uses
  },
  [SET_PERMISSIONS] (state: IProfileState, permissions: IUserPermissions) {
    state.permissions = permissions
  },
  [SET_DISCARDED_PERMISSION] (state: IProfileState, permission: string) {
    state.discardedPermission = permission
  },
  [SET_REASONS_LIST] (state: IProfileState, data:any) {
    state.reasons_list = data
  },
  [CLEAR_PROFILE] (state: IProfileState) {
    const cleanState = initialState()

    for (const field in state) {
      state[field] = cleanState[field]
    }
  }
}

const actions = <ActionTree<any, any>> {
  async registerUser ({ dispatch, commit }, data: IRegisterUser) {
    try {
      const response = await UserService.register(data)
      const dataFormatter = new Jsona()
      commit(SET_PROFILE, dataFormatter.deserialize(response.data))
      commit(SET_PERMISSIONS, response.data.meta.permissions)
      dispatch('auth/setSession', response.data.meta.access_token, { root: true })
    } catch (e) {
      dispatch('exceptions/addExceptions', e.response.data, { root: true })
      console.log(e)
      throw e
    }
  },
  async getProfile ({ commit, dispatch }) {
    return UserService.get_profile({
      'include[0]': 'current_plan',
      'include[1]': 'summary',
      'include[2]': 'agreement',
      'include[3]': 'plan_boost'
    }).then((res) => {
      const dataFormatter = new Jsona()
      commit(SET_PROFILE, dataFormatter.deserialize(res.data))
      commit(SET_PERMISSIONS, res.data.meta.permissions)
      commit(SET_USES, res.data.meta.uses)
      return dispatch(SET_VIEWS, res.data.meta.views, { root: true })
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        dispatch('auth/resetSession', null, { root: true })
      }
      throw err
    })
  },
  toggleNewsNotifications ({ commit }, data: INotificationChange) {
    return new Promise((resolve, reject) => {
      UserService.toggle_notifications_email_news(data)
        .then((response) => {
          const dataFormatter = new Jsona()
          const res = dataFormatter.deserialize(response.data)
          commit(SET_PROFILE, res)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async getBillingInformation ({ dispatch }) {
    try {
      const response = await BillingService.get_available_plans()
      dispatch('createToasterMessage', response)
    } catch (e) {
      console.log(e)
    }
  },
  updateProfile (context, data) {
    return new Promise((resolve, reject) => {
      UserService.update_profile(data)
        .then(response => {
          const dataFormatter = new Jsona()
          context.commit(SET_PROFILE, dataFormatter.deserialize(response.data))
          // response has not response->data->meta field
          if (response.data.meta) {
            context.commit(SET_PERMISSIONS, response.data.meta.permissions)
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getDeleteReasonsList (context) {
    return new Promise((resolve, reject) => {
      UserService.get_delete_reasons_list()
        .then(response => {
          const dataFormatter = new Jsona()
          context.commit(SET_REASONS_LIST, dataFormatter.deserialize(response.data))
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  deleteProfile (context, data) {
    return new Promise((resolve, reject) => {
      UserService.delete_profile(data)
        .then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
    })
  },
  accountRecovery () {
    return new Promise((resolve, reject) => {
      UserService.account_recovery()
        .then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
    })
  },
  unconfirmedChangeEmail (context, email) {
    return new Promise((resolve, reject) => {
      UserService.unconfirmed_change_email(email)
        .then(response => {
          const dataFormatter = new Jsona()
          context.commit(SET_PROFILE, dataFormatter.deserialize(response.data))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  resendConfirmationEmail (context) {
    return new Promise((resolve, reject) => {
      UserService.resend_confirmation_email()
        .then(response => {
          const dataFormatter = new Jsona()
          context.commit(SET_PROFILE, dataFormatter.deserialize(response.data))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sendConfirmEmailRequest ({ commit, dispatch }, { email, token }) {
    return new Promise((resolve, reject) => {
      UserService
        .sendConfirmEmailRequest({ email, token })
        .then(response => {
          const dataFormatter = new Jsona()
          commit(SET_PROFILE, dataFormatter.deserialize(response.data))
          commit(SET_PERMISSIONS, response.data.meta.permissions)
          commit(SET_USES, response.data.meta.uses)
          dispatch(SET_VIEWS, response.data.meta.views, { root: true })
          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
}

const getters = {
  getName (state: IProfileState): string | null {
    return state.name
  },
  currentUser (state: IProfileState): IProfileState {
    return state
  },
  getAgreement (state: IProfileState): any {
    return state.agreement
  },
  fullName (state: IProfileState): string {
    return state.name + ' ' + state.last_name
  },
  getCurrentPlan (state: IProfileState): any {
    return state.current_plan
  },
  getUserPermissions (state: IProfileState): IUserPermissions {
    return state.permissions
  },
  getUserUses (state: IProfileState): IUserPermissions {
    return state.uses
  },
  getDiscardedPermission (state: IProfileState): string {
    return state.discardedPermission
  },
  getEmail (state: IProfileState): string | null {
    return state.email
  },
  getReasonsList (state: IProfileState): Array<any> {
    return state.reasons_list
  },
  getDeletionRequested (state: IProfileState): boolean | null {
    return state.deletion_requested
  },
  getUserRemainingIntegrations (state: IProfileState): number | null {
    return state.remaining_integrations
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
