import { ActionTree, MutationTree } from 'vuex'
import IException from '@/models/IException'
import IExceptionItem from '@/models/IExceptionItem'
import {
  ADD_EXCEPTION,
  ADD_EXCEPTIONS,
  CLEAR_EXCEPTIONS,
  CLEAR_EXCEPTION_BY_SOURCE_PARAMETER
} from '@/store/mutationsConstants/mutationExceptions'

const initialState = {
  errors: []
}

const mutations = <MutationTree<IException>> {
  [ADD_EXCEPTION] (state: IException, exception: IExceptionItem) {
    state.errors.push(exception)
  },
  [ADD_EXCEPTIONS] (state: IException, exceptions: IException) {
    state.errors = state.errors.concat(exceptions.errors)
  },
  [CLEAR_EXCEPTIONS] (state: IException) {
    state.errors = []
  },
  [CLEAR_EXCEPTION_BY_SOURCE_PARAMETER] (state: IException, parameter: string) {
    state.errors = state.errors.filter(error => error?.source.parameter !== parameter)
  }
}
const actions = <ActionTree<any, any>> {
  addExceptions (context, data: IException) {
    context.commit(ADD_EXCEPTIONS, data)
  },
  clearExceptions (context) {
    context.commit(CLEAR_EXCEPTIONS)
  }
}

const getters = {
  exceptionMessageBySourceParameter: (state: IException) => (parameter: string): string | null => {
    const exception = state.errors.find(n => {
      return n?.source.parameter === parameter
    })
    return exception?.detail[0]
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
