export const SET_STATE_FIELD = 'setStateField'
export const SET_STATE_FIELD_PROP = 'setStateFieldProp'
export const ADD_ERROR = 'addError'
export const REMOVE_ERROR = 'removeError'
export const REMOVE_ERROR_ANY = 'removeErrorAny'
export const REMOVE_ERROR_BY_DETAIL = 'removeErrorByDetail'
export const CHANGE_CHILD_ATTACHMENT = 'changeChildAttachment'
export const RESET_CHILD_ATTACHMENTS = 'resetChildAttachments'
export const ACTIVATE_CHILD_ATTACHMENT = 'activateChildAttachment'
export const DELETE_CHILD_ATTACHMENT = 'deleteChildAttachment'
export const OVERRIDING_ID = 'overridingId'
export const AD_IMAGE_IN_ATTACHMENT = 'adImageInAttachment'
export const TOGGLE_PLACEMENT = 'togglePlacement'
export const GENERATE_VARIATIONS = 'generateVariations'
export const RESET_AD_DESIGN_DATA = 'resetAdDesignData'
export const CHANGE_HEADLINE_STATE = 'changeHeadlineState'
export const CHANGE_PRIMARY_TEXT_STATE = 'changePrimaryTextState'
export const CHANGE_DESCRIPTION_STATE = 'changeDescriptionState'
export const CHANGE_CALL_ACTION_STATE = 'changeCallActionState'
export const CHANGE_TARGET_LINK_STATE = 'changeTargetLinkState'
export const CHANGE_DISPLAY_LINK_STATE = 'changeDisplayLinkState'
export const REMOVE_IMAGE_FROM_AD_DESIGN_DATA = 'removeImageFromAdDesignData'
export const SET_EDITABLE_CREATIVE = 'setEditableCreative'
export const RESET_EDITABLE_CREATIVE = 'resetEditableCreative'
export const CLEAR_STATE = 'clearState'
