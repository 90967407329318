import { ActionTree, MutationTree } from 'vuex'
import Jsona from 'jsona'
import FoldersService from '@/services/api/FoldersService'
import IFolders from '@/models/IFolders'
import Vue from 'vue'
import IRule from '@/models/Rules/IRule'
import _ from 'lodash'
import RulesService from '@/services/api/RulesService'
import IFolder from '@/models/IFolder'

const initialState = () : IFolders => ({ data: [], meta: null, errors: [], name: '', processing: false })

const findFolderById = (state: IFolders, id: IFolders['data'][number]['id']): IFolders['data'][number] | undefined => {
  return state.data.find(folder => folder.id === id)
}

const mutations = <MutationTree<IFolders>> {
  openFolder (state: IFolders, folder_id: IFolders['data'][number]['id']) {
    const folder = findFolderById(state, folder_id)

    if (folder) {
      Vue.set(folder, 'opened', true)
    }
  },
  toggleFolder (state: IFolders, folder_id: IFolders['data'][number]['id']) {
    const folder = findFolderById(state, folder_id)

    if (folder) {
      Vue.set(folder, 'opened', !folder.opened)
    }
  },
  setProcessing (state: IFolders, data: IFolders) {
    state.processing = data.processing
  },
  setFolders (state: IFolders, data: IFolders) {
    state.data = data.data
    state.meta = data.meta
  },
  addFolders (state: IFolders, data: IFolders) {
    state.data = state.data.concat(data.data)
    state.meta = data.meta
  },
  mergeFolders (state: IFolders, data: IFolders) {
    data.data.forEach(folder => {
      const stateFolder = state.data.find(f => f.id === folder.id)
      if (stateFolder) {
        stateFolder.rules.concat(folder.rules as [])
      } else {
        state.data.push(folder)
      }
    })
    state.meta = data.meta
  },
  replaceFolderRules (state: IFolders, { folder_id, rules }) {
    const folder = state.data.find((f) => +f.id === +folder_id)
    if (folder) {
      folder.rules = rules
    }
  },
  addRulesToFolder (state: IFolders, { folder_id, rules }) {
    const folder = state.data.find((f) => +f.id === +folder_id)
    if (folder) {
      folder.rules = [...folder.rules, ...rules]
    }
  },
  setFolderProp (state: IFolders, { folder, prop, value }) {
    const stateFolder = state.data.find((fold) => fold.id === folder.id)
    Vue.set(stateFolder, prop, value)
  },
  setName (state: IFolders, name) {
    state.name = name
  },
  setRuleProp (state: IFolders, { rule_id, type, value }) {
    let rule = null
    _.forEach(state.data, folder => {
      rule = folder.rules.find(r => +r.id === +rule_id)
      return !rule
    })
    if (rule) {
      Vue.set(rule, type, value)
    }
  }
}
const actions = <ActionTree<IFolders, any>>{
  async loadFolders ({ dispatch, rootGetters }, { page = 1 }) {
    try {
      const response = await FoldersService.getFolders(rootGetters['adService/getService'], page)
      const dataFormatter = new Jsona()
      const callback = () => {
        if (
          response.data.meta.pagination.current_page <
          response.data.meta.pagination.total_pages
        ) {
          dispatch('loadFolders', { service: rootGetters['adService/getService'], page: page + 1 })
        }
      }
      if (page === 1) {
        dispatch('setFolders', {
          data: dataFormatter.deserialize(response.data),
          meta: response.data.meta
        }).then(callback)
      } else {
        dispatch('addFolders', {
          data: dataFormatter.deserialize(response.data),
          meta: response.data.meta
        }).then(callback)
      }
    } catch (e) {
      console.log(e)
    }
  },
  async loadFolderRules ({ dispatch, rootGetters }, { folder_id, page = 1 }) {
    try {
      const response = await RulesService.getRules(rootGetters['adService/getService'], { folder_id: folder_id, per_page: 50 }, page)
      const dataFormatter = new Jsona()
      const dataDeserialized = { data: dataFormatter.deserialize(response.data), meta: response.data.meta }
      if (page === 1) {
        await dispatch('replaceFolderRules', { folder_id: folder_id, rules: dataDeserialized.data })
      } else {
        await dispatch('addRulesToFolder', { folder_id: folder_id, rules: dataDeserialized.data })
      }
      if (dataDeserialized.meta?.pagination?.total_pages > page) {
        dispatch('loadFolderRules', { folder_id: folder_id, page: page + 1 })
      }
    } catch (e) {
      console.log(e)
    }
  },
  replaceFolderRules (context, data) {
    context.commit('replaceFolderRules', data)
  },
  addRulesToFolder (context, data) {
    context.commit('addRulesToFolder', data)
  },
  setFolders (context, folders: IFolders) {
    context.commit('setFolders', folders)
  },
  addFolders (context, folders: IFolders) {
    context.commit('addFolders', folders)
  },
  clearState ({ commit }) {
    commit('setFolders', { data: [], meta: null })
  },
  mergeFolders ({ commit }, data) {
    commit('mergeFolders', data)
  },
  setFolderProp (context, { folder, prop, value }) {
    context.commit('setFolderProp', { folder, prop, value })
  },
  setName ({ commit }, name) {
    commit('setName', name)
  },
  toggleFolder ({ commit }, id: number) {
    commit('toggleFolder', id)
  },
  creatingFolder ({ dispatch, state, rootGetters }, { name }) {
    if (name) {
      state.name = name
    }
    return new Promise((resolve, reject) => {
      FoldersService.createFolder(rootGetters['adService/getService'], { name: state.name })
        .then(res => {
          dispatch('loadFolders', {})
          dispatch('notifications/addNotification',
            { id: Date.now(), body: 'Folder created!', type: 'success' },
            { root: true })
          resolve({ status: res.status, folder_id: res.data.data.id })
          state.processing = false
          state.name = ''
        })
        .catch(error => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
            { root: true }
          )
          state.errors = error.response.data.errors
          console.log(error)
          reject(error)
          state.processing = false
        })
    })
  },
  renamingFolder ({ dispatch, commit, state, rootGetters }, { data }) {
    return new Promise((resolve, reject) => {
      FoldersService.renameFolder(rootGetters['adService/getService'], { id: data.id, name: data.name })
        .then(res => {
          dispatch('loadFolders', {})
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Folder renamed!', type: 'success' },
            { root: true }
          )
          resolve({ status: res.status })
        })
        .catch(error => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
            { root: true }
          )
          state.errors = error.response.data.errors
          console.log(error)
          reject(error)
        })
    })
  },
  deletingFolder ({ commit, state, dispatch, rootGetters }, { id }) {
    return new Promise((resolve, reject) => {
      FoldersService.deleteFolder(rootGetters['adService/getService'], { id: id })
        .then(res => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Folder deleted!', type: 'success' },
            { root: true }
          )
          dispatch('loadFolders', {})
          resolve({ status: res.status, folder_id: id })
        })
        .catch(error => {
          state.errors = error.response.data //  Something wrong: console log saying 'Cannot read properties of undefined (reading 'data')'
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
            { root: true }
          )
          commit('setFolderErrors', error.response.data.name)
          console.log(error)
          reject(error)
        })
    })
  },
  setRuleProp ({ commit }, { rule_id, type, value }) {
    commit('setRuleProp', { rule_id: rule_id, type: type, value: value })
  },
  setRulesStatus ({ dispatch }, { ids, status }) {
    ids.forEach(i => {
      dispatch('setRuleProp', { rule_id: i, type: 'status', value: status })
    })
  }
}
const getters = {
  getError (state) {
    return function (field) {
      return state.errors.find(v => v.source.parameter === field)?.detail[0]
    }
  },
  getName (state: IFolders) {
    return state.name
  },
  getProcessing (state: IFolders) {
    return state.processing
  },
  getAllFolders (state: IFolders): IFolders {
    return state
  },
  getFolderRuleById: (state: IFolders) => (folderId: number, ruleId: number): IRule => {
    const folder = state.data.find((f: IFolder) => f.id === folderId)
    return folder.rules.find((r: any) => r.id === ruleId)
  },
  getRulesByFolderId: (state) => (folderId) => {
    return state.data.filter(v => v.id === String(folderId))[0].rules
  },
  getRuleById: (state: IFolders) => (ruleId: number): IRule | null => {
    let rule = null
    state.data.some(folder => {
      rule = folder.rules.find((r: IRule) => +r.id === ruleId)
      return !!rule
    })
    return rule
  },
  getFolderById: (state: IFolders) => (folderId: number|string): IFolder | null => {
    return state.data.find((f: IFolder) => +f.id === +folderId)
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
