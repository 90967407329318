



















import Vue from 'vue'
import NewUiNotifications from '@/components/NewUIComponents/NewUiNotifications.vue'
import Header from '@/components/TheHeader.vue'
import Sidebar from '@/components/TheSidebar.vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'MainLayout',
  components: {
    NewUiNotifications,
    Header,
    Sidebar
  },
  data () {
    return {
      width: 0
    }
  },
  props: {
    msg: String,
    sidebarMenuVisible: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions(['updateWidth']),
    screenWidth () {
      this.width = window.innerWidth
      this.updateWidth(this.width)
    }
  },
  computed: {
    ...mapGetters(['get_sidebar_open_state', 'get_width']),
    ...mapGetters('auth', ['auth_manually'])
  },
  created () {
    this.width = window.innerWidth
    this.updateWidth(this.width)
    window.addEventListener('resize', this.screenWidth)
  }
})
