import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import store from './store'
import { decodeJwt } from 'jose'
import { saveCookie } from '@/utils/cookie'
import { getData as getBrowserData } from '@/utils/browserData'
import { isString } from 'lodash'

export const saveTokenToCookie = () => {
  // const Exp = jwt.decode(refreshToken) as { [key: string]: number }
  // const DateExp = new Date(Date.now() + Exp.exp / 1000)
  const DateExp = new Date(Date.now() + Number(process.env.VUE_APP_REFRESH_TOKEN_TTL || 3600) * 1000)
  const hostname = document.location.hostname.split('.').slice(-2).join('.')
  saveCookie({ key: 'access_token', value: 'true', path: '/', expires: DateExp, domain: hostname })
}

const authTokenKey = 'Authorization'
const authTokenTemplate = (token: string) => `Bearer ${token}`

export interface CustomAxiosConfig extends AxiosRequestConfig {
  _withoutToken?: boolean | null
}

const setAuthToken = (config: CustomAxiosConfig, token: string) => {
  if (config.headers) {
    config.headers[authTokenKey] = authTokenTemplate(token)
  }
}

const axiosConfig = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cookie-Enabled': getBrowserData().cookieEnabled,
    'Hardware-Concurrency': getBrowserData().hardwareConcurrency,
    webdriver: getBrowserData().webdriver,
    platform: getBrowserData().platform,
    vendor: getBrowserData().vendor,
    timezone: getBrowserData().timezone
  },
  withCredentials: true,
  _withoutToken: false
}

const axiosInstance = axios.create(axiosConfig)

function getExpires (jwt: string): number {
  const { exp } = decodeJwt(jwt)
  return exp
}

function accessTokenExpired (jwt: string): boolean {
  return Date.now() + (10 * 1000) > getExpires(jwt) * 1000
}

type Subscriber = (token: string | null, error: any) => void

let isFetchingToken = false
let tokenSubscribers: Subscriber[] = []

function forceLogout () {
  isFetchingToken = false
  store.dispatch('auth/resetSession')
  window.location.href = '/registration/sign-in'
}

function subscribeTokenRefresh (cb: Subscriber) {
  tokenSubscribers.push(cb)
}
function onTokenRefreshed (token, errRefreshing) {
  tokenSubscribers.map(cb => cb(token, errRefreshing))
}

interface IRefreshRequestResponse {
  accessToken: string
}

const createSetAuthInterceptor = () => async (config: CustomAxiosConfig): Promise<CustomAxiosConfig> => {
  if (config._withoutToken) {
    return config
  }
  if (!isString(config.url)) {
    try {
      config.url = config.url.toString()
    } catch (e) {
      console.log('unexpected url type')
      return config
    }
  }
  if (config.url.includes('auth/refresh')) {
    isFetchingToken = true
    return new Promise((resolve, reject) => {
      return resolve(config)
    }).finally(() => {
      isFetchingToken = false
    })
  }
  try {
    let needRefresh = false
    if (isFetchingToken) {
      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((token, errRefreshing) => {
          if (errRefreshing) {
            return reject(errRefreshing)
          }
          setAuthToken(config, token)
          return resolve(config)
        })
      })
    }
    if (store.getters['auth/access_token']) {
      if (accessTokenExpired(store.getters['auth/access_token'])) {
        needRefresh = true
      }
    } else {
      needRefresh = true
    }
    if (needRefresh) {
      await requestRefresh()
        .then(newToken => {
          onTokenRefreshed(newToken.accessToken, null)
          setAuthToken(config, newToken.accessToken)
          tokenSubscribers = []
        }).catch((error) => {
          onTokenRefreshed(null, error)
          tokenSubscribers = []
          return Promise.reject(error)
        })
    } else {
      setAuthToken(config, store.getters['auth/access_token'])
    }
    return config
  } catch (e) {
    onTokenRefreshed(null, e)
    tokenSubscribers = []
    return Promise.reject(e)
  }
}

const setAuthCb = createSetAuthInterceptor()
axiosInstance.interceptors.request.use(setAuthCb)

axiosInstance.interceptors.response.use(undefined, err => {
  if (err.response.config &&
    (err.response.config.url.includes('auth/login') ||
    err.response.config.url.includes('auth/logout') ||
    err.response.config.url.includes('auth/refresh'))) {
    return Promise.reject(err)
  }

  if (err.response.status === 403) return forceLogout()
  if (err.response.status !== 401) return Promise.reject(err)

  if (!isFetchingToken) {
    isFetchingToken = true

    requestRefresh()
      .then(newAccessToken => {
        isFetchingToken = false

        onTokenRefreshed(newAccessToken.accessToken, null)
        tokenSubscribers = []

        store.dispatch('auth/setSession', newAccessToken.accessToken)
      })
      .catch(() => {
        onTokenRefreshed(null, new Error('Unable to refresh access token'))
        tokenSubscribers = []

        forceLogout()
      })
  }

  return new Promise((resolve, reject) => {
    subscribeTokenRefresh((newToken, errRefreshing) => {
      if (errRefreshing) return reject(errRefreshing)

      setAuthToken(err.config, newToken)
      return resolve(axios(err.config))
    })
  })
})

const requestRefresh = async (): Promise<IRefreshRequestResponse > => {
  try {
    isFetchingToken = true
    const response: AxiosResponse = await axios.post('auth/refresh', {}, axiosConfig)
    if (response.data.access_token) {
      await store.dispatch('auth/setSession', response.data.access_token)
      return {
        accessToken: response.data.access_token
      }
    } else {
      throw new Error('Unable to refresh token')
    }
  } catch (e) {
    if (e?.response?.status === 401) {
      forceLogout()
    }
  } finally {
    isFetchingToken = false
  }
}

export default axiosInstance
