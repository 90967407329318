import { middlewareContext } from '@/router'

export default function auth ({ next, store, nextMiddleware, to }: middlewareContext): void | Promise<unknown> {
  if (store.getters['auth/is_authorized'] === null) {
    store.dispatch('auth/refresh').then(() => {
      checkAuthState({ next: next, store: store, nextMiddleware: nextMiddleware, to: to })
    }).catch(() => {
      return next({ name: 'SignIn', query: { redirect: to.fullPath } })
    })
  } else {
    checkAuthState({ next: next, store: store, nextMiddleware: nextMiddleware, to: to })
  }
}

function checkAuthState ({ next, store, nextMiddleware, to }) {
  if (!store.getters['auth/is_authorized']) {
    return next({ name: 'SignIn', query: { redirect: to.fullPath } })
  }
  return nextMiddleware()
}
