import { AxiosResponse } from 'axios'
import http from '@/http-common'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  get_topics (): Promise<AxiosResponse> {
    return http.get('/contact-us-categories')
  },
  create_ticket (data) {
    return http.post('/contact-us', data)
  }
}
