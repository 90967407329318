import { ActionTree, MutationTree } from 'vuex'
import Jsona from 'jsona'
import CampaignsService from '@/services/api/CampaignsService'
import IFbCampaigns from '@/models/IFbCampaigns'
import IFbCampaign from '@/models/IFbCampaign'
import {
  SET_CAMPAIGNS,
  ADD_CAMPAIGNS,
  SET_LOADED,
  SET_CAMPAIGNS_PAGE,
  RESET_STATE,
  SET_SEARCH_NAME
} from '@/store/mutationsConstants/mutationCampaigns'
import IAdSet from '@/models/IAdSet'
import IAd from '@/models/IAd'

interface loadRuleCampaignsParams {
  service: string
  search: Record<any, any>
}

const initialState = () : IFbCampaigns => ({
  data: [],
  meta: null,
  loaded: false,
  campaignsPage: 0,
  searchName: null
})

const mutations = <MutationTree<IFbCampaigns>> {
  [SET_CAMPAIGNS] (state: IFbCampaigns, data: IFbCampaigns) {
    state.data = data.data
    state.meta = data.meta
  },
  [ADD_CAMPAIGNS] (state: IFbCampaigns, data: IFbCampaigns) {
    state.data = state.data.concat(data.data)
    state.meta = data.meta
  },
  [SET_LOADED] (state: IFbCampaigns, value: boolean) {
    state.loaded = value
  },
  [SET_CAMPAIGNS_PAGE] (state: IFbCampaigns, value: number | null) {
    state.campaignsPage = value
  },
  [SET_SEARCH_NAME] (state: IFbCampaigns, value: string | null) {
    state.searchName = value
  },
  [RESET_STATE] (state: IFbCampaigns) {
    state.data = []
    state.meta = null
    state.loaded = false
    state.campaignsPage = 0
  }
}
const actions = <ActionTree<IFbCampaigns, any>>{
  async loadCampaigns ({ dispatch }, params?: Record<string, any>) {
    try {
      const page = params?.search?.page || 1
      const response = await CampaignsService.getCampaigns(params?.search, params?.filter)
      const dataFormatter = new Jsona()
      const callback = async () => {
        await dispatch('setCampaignsPage', Number(response.data.meta.pagination.current_page))
        if (response.data.meta.pagination.current_page < response.data.meta.pagination.total_pages) {
          return true
          // dispatch('loadCampaigns', { ...params, page: page + 1 })
        } else {
          await dispatch('setLoaded', true)
          return false
        }
      }
      if (page === 1) {
        return dispatch('setCampaigns', { data: dataFormatter.deserialize(response.data), meta: response.data.meta }).then(callback)
      } else return dispatch('addCampaigns', { data: dataFormatter.deserialize(response.data), meta: response.data.meta }).then(callback)
    } catch (e) {
      console.log(e)
    }
  },
  async loadRuleCampaigns ({ dispatch }, params: loadRuleCampaignsParams) {
    try {
      const page = params.search?.page || 1
      const response = await CampaignsService.getRuleCampaigns(params.service, params.search)
      const dataFormatter = new Jsona()
      const callback = async () => {
        await dispatch('setCampaignsPage', Number(response.data.meta.pagination.current_page))
        if (response.data.meta.pagination.current_page < response.data.meta.pagination.total_pages) {
          return true
          // dispatch('loadCampaigns', { ...params, page: page + 1 })
        } else {
          await dispatch('setLoaded', true)
          return false
        }
      }
      if (page === 1) {
        return dispatch('setCampaigns', { data: dataFormatter.deserialize(response.data), meta: response.data.meta }).then(callback)
      } else return dispatch('addCampaigns', { data: dataFormatter.deserialize(response.data), meta: response.data.meta }).then(callback)
    } catch (e) {
      console.log(e)
    }
  },
  setCampaigns (context, rules: IFbCampaigns) {
    context.commit(SET_CAMPAIGNS, rules)
  },
  addCampaigns (context, rules: IFbCampaigns) {
    context.commit(ADD_CAMPAIGNS, rules)
  },
  setLoaded (context, value: boolean) {
    context.commit(SET_LOADED, value)
  },
  setCampaignsPage ({ commit }, value) {
    commit(SET_CAMPAIGNS_PAGE, value)
  },
  setSearchName ({ commit }, value) {
    commit(SET_SEARCH_NAME, value)
  },
  resetState ({ commit }) {
    commit(RESET_STATE)
  }
}
const getters = {
  getAllCampaigns (state: IFbCampaigns): IFbCampaign[] {
    return state.data
  },
  campaignByExternalId: (state: IFbCampaigns) => (fbId: string):IFbCampaign => {
    return state.data.find(c => c.external_id === fbId)
  },
  pagination (state: IFbCampaigns): Record<string, any> {
    return state.meta
  },
  getById: (state: IFbCampaigns) => (id: number) : IFbCampaign => {
    // eslint-disable-next-line eqeqeq
    return state.data.find((a) => a.id == id)
  },
  loaded (state: IFbCampaigns): boolean {
    return state.loaded
  },
  campaignByAdSetExternalId: (state: IFbCampaigns) => (external_id: string) : IFbCampaign => {
    let campaign = null
    state.data.forEach(c => {
      if (c.ad_sets.find(a => a.external_id === external_id)) campaign = c
      if (campaign) return false
    })
    return campaign
  },
  getByAdAccountId: (state: IFbCampaigns) => (id: number): IFbCampaign[] => {
    if (id) {
      return state.data.filter(c => {
        // eslint-disable-next-line eqeqeq
        return c.account_id == id
      })
    } else {
      return state.data
    }
  },
  filteredAdSets: (state: IFbCampaigns, getters) => (campaign: IFbCampaign): IAdSet[]|[] => {
    if (!state.searchName || state.searchName.length === 0) {
      return campaign.ad_sets
    }
    return campaign.ad_sets.filter(adSet => {
      const adsetLike = adSet?.name && adSet.name.toLowerCase().indexOf(state.searchName.toLowerCase()) !== -1
      if (adsetLike) {
        return true
      } else {
        return getters.filteredAds(adSet).length > 0
      }
    })
  },
  filteredAds: (state: IFbCampaigns) => (adSet: IAdSet): IAd[]|[] => {
    if (!state.searchName || state.searchName.length === 0) {
      return adSet.ads
    }
    return adSet.ads.filter(ad => ad?.name && ad.name.toLowerCase().indexOf(state.searchName.toLowerCase()) !== -1)
  },
  adSetByAdExternalId: (state: IFbCampaigns) => (external_id: string): any => {
    let adSet = null
    state.data.forEach(c => {
      adSet = c.ad_sets.find(a => a.ads.find(ad => ad.external_id === external_id))
      if (adSet) return false
    })
    return adSet
  },
  adSetByExternalId: (state: IFbCampaigns) => (external_id: string): any => {
    let adSet = null
    state.data.some(c => {
      adSet = c.ad_sets.find(a => a.external_id === external_id)
      if (adSet) {
        return true
      } else {
        return false
      }
    })
    return adSet
  },
  adSetIdByExternalId: (state: IFbCampaigns) => (external_id: string): any => {
    let adSet = null
    state.data.forEach(c => {
      const tmpAdSet = c.ad_sets.find(a => String(a.external_id) === String(external_id))
      if (tmpAdSet) {
        adSet = tmpAdSet
      }
    })
    return adSet
  },
  getCampaignsPage (state: IFbCampaigns): number | null {
    return state.campaignsPage
  },
  getSearchName (state: IFbCampaigns): string | null {
    return state.searchName
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
