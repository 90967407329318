export const SET_CAMPAIGNS = 'setCampaigns'
export const SET_REPORT_AD_SETS = 'setReportAdSets'
export const SET_AD_SETS = 'setAdSets'
export const SET_AD_SET = 'setAdSet'
export const SET_INIT_AD_SET = 'setInitAdSet'
export const SET_FILTER = 'setFilter'
export const SET_FILTER_DASHBOARD = 'setFilterDashboard'
export const SET_CAMPAIGN = 'setCampaign'
export const SET_INIT_CAMPAIGN_DATA = 'setInitCampaignData'
export const SET_STATE_FIELD = 'setStateField'
export const SET_STATE_PROP = 'setStateProp'
export const SET_STATE_FIELD_PROP = 'setStateFieldProp'
export const CHANGE_INCLUSION_TARGETING = 'changeInclusionTargeting'
export const SET_AUDIENCE_INSPECTOR_PROP = 'setAudienceInspectorProp'
export const SET_CAMPAIGN_PERFOMANCE = 'setCampaignPerformance'
export const SET_CAMPAIGN_PERFOMANCE_META = 'setCampaignPerformanceMeta'
export const SET_OBJECTIVE = 'setObjective'
export const SET_SPECIAL_AD_CATEGORIES = 'setSpecialAdCategories'
export const SET_CUSTOM_AUDIENCES = 'setCustomAudiences'
export const SET_LOCATION_TYPES = 'setLocationTypes'
export const SET_LOCALES = 'setLocales'
export const SET_TARGETING = 'setTargeting'
export const SET_LOCAL_AUDIENCES_DATA = 'setLocalAudiencesData'
export const SET_CAMPAIGN_OBJECTIVE = 'setCampaignObjective'
export const SET_AUDIENCES_DATA = 'setAudiencesData'
export const SET_AUDIENCES_PROPERTY = 'setAudienceProperty'
export const SET_ALL_ADS_AD_SETS = 'set_allAds_adSets'
export const SET_CAMPAIGN_STATUSES = 'setCampaignStatuses'
export const CLEAR_ADS_MANAGER = 'clearAdsManager'
export const TOGGLE_CHANGES = 'toggleChanges'
export const TOGGLE_AD_SET_CHANGES = 'toggleAdSetChanges'
export const SET_SELECTED_AD_ACCOUNT = 'setSelectedAdAccount'
