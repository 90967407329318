export const RESET_SEARCH_RESULTS = 'resetSearchResults'
export const SET_LOCALES = 'setLocales'
export const SET_LOADER = 'setLoader'
export const SET_STATE_PROP = 'setStateProp'
export const SET_STATE_FIELD = 'setStateField'
export const ADD_TO_SELECTED_PROJECTS = 'addToSelectedProjects'
export const DELETE_FROM_SELECTED_PROJECTS = 'deleteFromSelectedProjects'
export const DELETE_COLLECTION_ITEM = 'deleteCollectionItem'
export const SET_FILTERS = 'setFilters'
export const APPLY_FILTERS = 'applyFilters'
export const SET_AUDIENCE_SIZES = 'setAudienceSizes'
export const SET_MIN_MAX_AUDIENCE_SIZES = 'setMinMaxAudienceSizes'
export const ADD_FILTER_WORD = 'addFilterWord'
export const CHANGE_TYPE_FILTER_WORD = 'changeTypeFilterWord'
export const ADD_TO_SELECTED_TARGETING_TYPE = 'addToSelectedTargetingType'
export const ADD_TO_INTEREST_COLLECTION = 'addToInterestCollection'
export const DELETE_FROM_INTEREST_COLLECTION = 'deleteFromInterestCollection'
export const ADD_SEARCH_WORD = 'addSearchWord'
export const DELETE_SEARCH_WORD = 'deleteSearchWord'
export const DIVIDE_INTERESTS_COLLECTION = 'divideInterestsCollection'
