import { UnionServices } from '@/models/IUserUses'
import type { Filter } from '@/utils/Filter'
import { AxiosResponse } from 'axios'
import http from '@/http-common'
import { TJsonApiBody } from 'jsona/lib/JsonaTypes'

type PromiseResponse<T> = Promise<AxiosResponse<T>>

export default class {
  static getPostBoostingRules (service: UnionServices, data: unknown, filter?: Filter): PromiseResponse<TJsonApiBody> {
    return http.get(`/post_boosting/${service}/rules${filter ? `?${filter}` : ''}`, {
      params: data
    })
  }

  static getPostBoostingRule (service: UnionServices, rule_id: number, with_notification_spec = false): PromiseResponse<unknown> {
    return http.get(`/post_boosting/${service}/rules/${rule_id}${with_notification_spec ? '?include[]=notification_spec' : ''}`)
  }

  static createPostBoostingRule (service: UnionServices, data: unknown): PromiseResponse<unknown> {
    return http.post(`/post_boosting/${service}/rules`, data)
  }

  static updatePostBoostingRule (service: UnionServices, data: unknown, rule_id: string): PromiseResponse<unknown> {
    return http.patch(`/post_boosting/${service}/rules/${rule_id}`, data)
  }

  static searchPosts (service: UnionServices, data: unknown, rule_id: string): PromiseResponse<unknown> {
    return http.post(`/post_boosting/${service}/rules/${rule_id}/posts?include[0]=insights`, data)
  }

  static deletePostBoostingRule (service: UnionServices, rule_id: string): PromiseResponse<unknown> {
    return http.delete(`/post_boosting/${service}/rules/${rule_id}`)
  }

  static deletePostBoostingRules (service: UnionServices, ids: number): PromiseResponse<unknown> {
    return http.post(`/post_boosting/${service}/rules/delete`, {
      ids
    })
  }

  static getPostTypes (service: UnionServices): PromiseResponse<unknown> {
    return http.get(`/post_boosting/${service}/post_types`)
  }

  static getEvaluationFields (service: UnionServices): PromiseResponse<unknown> {
    return http.get(`/post_boosting/${service}/evaluation_fields`)
  }

  static getTimeRangeBoosting (service: UnionServices): PromiseResponse<unknown> {
    return http.get(`/automated_rules/${service}/time_ranges`)
  }

  static getCampaignNameTemplates (service: UnionServices): PromiseResponse<AxiosResponse> {
    return http.get(`/post_boosting/${service}/campaign_name_templates`)
  }

  static getAdSetNameTemplates (service: UnionServices): PromiseResponse<AxiosResponse> {
    return http.get(`/post_boosting/${service}/adset_name_templates`)
  }

  static getCampaignSettings (service: UnionServices, rule_id: string): PromiseResponse<AxiosResponse> {
    return http.get(`/post_boosting/${service}/rules/${rule_id}/campaign`)
  }

  static postCampaignSettings (service: UnionServices, rule_id: string, body: unknown): PromiseResponse<AxiosResponse> {
    return http.post(`/post_boosting/${service}/rules/${rule_id}/campaign`, body)
  }

  static getAdSetSettings (service: UnionServices, ruleId: string): Promise<AxiosResponse> {
    return http.get(`/post_boosting/${service}/rules/${ruleId}/ad_set`)
  }

  static getAdSetsCustomAudiences (service: UnionServices, ruleId: string): PromiseResponse<AxiosResponse> {
    return http.get(`/post_boosting/${service}/rules/${ruleId}/get_custom_audiences`)
  }

  static postAdSetsSetting (service: UnionServices, ruleId: string, body: unknown): PromiseResponse<AxiosResponse> {
    return http.post(`/post_boosting/${service}/rules/${ruleId}/ad_set`, body)
  }

  static getAdSettings (service: UnionServices, ruleId: string): PromiseResponse<AxiosResponse> {
    return http.get(`/post_boosting/${service}/rules/${ruleId}/ad`)
  }

  static postAdSetting (service: UnionServices, ruleId: string, body: unknown): PromiseResponse<AxiosResponse> {
    return http.post(`/post_boosting/${service}/rules/${ruleId}/ad`, body)
  }

  static getPixels (service: UnionServices, ruleId: string): PromiseResponse<AxiosResponse> {
    return http.get(`/post_boosting/${service}/rules/${ruleId}/pixels`)
  }

  static getEvents (service: UnionServices): PromiseResponse<AxiosResponse> {
    return http.get(`/post_boosting/${service}/conversion_events`)
  }
}
