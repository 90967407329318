import ICookie from '@/models/ICookie'

export function saveCookie (params: ICookie): void {
  document.cookie = `${process.env.VUE_APP_COOKIE_PREFIX}${params.key}=${params.value}; domain=${params.domain}; path=${params.path} ;expires=${params.expires.toUTCString()};`
}

export function deleteCookie ({ domain, key }): void {
  document.cookie = `${process.env.VUE_APP_COOKIE_PREFIX}${key}=null; domain=${domain}; max-age=-1; path=/`
}

export function getCookie (key: string): string | null {
  const value = document.cookie.split(';').find(c => c.trim().split('=')[0] === process.env.VUE_APP_COOKIE_PREFIX + key)
  return value ? value.trim().split('=')[1] : null
}
