















import Vue from 'vue'

export default Vue.extend({
  name: 'Card',
  props: {
    align: {
      type: String,
      default: 'left'
    },
    preloadingMask: {
      type: Boolean,
      default: false
    },
    smoothDisplay: {
      type: Boolean,
      default: false
    }
  }
})
