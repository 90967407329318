















import NewUiAlert from '@/components/NewUIComponents/NewUiAlert.vue'
import Vue, { PropType } from 'vue'

export type Alert = {
  type: 'primary' | 'success' | 'warning' | 'danger',
  title?: string,
  body?: string,
  closeButton?: 'text' | 'icon'
}

export default Vue.extend({
  name: 'NewUiAlerts',
  components: { NewUiAlert },
  props: {
    alerts: Array as PropType<Array<Alert>>,
    alertsKey: {
      type: String
    }
  },
  methods: {
    close (key: number | string) {
      this.$emit('close', key)
    }
  }
})
