import { ActionTree, MutationTree } from 'vuex'
import INotificationsState from '@/models/INotificationsState'
import INotification from '@/models/INotification'
import IException from '@/models/IException'
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '@/store/mutationsConstants/mutationNotifications'

const initialState = {
  notifications: []
}

const autoRemoveTimeout = 3000
const getRandomInt = (max) => {
  return Math.floor(Math.random() * max)
}

const mutations = <MutationTree<INotificationsState>> {
  [ADD_NOTIFICATION] (state: INotificationsState, data: INotification) {
    state.notifications.push(data)
    if (data.autoClose) {
      setTimeout(() => {
        state.notifications = state.notifications.filter(n => n.id !== data.id)
      }, autoRemoveTimeout)
    }
  },
  [REMOVE_NOTIFICATION] (state: INotificationsState, id: number) {
    state.notifications = state.notifications.filter(n => n.id !== id)
  }
}
const actions = <ActionTree<any, any>> {
  addNotification (context, data: any) {
    if (data.autoClose !== false) {
      data.autoClose = true
    }
    if (data.id === null || data.id === undefined) {
      data.id = Date.now()
      if (context.getters.getNotification(data.id)) {
        data.id = data.id + getRandomInt(Date.now())
      }
    }
    context.commit('addNotification', data)
  },
  addSuccessNotification (context, body: string) {
    context.dispatch('addNotification', { type: 'success', body: body })
  },
  addExceptions (context, data: IException) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if ((typeof data.errors) === 'array' || (typeof data.errors) === 'object') {
      data.errors.forEach(exception => {
        if (exception.source.parameter === null) {
          context.dispatch('addNotification', { type: 'danger', body: exception.detail[0] })
        }
      })
    } else if ((typeof data.errors) === 'string') {
      context.dispatch(ADD_NOTIFICATION, { type: 'danger', body: data.errors })
    }
  },
  removeNotification (context, id: number) {
    context.commit(REMOVE_NOTIFICATION, id)
  }
}

const getters = {
  getNotifications (state: INotificationsState): INotification[] {
    return state.notifications
  },
  getNotificationsMessages (state: INotificationsState): string[] {
    return state.notifications.map(d => d.body)
  },
  getNotification: (state: INotificationsState) => (id: number): INotification | null => {
    return state.notifications.find(n => n.id === id)
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
