import AffiliateService, { IPagination } from '@/services/api/AffiliateService'
import { RawLocation } from 'vue-router'

export interface IAffiliateTable {
  head: Array<{
    text: string,
    color: `#${string}`,
    type: 'text' | 'status' | 'currency' | 'percentage'
    contentConverter?: (content: string | number) => string | number
  }>
  body: Array<Array<string | number>> | null,
  route?: RawLocation
}

export type RouteKeys = 'pending' | 'earned' | 'purchases' | 'clicks'

export type IRoute = {
  key: string
  title: string
  subtitle: string
  getDataAsTable (query?: Record<string, string | number>)
    : Promise<{ body: IAffiliateTable['body'], pagination?: IPagination }>
  head: IAffiliateTable['head']
}

export type IRoutes = Record<RouteKeys, IRoute>

export type HeadCell = IAffiliateTable['head'][number]

const headDate: HeadCell = {
  text: 'Date',
  color: '#DEE2E6',
  type: 'text'
}

const headPayout: HeadCell = {
  text: 'Payout',
  color: '#FFC107',
  type: 'currency'
}

const headStatus: HeadCell = {
  text: 'Status',
  color: '#2888F0',
  type: 'status',
  contentConverter (content: string): string {
    return content === 'cancel' ? 'canceled' : content
  }
}

export const ROUTES: IRoutes = {
  pending: {
    key: 'pending',
    title: 'Pending',
    subtitle: 'Transactions',
    head: [
      {
        ...headDate,
        contentConverter (content: string): string {
          return content.split(' ')[0]
        }
      },
      headPayout,
      headStatus
    ],
    async getDataAsTable (query) {
      const res = (await AffiliateService.getTransactions(query))?.data

      return {
        body: res?.data?.map(({ attributes }) => [attributes?.payable_at || '-', attributes.payout, attributes.status]) || null,
        pagination: res?.meta?.pagination
      }
    }
  },
  earned: {
    key: 'earned',
    title: 'Earned',
    subtitle: 'Withdrawals history',
    head: [
      headDate,
      {
        text: 'Wallet',
        color: '#F44444',
        type: 'text'
      },
      {
        text: 'Amount',
        color: '#FFC107',
        type: 'currency'
      },
      headStatus
    ],
    async getDataAsTable (query) {
      const res = (await AffiliateService.getWithdrawals(query))?.data

      return {
        body: res?.data?.map(({ attributes }) =>
          [attributes.created_at, attributes.wallet, attributes.amount, attributes.status]
        ) || null,
        pagination: res?.meta?.pagination
      }
    }
  },
  purchases: {
    key: 'purchases',
    title: 'Purchases',
    subtitle: 'Transactions',
    head: [
      headDate,
      headPayout,
      headStatus
    ],
    async getDataAsTable (query) {
      const res = (await AffiliateService.getTransactions(query))?.data

      return {
        body: res?.data?.map(({ attributes }) =>
          [attributes.created_at, attributes.payout, attributes.status]
        ) || null,
        pagination: res?.meta?.pagination
      }
    }
  },
  clicks: {
    key: 'clicks',
    title: 'Clicks / Sign-ups',
    subtitle: 'Clicks / Sign-ups',
    head: [
      headDate,
      {
        text: 'Clicks',
        color: '#2888F0',
        type: 'text'
      },
      {
        text: 'Sign-ups',
        color: '#F44444',
        type: 'text'
      },
      {
        text: 'CTR',
        color: '#DE528D',
        type: 'percentage'
      }
    ],
    async getDataAsTable (query) {
      const res = (await AffiliateService.getClicks(query))?.data

      return {
        body: res?.data?.map(({ attributes }) =>
          [attributes.date, attributes.clicks, attributes.signups, attributes.ctr]
        ) || null,
        pagination: res?.meta?.pagination
      }
    }
  }
}

export const AFFILIATE_ROUTES = Object.values(ROUTES)
