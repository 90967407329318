










import NewUiAlerts, { Alert } from '@/components/NewUIComponents/NewUiAlerts.vue'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'NewUiNotifications',
  components: { NewUiAlerts },
  computed: {
    ...mapGetters('notifications', ['getNotifications']),
    alerts (): Array<Alert & { id: number }> {
      return this.getNotifications.map(_ => {
        const result: Alert & { id: number } = {
          id: _.id as number,
          type: _.type as 'primary' | 'success' | 'warning' | 'danger',
          title: undefined,
          body: undefined
        }

        if (_.title) {
          result.title = _.title
        }
        if (_.body) {
          result.body = _.body
        }

        return result
      })
    }
  },
  methods: {
    ...mapActions('notifications', ['removeNotification']),
    close (id: number) {
      this.removeNotification(id)
    }
  }
})
