import { AxiosResponse } from 'axios'
import { ActionTree, MutationTree } from 'vuex'
import Jsona from 'jsona'
import RulesService from '@/services/api/RulesService'
import IRules from '@/models/Rules/IRules'
import IRule from '@/models/Rules/IRule'
import Vue from 'vue'
import _ from 'lodash'
import {
  CONDITION_TYPE_CONDITION,
  CONDITION_TYPE_GROUP,
  ENTITY_TYPE_AD,
  ENTITY_TYPE_ADSET,
  ENTITY_TYPE_CAMPAIGN,
  ENTITY_TYPE_CAMPAIGN_GROUP,
  ENTITY_TYPE_EXTENSION,
  ENTITY_TYPE_KEYWORD,
  ENTITY_TYPE_SEARCH_TERM,
  LINKEDIN_SERVICE,
  METRIC_TYPE_CUSTOM,
  METRIC_TYPE_SIMPLE,
  OPERATOR_GREATER_THAN, SCHEDULE_CONTINUOUSLY_INTERVALS, SCHEDULE_TYPE_CONTINUOUSLY, SELECTION_METHODS
} from '@/constants/FbAutomatedRule'

const initState = {
  data: [],
  meta: {},
  errors: [],
  ruleEdited: false,
  serverData: [],
  selectedRules: [],
  log: [],
  processing: false,
  selected_entities: {
    campaigns: [],
    ad_sets: [],
    ads: [],
    extensions: [],
    search_terms: [],
    keywords: []
  },
  selection_filters_estimated_match: null,
  selection_filters: []
}

const newRuleInit = {
  id: null,
  name: 'Untitled rule',
  description: null,
  ad_account_id: null,
  status: 'ENABLED',
  ad_account: null,
  execution_spec: {
    available: [],
    key: 'NOTIFY',
    name: 'Notify',
    options: { frequency: '86400' },
    sets: []
  },
  folder_id: null,
  last_triggered: null,
  next_state: null,
  schedule_spec: {
    type: SCHEDULE_TYPE_CONTINUOUSLY,
    schedule: null,
    value: SCHEDULE_CONTINUOUSLY_INTERVALS[0].value
  },
  created_at: null,
  updated_at: null,
  entity_type: ENTITY_TYPE_CAMPAIGN,
  selection_method: SELECTION_METHODS.SPECIFIC_ITEMS,
  entities: {
    campaigns: [],
    ad_sets: [],
    ads: [],
    extensions: [],
    search_terms: [],
    keywords: []
  },
  notification_spec: {
    enabled: false,
    emails: [],
    slack_channels: []
  },
  selection_filters: []
}

const defaultGroup = {
  type: CONDITION_TYPE_GROUP,
  level: 1,
  id: null,
  uid: null,
  operator: 'AND',
  conditions: null
}

const generateConditionWithId = (id = Date.now() + 1) => {
  return {
    id,
    uid: id,
    type: CONDITION_TYPE_CONDITION,
    metric_type: METRIC_TYPE_SIMPLE,
    metric: null,
    custom_metric_id: null,
    value: 0,
    operator: OPERATOR_GREATER_THAN,
    time_range: 'LIFETIME'
  }
}

const initialState = () : IRules => (initState)

const mutations = <MutationTree<IRules>><unknown>{
  setProcessing (state, data) {
    state.processing = data.processing
  },
  setLog (state, data) {
    state.log = data
  },
  setStateProp (state, { prop, value }) {
    Vue.set(state, prop, value)
  },
  setSelectedRules (state, data) {
    state.selectedRules.push(Number(data))
  },
  removeSelectedRules (state, data: number) {
    const ind = state.selectedRules.indexOf(Number(data))
    if (ind !== -1) {
      state.selectedRules.splice(ind, 1)
    }
  },
  resetSelectedRules (state) {
    state.selectedRules = []
  },
  setRules (state: IRules, data: IRules) {
    state.data = data.data
    state.meta = data.meta
  },
  addRules (state: IRules, data: IRules) {
    state.data = state.data.concat(data.data)
    state.meta = data.meta
  },
  setRuleProp (state: IRules, { id, type, value }) {
    const rule = state.data.find(r => +r.id === +id)
    if (rule) {
      Vue.set(rule, type, value)
    }
  },
  addError (state: IRules, { payload }) {
    state.errors.push(payload)
  },
  removeError (state: IRules, key) {
    state.errors = state.errors.filter(v => v && v.source.parameter !== key)
  },
  setErrors (state: IRules, payload) {
    state.errors = payload
  },
  removeErr (state, payload) {
    const errArr = state.errors.filter(v => v.source.parameter.split('.').some(v => v === String(payload.key)))
    let err
    if (errArr.length === 1) {
      err = errArr[0]
    }
    if (errArr.length > 1) {
      err = errArr.find(v => v.source.parameter.split('.').some(v => v === payload.field))
    }
    const ind = state.errors.indexOf(err)
    if (ind !== -1) {
      state.errors.splice(ind, 1)
    }
  },
  resetErrs (state) {
    state.errors = []
  },
  resetEntitiesMutation (state, id) {
    const rule = state.data.find(r => +r.id === +id)
    Vue.set(rule, 'entities', JSON.parse(JSON.stringify(newRuleInit.entities)))
  },
  setRuleEdited (state: IRules, value) {
    state.ruleEdited = value
  },
  addNewRule (state: IRules) {
    const id = Date.now()
    state.data.push({
      ...JSON.parse(JSON.stringify(newRuleInit)),
      conditions: {
        ...defaultGroup,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id,
        uid: id,
        conditions: { [id + 1]: generateConditionWithId(id + 1) }
      }
    })
  },
  addRule (state: IRules, payload) {
    state.data = _.filter(state.data, rule => rule.id !== payload.id)
    state.data.push(payload)
  },
  setActionProp (state, payload) {
    const rule = state.data.find(rule => String(rule.id) === String(payload.ruleId))
    if (!rule) {
      return true
    }
    const actionProps = rule.execution_spec
    const options = { ...actionProps.options, [payload.key]: payload.value }
    Vue.set(actionProps, 'options', options)
  },
  setNestedProp (state, { id, field, prop, value }) {
    const rule = state.data.find(r => r.id === id)
    Vue.set(rule[field], prop, value)
  },
  addDefaultCondition (state, payload) {
    const rule = state.data.find(rule => rule.id === payload.ruleId)
    const id = Date.now()
    function addConditionRecursive (obj, groupId) {
      for (const prop in obj) {
        if (obj[prop]['conditions'] && String(prop) !== String(groupId)) {
          addConditionRecursive(obj[prop]['conditions'], groupId)
          // eslint-disable-next-line eqeqeq
        } else if (String(prop) === String(payload.groupId)) {
          Vue.set(obj[prop]['conditions'], id, generateConditionWithId(id))
        } else {
          continue
        }
      }
    }

    if (payload.level === 1) {
      Vue.set(rule['conditions']['conditions'], id, generateConditionWithId(id))
    } else {
      addConditionRecursive(rule.conditions.conditions, payload.groupId)
    }
    state.ruleEdited = true
  },

  addDefaultGroup (state, payload) {
    const rule = state.data.find(rule => rule.id === payload.ruleId)
    const id = Date.now()
    const defaultGroup = {
      type: CONDITION_TYPE_GROUP,
      operator: 'AND',
      level: payload.level + 1,
      id,
      uid: id,
      conditions: {
        [id + 1]: generateConditionWithId(id + 1),
        [id + 2]: generateConditionWithId(id + 2)
      }
    }

    function addGroupRecursive (obj, groupId) {
      for (const prop in obj) {
        if (obj[prop]['conditions'] && String(prop) !== String(groupId)) {
          addGroupRecursive(obj[prop]['conditions'], groupId)
        } else if (String(prop) === String(groupId)) {
          Vue.set(obj[prop]['conditions'], id, defaultGroup)
        } else {
          continue
        }
      }
    }
    if (payload.level === 0) {
      Vue.set(rule, 'conditions', {
        ...defaultGroup,
        id,
        uid: id,
        conditions: {
          [id + 1]: generateConditionWithId(id + 1)
        }
      })
    }

    if (Number(payload.level) === 1) {
      Vue.set(rule['conditions']['conditions'], id, defaultGroup)
    } else {
      addGroupRecursive(rule.conditions.conditions, payload.groupId)
    }
    state.ruleEdited = true
  },
  setConditionProp (state, payload) {
    const rule = state.data.find(rule => rule.id === payload.ruleId)
    function setPropsRecursive (obj, itemId) {
      for (const prop in obj) {
        if (obj[prop] && obj[prop]['type'] !== CONDITION_TYPE_CONDITION) {
          setPropsRecursive(obj[prop].conditions, itemId)
        } else if (String(prop) === String(itemId)) {
          Vue.set(obj[prop], payload.type, payload.value)
        }
      }
    }
    if (rule) {
      setPropsRecursive(rule.conditions.conditions, payload.itemId)
      state.ruleEdited = true
    }
  },
  setEachConditionProp (state, { ruleId, type, value, conditionCallback }) {
    const rule = state.data.find((rule) => rule.id === ruleId)
    const setPropsRecursive = (group) => {
      for (const prop in group) {
        if (group[prop] && group[prop]['type'] === CONDITION_TYPE_GROUP) {
          setPropsRecursive(group[prop].conditions)
        } else {
          if (conditionCallback) {
            if (conditionCallback(group[prop])) {
              Vue.set(group[prop], type, value)
            }
          } else {
            Vue.set(group[prop], type, value)
          }
        }
      }
    }
    setPropsRecursive(rule.conditions.conditions)
  },
  setGroupProp (state, { ruleId, groupId, type, value, level }) {
    const rule = state.data.find(rule => rule.id === ruleId)

    function setPropsRecursive (obj, groupId) {
      for (const prop in obj) {
        if (String(prop) === String(groupId)) {
          Vue.set(obj[prop], type, value)
        } else if (obj[prop]['type'] !== CONDITION_TYPE_CONDITION) {
          setPropsRecursive(obj[prop].conditions, groupId)
        }
      }
    }

    if (level === 1) Vue.set(rule.conditions, type, value)
    else setPropsRecursive(rule.conditions.conditions, groupId)
    state.ruleEdited = true
  },
  deleteUnavailableMetrics (state, { ruleId, folderId, getters, rootGetters }) {
    let rule = state.data.find(r => r.id === ruleId)
    if (!rule) {
      rule = rootGetters['rulesFolders/getRulesByFolderId'](folderId).find(r => r.id === ruleId)
    }
    _.each(rule.conditions, c => {
      const sef = getters.selectedTrigger(c.metric)
      if (sef && !getters.metricAvailable({ rule_id: ruleId, trigger: sef, condition: c })) {
        c.metric = null
      }
    })
  },
  deleteCondition (state, payload) {
    const rule = state.data.find(rule => rule.id === payload.ruleId)
    function deleteGroupRecursive (obj, groupId) {
      for (const prop in obj) {
        if (String(prop) === String(groupId)) {
          Vue.delete(obj, prop)
        } else if (obj[prop]['type'] === CONDITION_TYPE_GROUP) {
          deleteGroupRecursive(obj[prop].conditions, groupId)
        } else {
          continue
        }
      }
    }

    function deleteItemRecursive (obj, itemId) {
      for (const prop in obj) {
        if (obj[prop]['type'] !== CONDITION_TYPE_CONDITION) {
          deleteItemRecursive(obj[prop].conditions, itemId)
        } else if (String(prop) === String(itemId)) {
          Object.keys(obj).length === 1
            ? deleteGroupRecursive(rule.conditions.conditions, payload.groupId)
            : Vue.delete(obj, prop)
        } else {
          continue
        }
      }
    }

    deleteItemRecursive(rule.conditions.conditions, payload.itemId)
    state.ruleEdited = true
  },
  clearSelectedEntities (state) {
    state.selected_entities = {
      campaigns: [],
      ad_sets: [],
      ads: [],
      extensions: [],
      search_terms: [],
      keywords: []
    }
  },
  addSelectedEntity (state, { level, id }) {
    switch (level) {
      case ENTITY_TYPE_CAMPAIGN:
        state.selected_entities.campaigns.push(id)
        break
      case ENTITY_TYPE_ADSET:
        state.selected_entities.ad_sets.push(id)
        break
      case ENTITY_TYPE_AD:
        state.selected_entities.ads.push(id)
        break
      case ENTITY_TYPE_EXTENSION:
        state.selected_entities.extensions.push(id)
        break
      case ENTITY_TYPE_SEARCH_TERM:
        state.selected_entities.search_terms.push(id)
        break
      case ENTITY_TYPE_KEYWORD:
        state.selected_entities.keywords.push(id)
        break
    }
  },
  removeSelectedEntity (state, { level, id }) {
    switch (level) {
      case ENTITY_TYPE_CAMPAIGN:
        state.selected_entities.campaigns = state.selected_entities.campaigns.filter(c => c !== id)
        break
      case ENTITY_TYPE_ADSET:
        state.selected_entities.ad_sets = state.selected_entities.ad_sets.filter(c => c !== id)
        break
      case ENTITY_TYPE_AD:
        state.selected_entities.ads = state.selected_entities.ads.filter(c => c !== id)
        break
      case ENTITY_TYPE_EXTENSION:
        state.selected_entities.extensions = state.selected_entities.extensions.filter(c => c !== id)
        break
      case ENTITY_TYPE_SEARCH_TERM:
        state.selected_entities.search_terms = state.selected_entities.search_terms.filter(c => c !== id)
        break
      case ENTITY_TYPE_KEYWORD:
        state.selected_entities.keywords = state.selected_entities.keywords.filter(c => c !== id)
        break
    }
  },
  setSelectedEntitiesPack (state, { level, ids }) {
    switch (level) {
      case ENTITY_TYPE_CAMPAIGN:
        state.selected_entities.campaigns = [...ids]
        break
      case ENTITY_TYPE_ADSET:
        state.selected_entities.ad_sets = [...ids]
        break
      case ENTITY_TYPE_AD:
        state.selected_entities.ads = [...ids]
        break
      case ENTITY_TYPE_EXTENSION:
        state.selected_entities.extensions = [...ids]
        break
      case ENTITY_TYPE_SEARCH_TERM:
        state.selected_entities.search_terms = [...ids]
        break
      case ENTITY_TYPE_KEYWORD:
        state.selected_entities.keywords = [...ids]
        break
    }
  },
  setSelectionFiltersEstimatedMatch (state: IRules, data) {
    state.selection_filters_estimated_match = data
  }
}

const actions = <ActionTree<IRules, any>>{
  async loadRules ({ dispatch, rootGetters }, { search = {}, page = 1 }) {
    try {
      const response = await RulesService.getRules(rootGetters['adService/getService'], search, page)
      const dataFormatter = new Jsona()
      const dataDeserialized = { data: dataFormatter.deserialize(response.data), meta: response.data.meta }
      // const callback = () => {
      //   if (response.data.meta.pagination.current_page < response.data.meta.pagination.total_pages) {
      //     dispatch('loadRules', { service: service, search: search, page: page + 1 })
      //   }
      // }
      dispatch('setRules', dataDeserialized)
      // if (page === 1) {
      //   dispatch('setRules', { data: dataFormatter.deserialize(response.data), meta: response.data.meta }).then(callback)
      // } else dispatch('addRules', { data: dataFormatter.deserialize(response.data), meta: response.data.meta }).then(callback)
    } catch (e) {
      console.log(e)
    }
  },
  async loadRulesWithFolders ({ dispatch, rootGetters }, { search = {}, page = 1 }): Promise<AxiosResponse<any>> {
    return RulesService.getRules(rootGetters['adService/getService'], search, page)
      .then(async response => {
        const dataFormatter = new Jsona()
        const dataDeserialized = {
          data: dataFormatter.deserialize(response.data),
          meta: response.data.meta
        }
        const callback = () => {
          if (dataDeserialized.meta.pagination.current_page < dataDeserialized.meta.pagination.total_pages) {
            dispatch('loadRulesWithFolders', {
              search: search,
              page: page + 1
            })
          }
        }
        const data = dataDeserialized.data.filter((rule) => rule.folder_id).reduce((folders, rule) => {
          const folder = folders?.find(folder => folder.id === String(rule.folder_id))

          if (folder) {
            rule.folder = null // Ошибка Maximum call stack size exceeded
            folder.rules.push(rule)
          } else if (rule && rule.folder) {
            folders.push(rule.folder)
            rule.folder = null // Ошибка Maximum call stack size exceeded
            folders[folders.length - 1].rules = [rule]
          }

          return folders
        }, []).map((folder) => {
          folder.opened = true
          return folder
        })
        if (page === 1) {
          await dispatch('rulesFolders/clearState', {
            data: data,
            meta: dataDeserialized.meta
          }, { root: true })
        }
        await dispatch('rulesFolders/mergeFolders', {
          data: data,
          meta: dataDeserialized.meta
        }, { root: true })
          .then(callback)
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },
  setRules (context, rules: IRules) {
    context.commit('setRules', rules)
  },
  addRules (context, rules: IRules) {
    context.commit('addRules', rules)
  },
  saveRule ({ dispatch, commit, state, rootGetters }, { rule }) {
    return new Promise((resolve, reject) => [
      RulesService.createRule(rootGetters['adService/getService'], rule)
        .then((response) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Rule created!', type: 'success' },
            { root: true }
          )
          state.processing = false
          const dataFormatter = new Jsona()
          resolve(dataFormatter.deserialize(response.data))
        })
        .catch((error) => {
          commit('setErrors', error.response.data.errors)
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
            { root: true }

          )
          reject(error)
          state.processing = false
        })
    ])
  },
  disableProcessing ({ state }) {
    state.processing = false
  },
  updateRule ({ dispatch, commit, state, rootGetters }, { rule }) {
    return new Promise((resolve, reject) => {
      RulesService.updateRule(rootGetters['adService/getService'], rule)
        .then((response) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Rule updated successfully!', type: 'success' },
            { root: true }
          )
          resolve(response.status)
          state.processing = false
        })
        .catch((error) => {
          reject(error)
          commit('setErrors', error.response.data.errors)
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
            { root: true }
          )
          state.processing = false
        })
    })
  },
  setRuleProp (context, { id, type, value }) {
    context.commit('setRuleProp', { id, type, value })
  },
  setNestedProp (context, { id, field, prop, value }) {
    context.commit('setNestedProp', { id, field, prop, value })
  },
  resetEntities ({ commit }, id) {
    commit('setRuleProp', { id: id, type: 'entities', value: { ...JSON.parse(JSON.stringify(newRuleInit.entities)) } })
  },
  resetExecutionSpec ({ commit }, id) {
    commit('setRuleProp', { id: id, type: 'execution_spec', value: { ...JSON.parse(JSON.stringify(newRuleInit.execution_spec)) } })
  },
  resetExecutionSpecIfNeed ({ dispatch, getters, rootGetters }, { id, entityType }) {
    const rule = getters.getRuleById(id)
    if (entityType && rule.entity_type !== entityType) {
      const action = rootGetters['rulesMeta/getTaskByFrontKey'](rule.execution_spec?.key)
      if (!action) {
        return null
      }
      if (!getters.taskActionAvailable(entityType, action)) {
        dispatch('resetExecutionSpec', id)
      }
    }
  },
  addError (context, payload) {
    context.commit('addError', { payload: payload })
  },
  removeError (context, key) {
    if (context.state.errors) context.commit('removeError', key)
  },
  removeErr ({ commit }, payload) {
    commit('removeErr', payload)
  },
  setErrors (context, payload) {
    context.commit('setErrors', payload)
  },
  setRuleEdited (context, value) {
    context.commit('setRuleEdited', value)
  },
  addNewRule (context) {
    context.commit('addNewRule')
  },
  getRuleFromServer ({ commit, rootGetters }, { ruleId }) {
    return new Promise<void>((resolve, reject) => {
      RulesService.getRule(rootGetters['adService/getService'], ruleId, { include: ['notification_spec', 'selection_filters'] })
        .then(response => {
          const dataFormatter = new Jsona()
          const data = dataFormatter.deserialize(response.data)
          if (data.notification_spec) {
            const notification_spec = {
              enabled: data.notification_spec.enabled,
              emails: data.notification_spec.receivers.filter(_ => _.type === 'email').map(_ => _.address),
              slack_channels: data.notification_spec.receivers.filter(_ => _.type === 'slack_channel').map(
                _ => _.address)
            }
            data.notification_spec = notification_spec
          }
          if (!data.selection_filters) {
            data.selection_filters = []
          }
          commit('addRule', data)
          resolve()
        })
        .catch((error) => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(error)
        })
    })
  },
  getRuleLogsFromServer ({ commit, rootGetters }, { params }) {
    return new Promise((resolve, reject) => {
      RulesService.getRuleLogs(rootGetters['adService/getService'], params)
        .then(response => {
          const dataFormatter = new Jsona()
          const meta = response.data.meta
          const result = dataFormatter.deserialize(response.data)
          result.meta = meta
          commit('setLog', result)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error.response)
        })
    })
  },
  addDefaultCondition ({ commit }, payload) {
    commit('addDefaultCondition', payload)
  },
  addDefaultGroup ({ commit }, payload) {
    commit('addDefaultGroup', payload)
  },
  setConditionProp (context, payload) {
    context.commit('setConditionProp', payload)
  },
  setRuleEntityType ({ commit }, { id, entityType, service }) {
    commit('setRuleProp', { id: id, type: 'entity_type', value: entityType })
    const metricNullFunc = (condition) => {
      switch (entityType) {
        case ENTITY_TYPE_AD:
          return false
        case ENTITY_TYPE_ADSET:
          return condition.entity_type === ENTITY_TYPE_AD
        case ENTITY_TYPE_CAMPAIGN:
          return condition.entity_type !== ENTITY_TYPE_CAMPAIGN
      }
    }
    commit('setEachConditionProp', {
      ruleId: id,
      type: 'metric',
      value: null,
      conditionCallback: metricNullFunc
    })
    const entityTypeFunc = (condition) => !condition.metric
    const metricEntityType = () => {
      switch (service) {
        case LINKEDIN_SERVICE:
          switch (entityType) {
            case ENTITY_TYPE_CAMPAIGN:
              return ENTITY_TYPE_CAMPAIGN_GROUP
            case ENTITY_TYPE_ADSET:
              return ENTITY_TYPE_CAMPAIGN
            default:
              return entityType
          }
        default:
          return entityType
      }
    }
    commit('setEachConditionProp', {
      ruleId: id,
      type: 'entity_type',
      value: metricEntityType(),
      conditionCallback: entityTypeFunc
    })
  },
  deleteUnavailableMetrics ({ commit, getters }, ruleId) {
    commit('deleteUnavailableMetrics', { ruleId, getters })
  },
  updateRuleStatus ({ dispatch, rootGetters }, { payload }) {
    return new Promise((resolve, reject) => {
      RulesService.updateRuleStatus(rootGetters['adService/getService'], {
        ruleId: payload.ruleId,
        status: payload.status
      })
        .then((response) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Status updated!', type: 'success' },
            { root: true }
          )
          resolve(response)
        })
        .catch((error) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
            { root: true }
          )
          reject(error.response)
        })
    })
  },
  changeStatuses ({ rootGetters }, { data }) {
    return new Promise((resolve, reject) => {
      RulesService.changeStatuses(rootGetters['adService/getService'], data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setRulesStatus ({ dispatch }, { ids, status }) {
    ids.forEach(i => {
      dispatch('setRuleProp', { id: i, type: 'status', value: status })
    })
  },
  deleteRuleItem ({ dispatch, state, rootGetters }, { id }) {
    return new Promise((resolve, reject) => {
      RulesService.deleteRule(rootGetters['adService/getService'], id)
        .then((response) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Deleted success!', type: 'success' },
            { root: true }
          )
          resolve(response)
        })
        .catch((error) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
            { root: true }
          )
          state.errors = error.response.data
          reject(error)
        })
    })
  },
  deleteManyRules ({ state, rootGetters }, { ids }) {
    return new Promise((resolve, reject) => {
      RulesService.deleteMany(rootGetters['adService/getService'], ids)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          state.errors = error.response.data
          reject(error)
        })
    })
  },
  duplicateRule ({ dispatch, state, rootGetters }, { id }) {
    return new Promise((resolve, reject) => {
      RulesService.duplicateRule(rootGetters['adService/getService'], id)
        .then((response) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Success!', type: 'success' },
            { root: true }
          )
          const dataFormatter = new Jsona()
          const dataDeserialized = { data: dataFormatter.deserialize(response.data), meta: response.data.meta }
          resolve(dataDeserialized)
        })
        .catch((error) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong! Please retry later', type: 'danger' },
            { root: true }
          )
          state.errors = error.response.data
          reject(error)
        })
    })
  },
  saveCustomMetric ({ commit, dispatch, rootGetters }, { data, ruleId, itemId }) {
    return new Promise((resolve, reject) => {
      RulesService.createCustomMetric(rootGetters['adService/getService'], data)
        .then(res => {
          commit('setConditionProp', {
            ruleId,
            itemId,
            type: 'metric_type',
            value: METRIC_TYPE_CUSTOM
          })
          commit('setConditionProp', {
            ruleId,
            itemId,
            type: 'metric',
            value: null
          })
          commit('setConditionProp', {
            ruleId,
            itemId,
            type: 'custom_metric_id',
            value: res.data.data.id
          })
          dispatch('rulesMeta/loadTriggers', { requestType: 'rules' }, { root: true })
          dispatch('rulesMeta/loadCustomMetrics', { from: 'rules' }, { root: true })
            .then(() => {
              resolve(res)
            })
          return true
        })
        .catch(e => {
          if (e.response) {
            commit('setErrors', prepareCustomMetricErrors(e.response.data))
            // commit('addError', { 'custom-metric': e.response.data.error_message })
          }
          reject(e)
          return null
        })
    })
  },

  updateCustomMetric ({ commit, dispatch, rootGetters }, { data, metricId }) {
    return RulesService.updateCustomMetric(rootGetters['adService/getService'], metricId, data)
      .then(() => {
        dispatch('rulesMeta/loadTriggers', { requestType: 'rules' }, { root: true })
        dispatch('rulesMeta/loadCustomMetrics', { from: 'rules' }, { root: true })
        return true
      })
      .catch(e => {
        if (e.response) {
          commit('setErrors', prepareCustomMetricErrors(e.response.data))
          commit('addError', { 'custom-metric': e.response.data.error_message })
        }
        return null
      })
  },
  setEntitiesState ({ commit }, data) {
    commit('setEntitiesState', data)
  },
  setEntityState ({ commit }, data) {
    commit('setEntityState', data)
  },
  clearEntitiesState ({ commit }) {
    commit('clearEntitiesState')
  },
  clearSelectedEntities ({ commit }) {
    commit('clearSelectedEntities')
  },
  toggleSelectedEntity ({ commit, getters }, { level, id, add }) {
    let key = 'campaigns'
    switch (level) {
      case ENTITY_TYPE_CAMPAIGN:
        key = 'campaigns'
        break
      case ENTITY_TYPE_ADSET:
        key = 'ad_sets'
        break
      case ENTITY_TYPE_AD:
        key = 'ads'
        break
      case ENTITY_TYPE_EXTENSION:
        key = 'extensions'
        break
      case ENTITY_TYPE_SEARCH_TERM:
        key = 'search_terms'
        break
      case ENTITY_TYPE_KEYWORD:
        key = 'keywords'
        break
    }
    if (add) {
      if (!getters['getSelectedEntities'][key].includes(id)) {
        commit('addSelectedEntity', { level: level, id: id })
      }
    } else {
      commit('removeSelectedEntity', { level: level, id: id })
    }
  },
  setSelectedEntitiesPack ({ commit }, { level, ids }) {
    commit('setSelectedEntitiesPack', { level: level, ids: ids })
  },
  moveRulesToFolder ({ rootGetters }, data) {
    return RulesService.moveRulesToFolder(rootGetters['adService/getService'], data)
  },
  requestSelectionFiltersEstimatedMatch ({ dispatch, rootGetters }, data: {ad_account_id: string | number, entity_type: string, selection_filters: []}) {
    return new Promise((resolve, reject) => {
      RulesService.selectionFiltersEstimatedMatch(rootGetters['adService/getService'], data)
        .then(res => {
          dispatch('setSelectionFiltersEstimatedMatch', res.data.data)
            .then(() => resolve(res))
        })
        .catch(e => {
          dispatch('setSelectionFiltersEstimatedMatch', null)
          reject(e)
        })
    })
  },
  setSelectionFiltersEstimatedMatch ({ commit }, data) {
    commit('setSelectionFiltersEstimatedMatch', data)
  }
}
const getters = {
  hasError: state => key => {
    return _.has(state.errors, key) && _.get(state.errors, key) !== []
  },
  isProcessing (state) {
    return state.processing
  },
  getConditionItemError (state) {
    return function (conditionItemId, field) {
      if (state.errors && state.errors.length) {
        const arr = state.errors.filter(v => v && v.source.parameter.split('.').some(v => v === String(conditionItemId)))
        return arr.find(v => v && v.source.parameter.split('.').some(v => v === String(field)))
      } else {
        return null
      }
    }
  },
  getError (state) {
    return function (field) {
      if (Array.isArray(field)) {
        return state.errors.length > 0
          ? state.errors.find(v => v && v.source.parameter.indexOf(field[0]) !== -1 && v.source.parameter.indexOf(field[1]) !== -1)?.detail[0]
          : ''
      } else {
        return state.errors.length > 0
          ? state.errors.find(v => v && v.source.parameter === field)?.detail[0]
          : ''
      }
    }
  },
  getErrorByConditionId (state) {
    return function (conditionId) {
      return state.errors.find(v => v.source.parameter.split('.').find(t => String(t) === String(conditionId + 1)))
    }
  },
  errorMsg: state => (key, replace = null) => {
    let s = _.get(state.errors, key, []).join('. ')
    if (replace) s = s.replace(key, replace)
    return s
  },
  warningMsg: state => (key, replace = null) => {
    let s = _.get(state.warnings, key, []).join('. ')
    if (replace) s = s.replace(key, replace)
    return s
  },
  hasWarning: state => key => {
    return _.has(state.warnings, key) && _.get(state.warnings, key) !== []
  },
  getActionOptions: state => ruleId => {
    const rule = state.data.find(r => r.id === ruleId)
    return rule.execution_spec.options
  },
  getAllRules (state: IRules): IRule[] {
    return state.data
  },
  pagination (state: IRules): Record<string, any> {
    return state.meta
  },
  entitiesStatus: (state: IRules, getters, rootState, rootGetters) => (ruleId: number, folderId = null): string => {
    let rule = state.data.find(r => r.id === ruleId)
    if (!rule) {
      rule = rootGetters['rulesFolders/getRulesByFolderId'](folderId).find(r => r.id === ruleId)
    }
    if (rule.execution_spec && rule.execution_spec.key === 'START') return 'paused'
    else return 'active'
  },
  getRuleById: (state: IRules) => (ruleId: number): IRule => {
    return state.data.find(r => +r.id === +ruleId)
  },
  ruleEdited (state: IRules): boolean {
    return state.ruleEdited
  },
  taskActionAvailable: (state, rootState, getters, rootGetters) => (entityType, action) => {
    const available = {
      campaign: false,
      adset: false,
      ad: false,
      extension: false,
      search_term: false
    }

    if (action.available.includes('campaign')) {
      available.campaign = entityType === ENTITY_TYPE_CAMPAIGN
    }

    if (action.available.includes('adset')) {
      available.adset = entityType === ENTITY_TYPE_ADSET
    }

    if (action.available.includes('ad')) {
      available.ad = entityType === ENTITY_TYPE_AD
    }
    if (action.available.includes('extension')) {
      available.extension = entityType === ENTITY_TYPE_EXTENSION
    }
    if (action.available.includes('search_term')) {
      available.search_term = entityType === ENTITY_TYPE_SEARCH_TERM
    }
    return _.values(available).filter(v => v).length > 0
  },
  availableTaskFields: (state, rootState, getters, rootGetters) => (ruleId: string | number, groupId: string | number) => {
    const group = rootGetters['rulesMeta/getTaskActions'].find(g => g.id === groupId)
    const rule = state.data.find(r => r.id === ruleId)

    return group.actions.filter(action => {
      if (
        !rule.entity_type &&
        rule.entities.campaigns.length === 0 &&
        rule.entities.ad_sets.length === 0 &&
        rule.entities.ads.length === 0 &&
        rule.entities.extensions.length === 0 &&
        rule.entities.search_terms.length === 0
      ) {
        return true
      }
      return getters.taskActionAvailable(rule.entity_type, action)
    })
  },
  getEntitiesBudgetInfo: (state, getters, rootState, rootGetters) => (
    ruleId,
    type
  ) => {
    const rule = state.data.find(rule => rule.id === ruleId)
    if (!rule) return false
    const entities = rule.entities[type]
    let allEmpty = true

    if (!entities.length) return false
    entities.map(id => {
      const item = rootGetters[`campaigns/${type === 'campaigns' ? 'campaignByExternalId' : 'adSetByExternalId'}`](id)
      if (item.daily_budget || item.lifetime_budget) allEmpty = false
    })
    return allEmpty
  },
  googleCampaignsIsSharedBudget: (state, getters, rootState, rootGetters) => (ruleId) => {
    const rule = state.data.find(rule => rule.id === ruleId)
    if (!rule) return false
    const entities = rule.entities['campaigns']
    if (!entities.length) return false
    return entities.map(id => {
      const campaign = rootGetters['campaigns/campaignByExternalId'](id)
      return campaign.shared_budget
    }).some(i => i)
  },
  taskGroupAvailable: (state, getters, rootState, rootGetters) => (ruleId, taskGroup) => {
    // filter taskGroup groups by status
    const rule = state.data.find(r => r.id === ruleId)
    if (rule.entity_type === ENTITY_TYPE_CAMPAIGN) {
      const everyCampaignCompleted = _.every(rule.entities.campaigns, fbId => {
        const campaign = rootGetters['campaigns/campaignByExternalId'](fbId)
        return campaign ? campaign.status === 'COMPLETED' : false
      })
      if (everyCampaignCompleted && rule.entities.campaigns.length) {
        return (taskGroup.statuses.includes('ALL') || taskGroup.statuses.includes('COMPLETED'))
      } else return true
    } else return true
  },
  getSelectedEntities (state: IRules) {
    return state.selected_entities
  },
  getSelectionFiltersEstimatedMatch (state: IRules) {
    return state.selection_filters_estimated_match
  }
}

function prepareCustomMetricErrors (errors) {
  return errors.errors.map(error => {
    return {
      ...error,
      source: {
        parameter: 'custom_metric.' + error.source.parameter
      }
    }
  })
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
