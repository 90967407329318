import _ from 'lodash'

export interface IFilterEntry {
  field: string
  predicate: string | null
  value: string
}

export class Filter {
  entries: IFilterEntry []

  constructor (entries: IFilterEntry[]) {
    this.entries = entries
  }

  toString (): string {
    let s = ''
    this.entries.forEach((entry, index) => {
      s += `filter[${index}][field]=${entry.field}&filter[${index}][predicate]=${entry.predicate}&filter[${index}][value]=${entry.value}&`
    })
    return _.trimEnd(s, '&')
  }

  add (filter: IFilterEntry): void {
    this.entries.push(filter)
  }

  set (filters: Filter['entries']): void {
    this.entries = filters
  }

  remove (filter: IFilterEntry): void {
    this.entries = this.entries.filter(current => current !== filter)
  }

  get empty (): boolean {
    return !this.entries.length
  }
}
