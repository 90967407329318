import http from '@/http-common'
import { AxiosResponse } from 'axios'
import { ValidateResetTokenBody } from '@/store/modules/auth'

export default {
  login (data: {
    email: string
    password: string
  }): Promise<AxiosResponse> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return http.post('auth/login', data, { _withoutToken: true })
  },
  logout (): Promise<AxiosResponse> {
    return http.post('auth/logout', {})
  },
  sendForgotPasswordEmail (email: string): Promise<AxiosResponse> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return http.post('/auth/password/email', { email: email }, { _withoutToken: true })
  },
  sendResetPasswordRequest (payload: Record<string, string>): Promise<AxiosResponse> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return http.post('/auth/password/reset', payload, { _withoutToken: true })
  },
  validateResetToken (payload: ValidateResetTokenBody): Promise<AxiosResponse> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return http.post('/auth/password/validate_token', payload, { _withoutToken: true })
  },
  refreshToken (): Promise<AxiosResponse> {
    return http.post('/auth/refresh')
  }
}
