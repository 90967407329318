import Vue from 'vue'
import ICampaignDashboard from '@/models/AdsManager/CampaignDashboard/ICampaignDashboard'
import adsCampaign from '@/services/api/AdsManagerCampaignService'
import IAdsManagerState from '@/models/AdsManager/IAdsManagerState'
import {
  SET_STATE_FIELD,
  SET_STATE_PROP,
  SET_STATE_FIELD_PROP,
  SET_AUDIENCE_INSPECTOR_PROP,
  SET_CAMPAIGN_PERFORMANCE,
  SET_CAMPAIGN_PERFORMANCE_META,
  UPDATE_CHARTS,
  OVERRIDE_PERFORMANCE_CHART_TITLES,
  ADD_CHART,
  REMOVE_CHART,
  CHANGE_SHOW_STATE,
  CAMPAIGN_PERFORMANCE_CHART_TITLES,
  CLEAR_STATE
} from '@/store/mutationsConstants/adsManagerMutations/mutationCampaignDashboard'
import { main } from '@popperjs/core'

const initialState = (): ICampaignDashboard => ({
  created_campaign: null,
  campaignDetail: [],
  dashboardAdSets: [],
  campaignPerformance: [],
  campaignPerformanceDueDate: [],
  campaignPerformanceMeta: [],
  campaignPerformanceOrdered: [],
  campaignPerformanceChartsOptions: [],
  campaignPerformanceAudienceInspector: {
    data: [],
    selectedBreakdown: {},
    breakdowns: [],
    selectedCostPerAction: {},
    costPerActions: []
  },
  campaignTargeting: [],
  bestPeriod: [],
  topFactors: [],
  locales: [],
  mainMetric: 'Impressions'
})

const getters = {
  getMainMetric (state) {
    return state.mainMetric
  },
  getLocaleName (state) {
    return function (key) {
      return state.locales
        .find(v => v.key === key)
    }
  },
  campaignPerformanceOrderedTitleSortedTrimmed (state) {
    return state.campaignPerformanceOrdered
      .sort(function (a, b) {
        if (a.id > b.id) return 1
        if (a.id < b.id) return -1
        return 0
      })
      .slice(0, 5)
  },
  availableBreakdowns (state) {
    return state.campaignPerformanceAudienceInspector?.breakdowns.filter(v =>
      state.campaignPerformanceAudienceInspector?.data
        .flat()
        .map(v => v.breakdowns_query)
        .find(j => j === v.key)
    )
  },
  sortedData (state) {
    return state.campaignPerformanceAudienceInspector.data
      .flat()
      .filter(
        v =>
          v.breakdowns_query ===
          state.campaignPerformanceAudienceInspector.selectedBreakdown?.key
      )
  },
  getCampaignDetails (state) {
    return state.campaignDetail
  }
}

const mutations = {
  [SET_STATE_FIELD] (state, { field, value }) {
    Vue.set(state, field, value)
  },
  [SET_STATE_PROP] (state, { prop, value }) {
    Vue.set(state, prop, value)
  },
  [SET_STATE_FIELD_PROP] (state, { field, prop, value }) {
    if (Array.isArray(field)) {
      Vue.set(state[field[0]][field[1]], prop, value)
    } else {
      Vue.set(state[field], prop, value)
    }
  },
  [SET_AUDIENCE_INSPECTOR_PROP] (state, { prop, value }) {
    Vue.set(state.campaignPerformanceAudienceInspector, prop, value)
  },
  [SET_CAMPAIGN_PERFORMANCE] (state, data) {
    state.campaignPerformance = data
  },
  [SET_CAMPAIGN_PERFORMANCE_META] (state, data) {
    state.campaignPerformanceMeta = data
  },
  [UPDATE_CHARTS] (state, payload) {
    if (state.campaignPerformanceChartsOptions.length > 0) {
      state.campaignPerformanceChartsOptions.forEach(item => {
        item.data = Object.values(state.campaignPerformance.day).map(
          (it: any) => it[item.name]
        )
      })
    }
  },
  [OVERRIDE_PERFORMANCE_CHART_TITLES] (state, payload) {
    const rep = state.campaignPerformanceOrdered.find(
      v => v.id === payload.replaceableId
    )
    const sub = state.campaignPerformanceOrdered.find(
      v => v.id === payload.substituteId
    )
    sub.showState = rep.showstate
    sub.id = payload.replaceableId
    rep.id = payload.substituteId
  },
  [ADD_CHART] (state, payload) {
    const obj = {
      id: state.campaignPerformanceChartsOptions.length,
      name: payload.prop,
      title: payload.title,
      data: Object.values(state.campaignPerformance.day).map(
        (item: any) => item[String(payload.prop)]
      ),
      color: payload.color
    }
    const searchObj = state.campaignPerformanceChartsOptions.find(
      v => v.name === payload.prop
    )
    const ind = state.campaignPerformanceChartsOptions.indexOf(searchObj)
    if (
      state.campaignPerformanceChartsOptions.filter(
        v => String(v.name) === String(obj.name)
      ).length === 0
    ) {
      state.campaignPerformanceChartsOptions.push(obj)
    } else {
      state.campaignPerformanceChartsOptions.splice(ind, 1)
    }
  },
  [REMOVE_CHART] (state, name) {
    if (state.campaignPerformanceChartsOptions.find(v => v.name === name)) {
      state.campaignPerformanceChartsOptions.splice(
        state.campaignPerformanceChartsOptions.indexOf(
          state.campaignPerformanceChartsOptions.find(v => v.name === name)
        ),
        1
      )
    }
  },
  [CHANGE_SHOW_STATE] (state, payload) {
    const chart = state.campaignPerformanceOrdered.find(
      v => v.name === payload.name
    )
    chart.showState = payload.showState
  },
  [CAMPAIGN_PERFORMANCE_CHART_TITLES] (state) {
    const result = []
    const displayedFields = [
      { name: 'impressions', title: 'Impressions' },
      { name: 'reach', title: 'Reach' },
      { name: 'spend', title: 'Spend' },
      { name: 'clicks', title: 'Clicks' },
      { name: 'unique_clicks', title: 'Engagement clicks' },
      { name: 'frequency', title: 'Frequency' },
      { name: 'ctr', title: 'CTR' },
      { name: 'cpm', title: 'CPM' },
      { name: 'cpc', title: 'CPC' },
      { name: 'cpp', title: 'CPP' }
    ]
    if (state.campaignPerformance !== []) {
      const obj = state.campaignPerformance.total[0]
      for (const item in obj) {
        if (displayedFields.find(v => v.name === item)) {
          let showSt = false
          let ids = 0
          if (state.campaignPerformanceOrdered.find(v => v.name === item)) {
            showSt = state.campaignPerformanceOrdered.find(v => v.name === item)
              .showState
            ids = state.campaignPerformanceOrdered.find(v => v.name === item).id
          } else {
            ids = result.length
          }
          result.push({
            id: ids,
            showState: showSt,
            name: item,
            value: obj[item],
            title:
            displayedFields[
              displayedFields.indexOf(
                displayedFields.find(v => v.name === item)
              )].title
          })
        }
      }
    }
    state.campaignPerformanceOrdered = result
  },
  [CLEAR_STATE] (state) {
    const clearState = initialState()

    for (const field in state) {
      state[field] = clearState[field]
    }
  }
}

const actions = {
  get_campaign_details ({ dispatch, commit }, id) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .get_campaign_detail(id)
        .then(response => {
          commit(SET_STATE_PROP, {
            prop: 'campaignDetail',
            value: response.data
          })
          resolve(response)
        })['catch'](error => {
          reject(error)
        })
    })
  },
  get_report_adSets ({ dispatch, commit }, campaign) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .get_report_adSets(campaign)
        .then(response => {
          commit(SET_STATE_PROP, {
            prop: 'dashboardAdSets',
            value: response.data.data
          })
          commit(SET_STATE_PROP, {
            prop: 'locales',
            value: response.data.meta.locales
          })
          resolve(response)
        })['catch'](error => {
          reject(error)
        })
    })
  },
  get_campaign_performance ({ dispatch, commit, state }, payload) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .get_campaign_performance(
          payload.id,
          payload.startDate,
          payload.endDate
        )
        .then(response => {
          commit(SET_CAMPAIGN_PERFORMANCE, response.data.data)
          commit(SET_CAMPAIGN_PERFORMANCE_META, response.data.meta)
          commit(UPDATE_CHARTS)
          commit(CAMPAIGN_PERFORMANCE_CHART_TITLES)
          resolve(response)
        })['catch'](error => {
          reject(error)
        })
    })
  },
  get_campaign_best_period ({ dispatch, commit }, id) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .get_campaign_best_period(id)
        .then(response => {
          commit(SET_STATE_PROP, {
            prop: 'bestPeriod',
            value: response.data.data
          })
          commit(SET_STATE_PROP, {
            prop: 'mainMetric',
            value: response.data.meta.mainMetric
          })
          resolve(response)
        }).catch(error => {
          reject(error)
        })
    })
  },
  get_campaign_top_factors ({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .get_campaign_top_factors(
          payload.id,
          payload.startDate,
          payload.endDate
        )
        .then(response => {
          commit(SET_STATE_PROP, {
            prop: 'topFactors',
            value: response.data.data
          })
          resolve(response)
        }).catch(error => {
          reject(error.response)
        })
    })
  },
  get_campaign_audience_inspector ({ dispatch, commit }, id) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .get_campaign_audience_inspector(id)
        .then(response => {
          commit(SET_AUDIENCE_INSPECTOR_PROP, {
            prop: 'data',
            value: response.data.data
          })
          commit(SET_AUDIENCE_INSPECTOR_PROP, {
            prop: 'breakdowns',
            value: response.data.meta.breakdowns
          })
          commit(SET_AUDIENCE_INSPECTOR_PROP, {
            prop: 'costPerActions',
            value: response.data.meta.costPerActions
          })
          // console.log(response)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
    })
  }
  // get_campaign_targeting ({ dispatch, commit, state }, id) {
  //   return new Promise((resolve, reject) => {
  //     adsCampaign
  //       .get_campaign_targeting(id)
  //       .then(response => {
  //         commit(SET_STATE_PROP, {
  //           prop: 'campaignTargeting',
  //           value: response.data.data.attributes.audiences_data
  //         })
  //         commit(SET_STATE_PROP, {
  //           prop: 'locales',
  //           value: response.data.meta.locales
  //         })
  //         resolve(response)
  //       }).catch(error => {
  //         reject(error)
  //       })
  //   })
  // }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
