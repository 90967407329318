





import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
export default Vue.extend({
  name: 'FirstPage',
  components: {
    BasePagePreloader
  },
  computed: {
    ...mapGetters('auth', ['is_authorized'])
  },
  methods: {
    ...mapActions('profile', ['getProfile'])
  },
  mounted () {
    this.getProfile()
      .then(() => {
        if (this.is_authorized) {
          this.$router.push({ name: 'AutomatedRules', params: { service: 'facebook' } })
        } else {
          this.$router.push('/registration/sign-in')
        }
      })
      .catch(() => {
        this.$router.push('/registration/sign-in')
      })
  }
})
