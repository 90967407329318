import Vue from 'vue'
import InterestsService from '@/services/api/InterestsService'
import {
  RESET_SEARCH_RESULTS,
  SET_LOCALES,
  SET_LOADER,
  SET_STATE_PROP,
  SET_STATE_FIELD,
  ADD_TO_SELECTED_PROJECTS,
  DELETE_FROM_SELECTED_PROJECTS,
  DELETE_COLLECTION_ITEM,
  SET_FILTERS,
  APPLY_FILTERS,
  SET_AUDIENCE_SIZES,
  SET_MIN_MAX_AUDIENCE_SIZES,
  ADD_FILTER_WORD,
  CHANGE_TYPE_FILTER_WORD,
  ADD_TO_SELECTED_TARGETING_TYPE,
  ADD_TO_INTEREST_COLLECTION,
  DELETE_FROM_INTEREST_COLLECTION,
  ADD_SEARCH_WORD,
  DELETE_SEARCH_WORD,
  DIVIDE_INTERESTS_COLLECTION
} from '@/store/mutationsConstants/mutationInterests'

export default {
  namespaced: true,
  state: {
    adSets: [],
    campaigns: [],
    // list of project from database
    projects: [],
    selectedProjects: [],
    // new project
    name: '',
    description: '',
    errors: [],
    // list of words from InterestsExploreSearchInput component (input tags)
    locales: [],
    selectLocale: 'en_US',
    searchWords: [],
    modalLoading: false,
    // list after request 'search' (not suggestion)
    loadingSearch: false,
    initialSearchResults: [],
    searchResults: [],
    // list after request 'suggestion'
    suggestionResults: [],
    // filters
    audienceSize: [],
    minAudienceSize: 0,
    maxAudienceSize: 1000,
    rangeAudienceSizes: [1, 1000],
    allTargetingTypes: [],
    targetingTypes: [],
    selectedTargetingTypes: [],
    addWordValue: '',
    addWordError: '',
    allFilterWords: [],
    filterWords: [],
    // variable for block button 'Apply' (made by design - it is not yet clear why)
    appliedLayeringChanges: false,
    // main array for updating project
    loadingInterestsCollection: false,
    interestsCollection: [],
    dividingLimit: 0
  },
  getters: {
    getError (state) {
      return function (field) {
        return state.errors.find(v => v.source.parameter === field)?.detail[0]
      }
    },
    getInterestsCollectionLayering (state) {
      const layers = [
        { status: false, data: [] },
        { status: false, data: [] },
        { status: false, data: [] }
      ]
      state.interestsCollection.forEach((item, index) => {
        layers[item.layer - 1].data.push(item)
        layers[item.layer - 1].status = layers[item.layer - 1].data.length > 0
      })
      return layers
    },
    getInterestCollectionSending (state, getters) {
      const layers = []
      let flexible_spec = {}
      getters.getInterestsCollectionLayering.forEach((item, index) => {
        flexible_spec = {
          interests: [],
          behaviors: [],
          life_events: [],
          industries: [],
          income: [],
          family_statuses: []
        }
        item.data.forEach(it => {
          flexible_spec[it.type].push({
            name: it.name,
            audience_size: it.audience_id,
            fb_id: it.audience_id
          })
        })
        const clean_flexible_spec = {}
        Object.keys(flexible_spec).forEach(it => {
          if (flexible_spec[it].length > 0) {
            clean_flexible_spec[it] = flexible_spec[it]
          }
        })
        layers[index] = clean_flexible_spec
      })
      return layers.filter(v => Object.keys(v).length !== 0)
    }
  },
  mutations: {
    [RESET_SEARCH_RESULTS] (state) {
      state.searchWords = []
      state.searchResults = []
      state.initialSearchResults = []
      state.suggestionResults = []
      state.audienceSize = []
      state.minAudienceSize = 0
      state.maxAudienceSize = 1000
      state.rangeAudienceSizes = [1, 1000]
      state.allTargetingTypes = []
      state.targetingTypes = []
      state.selectedTargetingTypes = []
      state.allFilterWords = []
      state.filterWords = []
    },
    [SET_LOCALES] (state, data) {
      state.locales = data
    },
    [SET_LOADER] (state, { field, value }) {
      Vue.set(state, field, value)
    },
    [SET_STATE_PROP] (state, { prop, value }) {
      Vue.set(state, prop, value)
    },
    [SET_STATE_FIELD] (state, { field, value }) {
      state.errors[field] = []
      const ind = state.errors.indexOf(state.errors.find(v => v.source.parameter === field))
      state.errors.splice(ind, 1)
      Vue.set(state, field, value)
    },
    [ADD_TO_SELECTED_PROJECTS] (state, projectId) {
      if (state.selectedProjects.filter(v => v === projectId).length === 0) {
        state.selectedProjects.push(projectId)
      }
    },
    [DELETE_FROM_SELECTED_PROJECTS] (state, projectId) {
      const ind = state.selectedProjects.indexOf(projectId)
      state.selectedProjects.splice(ind, 1)
    },
    [DELETE_COLLECTION_ITEM] (state, audienceId) {
      const itemIndex = state.interestsCollection.indexOf(state.interestsCollection.find(item => item.audience_id === audienceId))
      state.interestsCollection.splice(itemIndex, 1)
    },
    [SET_FILTERS] (state, data) {
      state.filterWords = []
      state.allFilterWords = []
      data.forEach((item) => {
        state.allTargetingTypes.push(item.path)
        // filter words
        item.name.split(' ').forEach((it) => {
          // excluding short words
          if (it.length > 2) {
            state.allFilterWords.push(it.toLowerCase().replace(/[^\w\s]|_/g, ''))
          }
        })
        // audience sizes
        state.audienceSize.push(item.audience_size)
        state.minAudienceSize = Math.min.apply(null, state.audienceSize)
        state.maxAudienceSize = Math.max.apply(null, state.audienceSize)
        state.rangeAudienceSizes = [state.minAudienceSize, state.maxAudienceSize]
      })
      // counting the number of words
      const wordsCounter = state.allFilterWords.reduce((acc, rec, index) => {
        return (typeof acc[rec] !== 'undefined')
          ? { ...acc, [rec]: acc[rec] + 1 }
          : { ...acc, [rec]: 1 }
      }, {})
      // form an array of objects
      const pred = []
      Object.keys(wordsCounter).forEach((name, index) => {
        if (name.length > 3) {
          pred.push({ name: name, type: 'do nothing', sum: wordsCounter[name] })
        }
      })
      state.filterWords = pred
      // sort in descending order by quantity
      state.filterWords.sort(function (a, b) {
        if (a.sum > b.sum) return -1
        if (a.sum < b.sum) return 1
        return 0
      })
      // take the 10 most common words
      state.filterWords = state.filterWords.slice(0, 10)
      state.filterWords.map((item, index) => {
        item.id = index
      })
      const targetingTypesCounter = state.allTargetingTypes.reduce(function (acc, el) {
        el.splice(el.length - 1, 1)
        acc[el] = (acc[el] || 0) + 1
        return acc
      }, {})
      state.targetingTypes = Object.keys(targetingTypesCounter).map(function (name, index) {
        return { id: index, data: name, sum: targetingTypesCounter[name] }
      })
    },
    [APPLY_FILTERS] (state) {
      state.searchResults = state.initialSearchResults
      state.searchResults = state.searchResults.filter(item =>
        item.audience_size >= Number(state.rangeAudienceSizes[0]) &&
        item.audience_size <= Number(state.rangeAudienceSizes[1]))
      const wordIncludeFilter = state.filterWords.filter(v => v.type === 'include')
      const wordExcludeFilter = state.filterWords.filter(v => v.type === 'exclude')

      const intermediateInc = []
      if (wordIncludeFilter && wordIncludeFilter.length > 0) {
        wordIncludeFilter.forEach(word => {
          const ab = state.initialSearchResults.filter(item =>
            item.name
              .toLowerCase()
              .replace(/[^\w\s]|_/g, '')
              .split(' ').join()
              .indexOf(word.name
                .toLowerCase()
                .replace(/[^\w\s]|_/g, '').split(' ').join()) !== -1)
          ab.forEach(i => {
            if (intermediateInc.filter(v => v.id === i.id).length === 0) {
              intermediateInc.push(i)
            }
          })
        })
        state.searchResults = intermediateInc
      }

      let intermediateExc = state.searchResults
      if (wordExcludeFilter && wordExcludeFilter.length > 0) {
        wordExcludeFilter.forEach(word => {
          const ab = intermediateExc.filter(item =>
            item.name
              .toLowerCase()
              .replace(/[^\w\s]|_/g, '')
              .split(' ').join()
              .indexOf(word.name
                .toLowerCase()
                .replace(/[^\w\s]|_/g, '').split(' ').join()) === -1)
          intermediateExc = ab
        })
        state.searchResults = intermediateExc
      }

      if (state.selectedTargetingTypes.length > 0) {
        state.searchResults = state.searchResults.filter(val => state.selectedTargetingTypes.some((element) => element.data === val.path.join()))
      }
    },
    [SET_AUDIENCE_SIZES] (state, data) {
      state.rangeAudienceSizes = data
    },
    [SET_MIN_MAX_AUDIENCE_SIZES] (state, data) {
      state.minAudienceSize = data[0]
      state.maxAudienceSize = data[1]
    },
    [ADD_FILTER_WORD] (state, data) {
      if (state.filterWords
        .filter(v => v.name
          .toLowerCase()
          .replace(/\s/g, '')
          .split(' ')
          .join('') === data.toLowerCase().replace(/\s/g, '').split(' ').join('')).length === 0) {
        let counter = 0
        state.initialSearchResults.forEach(item => {
          if (item.name.toLowerCase().replace(/[^\w\s]|_/g, ' ').split(' ').join('').indexOf(
            data.toLowerCase().replace(/[^\w\s]|_/g, '').split(' ').join('')
          ) !== -1) {
            counter += 1
          }
        })
        state.filterWords.push({ id: state.filterWords.length, type: 'do nothing', name: data, sum: counter })
        state.addWordValue = ''
        state.addWordError = ''
      } else {
        state.addWordError = 'You cannot add a word that is already in the list'
      }
    },
    [CHANGE_TYPE_FILTER_WORD] (state, { id, type }) {
      if (type !== 'delete') {
        state.filterWords.filter(item => item.id === id)[0].type = type
      } else {
        const ind = state.filterWords
          .indexOf(state.filterWords
            .find(item => item.id === id))
        state.filterWords.splice(ind, 1)
      }
    },
    [ADD_TO_SELECTED_TARGETING_TYPE] (state, { id, status }) {
      if (status) {
        state.selectedTargetingTypes.push(state.targetingTypes.filter(item => item.id === id)[0])
      } else {
        const ind = state.selectedTargetingTypes
          .indexOf(state.selectedTargetingTypes
            .filter(item => item.id === id)[0])
        state.selectedTargetingTypes.splice(ind, 1)
      }
    },
    [ADD_TO_INTEREST_COLLECTION] (state, data) {
      const element = data.interestId
        ? state.searchResults.filter(val => val.id === data.interestId)[0]
        : state.suggestionResults.filter(val => val.id === data.suggestionId)[0]
      element.layer = 1
      element.audience_size = String(element.audience_size)
      element.audience_id = element.id
      element.project_id = Number(data.projectId)
      element.path = String(element.path)
      element.string_path = String(element.path)
      element.type = String(element.type)
      element.topic = null
      if (state.interestsCollection.filter(v => v.audience_id === element.audience_id).length === 0) {
        state.interestsCollection.push(element)
      }
      state.appliedLayeringChanges = false
    },
    [DELETE_FROM_INTEREST_COLLECTION] (state, data) {
      const interestIndex = state.interestsCollection.indexOf(state.interestsCollection.find(val => Number(val.audience_id) === Number(data)))
      state.interestsCollection.splice(interestIndex, 1)
      state.appliedLayeringChanges = false
    },
    [ADD_SEARCH_WORD] (state, data) {
      state.searchWords.push(data)
    },
    [DELETE_SEARCH_WORD] (state, ind) {
      state.searchWords.splice(ind, 1)
    },
    [DIVIDE_INTERESTS_COLLECTION] (state, data) {
      const block = Math.ceil(state.interestsCollection.length / state.dividingLimit)
      switch (data) {
        case 'size':
          state.interestsCollection.sort(function (a, b) {
            if (Number(a.audience_size) > Number(b.audience_size)) return -1
            if (Number(a.audience_size) < Number(b.audience_size)) return 1
            return 0
          })
          break
        case 'randomly':
          state.interestsCollection.sort(() => Math.round(Math.random() * 100) - 50)
          break
      }
      if (state.dividingLimit === 2) {
        state.interestsCollection.map((item, index) => {
          if (index >= 0 && index < block) item.layer = 1
          if (index >= block) item.layer = 2
        })
      }
      if (state.dividingLimit === 3) {
        state.interestsCollection.map((item, index) => {
          if (index >= 0 && index < block) item.layer = 1
          if (index >= block && index < block * 2) item.layer = 2
          if (index >= block * 2) item.layer = 3
        })
      }
    }
  },
  actions: {
    // CRUD operations
    mainRequest ({ dispatch, commit, state }, callback) {
      state.modalLoading = true
      return new Promise((resolve, reject) => {
        callback
          .then((response) => {
            dispatch(
              'notifications/addNotification',
              { id: Date.now(), body: 'Success', type: 'success' },
              { root: true }
            )
            resolve(response)
          })
          .catch((error) => {
            state.errors = error.response.data.errors
            dispatch(
              'notifications/addNotification',
              { id: Date.now(), body: 'Error', type: 'danger' },
              { root: true }
            )
            console.log(error)
            reject(error)
          })
          .finally(() => {
            state.modalLoading = false
          })
      })
    },
    getCollections ({ commit }, data) {
      return new Promise((resolve, reject) => {
        InterestsService.getCollections(data)
          .then((response) => {
            commit(SET_STATE_FIELD, { field: 'projects', value: response.data.data })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getCollectionData ({ dispatch, commit }, data) {
      commit(SET_LOADER, { field: 'loadingInterestsCollection', value: true })
      return new Promise((resolve, reject) => {
        InterestsService.getCollectionData(data)
          .then((response) => {
            commit(SET_STATE_FIELD, { field: 'interestsCollection', value: response.data.data })
            commit(SET_LOADER, { field: 'loadingInterestsCollection', value: false })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createCollection ({ dispatch, state }) {
      const createData = {
        ad_account: 0,
        name: state.name,
        description: state.description
      }
      return dispatch('mainRequest',
        InterestsService.createCollection(createData))
    },
    saveCollection ({ dispatch, state }, id: number) {
      const updateData = {
        data: state.interestsCollection,
        id: id
      }
      return dispatch('mainRequest',
        InterestsService.updateCollection(updateData))
    },
    cloneCollection ({ dispatch, commit, state }, data) {
      const cloningCollectionData = {
        id: data.id,
        name: state.name,
        description: state.description
      }
      return dispatch('mainRequest',
        InterestsService.cloneCollection(cloningCollectionData))
    },
    mergeCollections ({ dispatch, commit, state }, data) {
      const mergeCollections = { name: state.name, id_arr: state.selectedProjects }
      return dispatch('mainRequest',
        InterestsService.mergeCollections(mergeCollections))
    },
    deleteCollection ({ dispatch, commit, state }, data) {
      let deleteCollectionData
      if (data) {
        deleteCollectionData = { id: data }
      } else {
        deleteCollectionData = { id: state.selectedProjects }
      }
      return dispatch('mainRequest',
        InterestsService.deleteCollection(deleteCollectionData))
    },

    // search operations
    getLocalesList ({ commit }) {
      return new Promise((resolve, reject) => {
        InterestsService.getLocales()
          .then((response) => {
            commit(SET_LOCALES, response.data)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },
    getAdsets ({ dispatch, commit, state }, data) {
      return new Promise((resolve, reject) => {
        InterestsService.getAdsets(data)
          .then((response) => {
            commit(SET_STATE_FIELD, { field: 'adSets', value: response.data.fb_data.data })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getCampaigns ({ dispatch, commit, state }, data) {
      return new Promise((resolve, reject) => {
        InterestsService.getCampaigns(data)
          .then((response) => {
            commit(SET_STATE_FIELD, { field: 'campaigns', value: response.data.fb_data.data })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    searchInterests ({ dispatch, commit, state }, data) {
      commit(SET_LOADER, { field: 'loadingSearch', value: true })
      const searchData = {
        arr_word: data,
        locale: state.selectLocale
      }
      return new Promise((resolve, reject) => {
        commit(SET_STATE_FIELD, { field: 'allTargetingTypes', value: [] })
        state.audienceSize = []
        state.allFilterWords = []
        state.filterWords = []
        state.rangeAudienceSizes = []
        InterestsService.searchInterests(searchData)
          .then((response) => {
            commit(SET_LOADER, { field: 'loadingSearch', value: false })
            commit(SET_STATE_FIELD, {
              field: 'initialSearchResults',
              value: response.data.data.map(v => {
                if (!v.type) {
                  v.type = 'interests'
                }
                return v
              })
            })
            commit(SET_STATE_FIELD, {
              field: 'searchResults',
              value: response.data.data.map(v => {
                if (!v.type) {
                  v.type = 'interests'
                }
                return v
              })
            })
            commit(SET_FILTERS, response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    searchSuggestions ({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        InterestsService.searchSuggestions(data)
          .then((response) => {
            commit(SET_STATE_FIELD, {
              field: 'suggestionResults',
              value: response.data.data.map(v => {
                if (!v.type) {
                  v.type = 'interests'
                }
                return v
              })
            })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
