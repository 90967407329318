import router from '@/router'
import { FACEBOOK_SERVICE } from '@/constants/FbAutomatedRule'

export default function ({ from, store, to, nextMiddleware }): void | Promise<unknown> {
  const service = to.params?.service ? to.params.service : (to.meta?.service || FACEBOOK_SERVICE)

  if (!to.meta.permissionCheckKey ||
    ((store.getters['profile/getUserUses'] && store.getters['profile/getUserUses'][to.meta.permissionCheckKey][service]))
  ) {
    nextMiddleware()
  } else {
    if (from.name !== 'FeatureUnavailable') {
      return router.push({ name: 'FeatureUnavailable' })
    }
  }
}
