import { ActionTree, MutationTree } from 'vuex'
import StrategiesService from '@/services/api/StrategiesService'
import RulesService from '@/services/api/RulesService'
import IStrategies from '@/models/IStrategies'
import Jsona from 'jsona'
import Vue from 'vue'
import {
  SET_STRATEGIES,
  SET_STRATEGY,
  SET_SERVER_DATA,
  SET_GROUPS
} from '@/store/mutationsConstants/mutationStrategy'

const initialState = () : { strategies: []; serverData: { taskActions: []; timeRanges: []; metricsWithSchedule: []; triggers: []; customMetrics: [], groups: [] }; strategyById: [] } => ({
  strategies: [],
  strategyById: [],
  serverData: {
    timeRanges: [],
    taskActions: [],
    triggers: [],
    customMetrics: [],
    metricsWithSchedule: [],
    groups: []
  }
})

const mutations = <MutationTree<IStrategies>> {
  [SET_STRATEGIES] (state: IStrategies, data: []) {
    state.strategies = data
  },
  [SET_STRATEGY] (state: IStrategies, data: []) {
    state.strategyById = data
  },
  [SET_SERVER_DATA] (state, { field, data }) {
    if (field === 'triggers') {
      data.map(group => {
        group.fields.map(metric => {
          if (!metric.trigger) {
            state.serverData.metricsWithSchedule.push(metric.field)
          }
        })
      })
    }
    Vue.set(state.serverData, field, data)
  },
  [SET_GROUPS] (state: IStrategies, data) {
    state.serverData.groups = data
  }
}
const actions = <ActionTree<IStrategies, any>>{
  loadStrategies ({ dispatch, rootGetters }, search) {
    return new Promise((resolve, reject) => {
      StrategiesService.getStrategies(rootGetters['adService/getService'], search)
        .then(response => {
          const dataFormatter = new Jsona()
          dispatch('setStrategies', dataFormatter.deserialize(response.data))
          resolve(response.status)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  // получение серверных данных пользователя для формирования собственных правил, используется также в
  // отображении рулов в превью стратегии (task_actions, timeRanges, triggers)
  // запрос идет через сервис для рулов (также используется при создании рулов но в другом модуле стора)
  getServerData ({ commit }, { service, route, field }) {
    return RulesService.getServerData(service, route).then(res => {
      commit('setServerData', { field, data: res.data.data })
      return res.data.data
    })
  },
  loadGroups ({ dispatch, rootGetters }) {
    return new Promise((resolve, reject) => {
      StrategiesService.getServerData(rootGetters['adService/getService'], 'groups')
        .then(response => {
          dispatch('setGroups', response.data)
          resolve(response)
        }).catch(er => {
          reject(er)
        })
    })
  },
  setStrategies (context, strategies: IStrategies) {
    context.commit(SET_STRATEGIES, strategies)
  },
  setGroups (context, data) {
    context.commit(SET_GROUPS, data)
  },
  setStrategy (context, strategy: IStrategies) {
    context.commit(SET_STRATEGY, strategy)
  },
  setServerData (context, data: any) {
    context.commit(SET_SERVER_DATA, data)
  },
  getStrategyDetail ({ dispatch, rootGetters }, { id, params = {} }) {
    return new Promise((resolve, reject) => {
      StrategiesService.getDetail(rootGetters['adService/getService'], id, params)
        .then(res => {
          const dataFormatter = new Jsona()
          dispatch('setStrategy', dataFormatter.deserialize(res.data))
          resolve(res.status)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  applyStrategies ({ dispatch, rootGetters }, { strategyId, data }) {
    return new Promise(resolve => {
      StrategiesService.applyStrategy(rootGetters['adService/getService'], strategyId, data)
        .then(res => {
          // res.data.id  -  job id for checking result
          resolve(res.data.data.id)
        })
        .catch(e => dispatch('errorHandler', e))
    })
  },
  getApplyingJobResult ({ rootGetters }, { id }) {
    return new Promise(resolve => {
      StrategiesService.getApplyingJobResult(rootGetters['adService/getService'], id).then(res => {
        resolve(res.data.data.attributes)
      })
    })
  }
}
const getters = {
  strategies (state: IStrategies) {
    return state.strategies
  },
  strategy (state: IStrategies) {
    return state.strategyById
  },
  selectedCustomMetric: state => id => {
    return (
      state.serverData.customMetrics &&
      state.serverData.customMetrics.filter(item => item.id === id)[0]
    )
  },
  groups (state: IStrategies) {
    return state.serverData.groups
  },
  groupName: state => key => {
    return state.serverData.groups[key]
  },
  selectedEvaluationField: state => key => {
    let res = null
    state.serverData.triggers &&
    state.serverData.triggers.map(group => {
      group.fields.map(item => {
        if (item.field === key) res = item
      })
      if (res) return null
    })
    return res
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
