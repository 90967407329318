import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import vSelect from 'vue-select'
import Toaster from 'v-toaster'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import Paginate from 'vuejs-paginate'
import Card from './components/Card.vue'
import VueClickaway from 'vue-clickaway'
import VTooltip from 'v-tooltip'
import VueGtm from 'vue-gtm'
import DebugPanel from './components/DebugPanel.vue'
import PreloaderLine from './components/PreloaderLine.vue'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VueI18n from 'vue-i18n'
import 'bootstrap/js/dist/dropdown.js'

Vue.component('v-select', vSelect)
Vue.component('paginate', Paginate)
Vue.component('debug-panel', DebugPanel)
Vue.component('Card', Card)
Vue.component('PreloaderLine', PreloaderLine)
Vue.config.productionTip = false
Vue.use(Toaster, { timeout: 5000 })
Vue.use(VModal, { dynamic: true, injectModalsContainer: true })
Vue.use(VTooltip)
Vue.use(VueMoment, {
  moment
})
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueI18n)
if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    queryParams: {},
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  })
}

new Vue({
  mixins: [VueClickaway.mixin],
  router,
  store,
  render: h => h(App)
}).$mount('#app')
