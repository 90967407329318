import adsCampaign from '@/services/api/AdsManagerCampaignService'
import Jsona from 'jsona'
import IBudgetState from '@/models/AdsManager/IBudgetState'
import IBudget from '@/models/AdsManager/IBudget'
import IBudgetAdSetSpendCap from '@/models/AdsManager/IBudgetsAdSetSpendCap'
import {
  SET_CAMPAIGN_BUDGET,
  SET_AD_SET_SPEND_CAP,
  SET_OPTIMIZATION_TYPE,
  SET_ATTRIBUTION_SPEC,
  SET_BIDDING,
  SET_CAMPAIGN_DETAILS_META,
  SET_ERRORS
} from '@/store/mutationsConstants/adsManagerMutations/mutationBudget'
import { ActionTree, MutationTree } from 'vuex'

const initialState = (): IBudgetState => ({
  campaignDetailsMeta: {},
  campaignBudget: <IBudget>{},
  budgetsAdSetSpendCap: <IBudgetAdSetSpendCap> {},
  initialBudgetsAdSetSpendCap: null,
  specData: [],
  optimizationTypes: [
    {
      label: 'Set AdSet budget manually',
      value: 'manually'
    },
    {
      label: 'Evenly across all AdSets',
      value: 'evenly'
    },
    {
      label: 'Proportionally based on AdSet audiences size',
      value: 'proportionally'
    }
  ],
  optimizationType: null,
  checkBidding: {
    costCap: false,
    lowestCostWithBidCap: false,
    lowestCostWithMinRoas: false,
    lowestCostWithoutCap: false
  },
  errors: []
})

const getters = {
  getOptimizationType (state) {
    return state.optimizationType
  },
  getOptimizationTypes (state) {
    return state.optimizationTypes
  },
  getCampaigns_budget_data (state: IBudgetState): Record<string, IBudget> {
    return state.campaignBudget
  },
  getInitialBudgetsAdSetSpendCap (state) {
    return state.initialBudgetsAdSetSpendCap
  },
  getBudgetsAdSetSpendCap (state: IBudgetState): Record<string, any> {
    return state.budgetsAdSetSpendCap
  },
  getCurrencySymbol (state: IBudgetState): string {
    return state.campaignBudget?.meta?.currency?.symbol
  },
  getCheckBidding (state: IBudgetState) {
    return state.checkBidding
  },

  getAttributionSpec (state: IBudgetState): any {
    const result = []
    state.specData.data.click.forEach((spec) => {
      result.push({
        label: spec.title,
        code: spec.value
      })
    })
    state.specData.data.click_or_view.forEach((spec) => {
      result.push({
        label: spec.title,
        code: spec.value
      })
    })
    return result
  },
  getCampaignBudgetDetailsMeta (state) {
    return state.campaignDetailsMeta
  },
  getMinBudgetAdSet () {
    return function (minBudgetData, optimize_for) {
      const minBudgetDailyLowFreq = minBudgetData?.min_budgets?.min_daily_budget_low_freq
      const minBudgetHighFreq = minBudgetData?.min_budgets?.min_daily_budget_high_freq
      const dailyBudgetImp = minBudgetData?.min_budgets?.min_daily_budget_imp

      switch (optimize_for) {
        case 'IMPRESSIONS':
        case 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS':
        case 'AD_RECALL_LIFT':
        case 'REACH':
        case 'OFFSITE_CONVERSIONS':
          return dailyBudgetImp
        case 'LINK_CLICKS':
        case 'PAGE_LIKES':
        case 'POST_ENGAGEMENT':
        case 'LANDING_PAGE_VIEWS':
          return minBudgetHighFreq
        default:
          return minBudgetDailyLowFreq
      }
    }
  }
}

const mutations = <MutationTree<IBudgetState>> {
  [SET_CAMPAIGN_BUDGET] (state: IBudgetState, budget): void {
    state.campaignBudget = budget
  },

  [SET_AD_SET_SPEND_CAP] (state: IBudgetState, budgetsAdSetSpendCap):void {
    budgetsAdSetSpendCap.map(adSet => {
      adSet.amount = adSet.budget
      adSet.min = adSet.min_spend_target
      adSet.max = adSet.max_spend_cap
    })
    state.budgetsAdSetSpendCap = budgetsAdSetSpendCap
  },
  setInitialAdSetSpendCap (state, budgetsAdSetSpendCap) {
    budgetsAdSetSpendCap.map(adSet => {
      adSet.amount = adSet.budget
      adSet.min = adSet.min_spend_target
      adSet.max = adSet.max_spend_cap
    })
    state.initialBudgetsAdSetSpendCap = JSON.parse(JSON.stringify(budgetsAdSetSpendCap))
  },
  [SET_OPTIMIZATION_TYPE] (state: IBudgetState, type) {
    state.optimizationType = type
  },
  [SET_ATTRIBUTION_SPEC] (state: IBudgetState, specData) {
    state.specData = specData
  },
  [SET_BIDDING] (state: IBudgetState, biddingData) {
    state.checkBidding = biddingData
  },
  [SET_CAMPAIGN_DETAILS_META] (state, meta) {
    state.campaignDetailsMeta = meta
  },
  [SET_ERRORS] (state, error) {
    // TODO: прописать условие
    state.errors.push(error)
  }
}

const actions = <ActionTree<string, any>> {
  async getCampaigns_budget ({ dispatch }, idCampaign: string): Promise<void> {
    try {
      const response = await adsCampaign.get_budget(idCampaign)
      dispatch('setCampaigns_budget', response.data)
    } catch (e) {
      console.log(e)
    }
  },

  async load_attribution_spec ({ dispatch }, campaignId: number) {
    return new Promise((resolve, reject) => {
      adsCampaign.getAttributionSpec(campaignId)
        .then(response => {
          dispatch('_setAttributionSpec', response.data)
          resolve(response)
        })
        .catch(er => reject(er))
    })
  },
  _setAttributionSpec (context: Record<any, any>, specData: Record<any, any>) {
    context.commit(SET_ATTRIBUTION_SPEC, specData)
  },

  async create_budget ({ dispatch }, requestBody) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .create_budget(requestBody)
        .then(r => resolve(r))
        .catch(er => reject(er))
    })
  },

  setCampaigns_budget (context, budget): void {
    context.commit(SET_CAMPAIGN_BUDGET, budget)
  },

  // async load_adSet_spendCap ({ dispatch }, requestBody): Promise<void> {
  //   try {
  //     const response = await adsCampaign.get_adSet_spendCap(requestBody)
  //     const dataFormatter = new Jsona()
  //     dispatch('setAdSetSpendCap', dataFormatter.deserialize(response.data))
  //     dispatch('setInitialAdSetSpendCap', dataFormatter.deserialize(response.data))
  //   } catch (e) {
  //     console.log(e)
  //   }
  // },

  load_adSet_spendCap ({ dispatch }, requestBody): Promise<any> {
    return new Promise((resolve, reject) => {
      adsCampaign.get_adSet_spendCap(requestBody).then((res) => {
        const dataFormatter = new Jsona()
        console.log(res)
        dispatch('setAdSetSpendCap', dataFormatter.deserialize(res.data))
        dispatch('setInitialAdSetSpendCap', dataFormatter.deserialize(res.data))
        dispatch('adsManager/set_locales', res.data.meta.locales, { root: true })
        resolve(res)
      }).catch((error) => {
        console.log(error)
        reject(error)
      })
    })
  },

  setAdSetSpendCap (context, budgetAdSet): void {
    context.commit(SET_AD_SET_SPEND_CAP, budgetAdSet)
  },
  setInitialAdSetSpendCap (context, budgetAdSet): void {
    context.commit('setInitialAdSetSpendCap', budgetAdSet)
  },
  setOptimizationType (context, type) {
    context.commit(SET_OPTIMIZATION_TYPE, type)
  },
  update_adSet_spendCap ({ dispatch }, requestBody): Promise<void> {
    return new Promise((resolve, reject) => {
      adsCampaign
        .update_adSet_spendCap(requestBody)
        .then(response => resolve(response.data)).catch(er => reject(er.response))
    })
  },
  async load_bidding ({ dispatch }, requestBody: { id: number, optimize: string }) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_bidding(requestBody)
        .then(response => {
          dispatch('_setBidding', response.data.data)
          resolve(response)
        }).catch(er => reject(er))
    })
  },

  _setCampaignBudgetDetailsMeta (context, meta):void {
    context.commit(SET_CAMPAIGN_DETAILS_META, meta)
  },

  _setBidding (context, bidding): void {
    context.commit(SET_BIDDING, bidding)
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
