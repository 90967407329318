import { AxiosResponse } from 'axios'
import http from '@/http-common'
import urlGen from '@/utils/urlGen'

export default {
  getStrategies (service: string, search = {}): Promise<AxiosResponse> {
    const params = urlGen.addSearch(search)
    return http.get(`/ad_strategies/${service}?${params.toString()}`)
  },
  getDetail (service: string, id, search = {}) {
    const params = urlGen.addSearch(search)
    const url = `/ad_strategies/${service}` + `${id ? `/${id}` : ''}`
    return http.get(`${url}?${params.toString()}`)
  },
  getServerData (service: string, route) {
    const url = `/ad_strategies/${service}/${route}`
    return http.get(url)
  },
  applyStrategy (service: string, strategyId, data) {
    return http.post(`/ad_strategies/${service}/${strategyId}/apply`, data)
  },
  getApplyingJobResult (service: string, jobId) {
    const url = `/ad_strategies/${service}/jobs/${jobId}`
    return http.get(url)
  }
}
