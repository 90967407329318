/* eslint dot-notation: ["error", { "allowPattern": "^[a-z]+(_[a-z]+)+$" }] */

import { UnionServices } from '@/models/IUserUses'
import PostBoostingService from '@/services/api/PostBoostingService'
import { SET_POST_BOOSTING_RULES } from '@/store/mutationsConstants/mutationPostBoosting'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Jsona from 'jsona'

interface PostBoostingState {
  postBoostingRules: {
    data: [],
    meta: {
      pagination: unknown
    },
    selectBoostRules: Array<number>
  },
}

const initialState = (): PostBoostingState => ({
  postBoostingRules: {
    data: [],
    meta: {
      pagination: {}
    },
    selectBoostRules: []
  }
})
const getters: GetterTree<PostBoostingState, unknown> = {
  postBoostingRules (state) {
    return state.postBoostingRules.data
  },
  getSelectBoostRules (state) {
    return state.postBoostingRules.selectBoostRules
  },
  getBoostRulesPagination (state) {
    return state.postBoostingRules.meta.pagination
  }
}
const mutations: MutationTree<PostBoostingState> = {
  [SET_POST_BOOSTING_RULES] (state, rules: typeof state.postBoostingRules) {
    state.postBoostingRules.data = rules.data
    state.postBoostingRules.meta = rules.meta
  },
  setSelectedBoostRules (state, data) {
    state.postBoostingRules.selectBoostRules.push(Number(data))
  },
  removeSelectedBoostRules (state, data: number) {
    const ind = state.postBoostingRules.selectBoostRules.indexOf(Number(data))
    if (ind !== -1) {
      state.postBoostingRules.selectBoostRules.splice(ind, 1)
    }
  }

}
const actions: ActionTree<PostBoostingState, unknown> = {
  loadPostBoostingRules ({ commit }, { service, data }: { service: UnionServices, data }): Promise<void> {
    return new Promise((resolve, reject) => {
      PostBoostingService.getPostBoostingRules(service, data)
        .then(response => {
          const dataFormatter = new Jsona()
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const dataDeserialized = { data: dataFormatter.deserialize(response.data), meta: response.data.meta }
          commit(SET_POST_BOOSTING_RULES, dataDeserialized)
        })
        .then(resolve)
        .catch(reject)
    })
  },
  deleteBoostingRule ({ dispatch }, { service, id }: { service: UnionServices, id}): Promise<unknown> {
    return new Promise((resolve, reject) => {
      PostBoostingService.deletePostBoostingRule(service, id).then((response) => {
        dispatch(
          'notifications/addNotification',
          { id: Date.now(), body: 'Deleted success!', type: 'success' },
          { root: true }
        )
        resolve(response)
      })
        .catch((error) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
            { root: true }
          )
          reject(error)
        })
    })
  }
  /// Не реализовано

  // deleteBoostingRules ({ dispatch }, { service, ids }: { service: UnionServices, ids: Array<number>}): Promise<unknown> {
  //   return new Promise((resolve, reject) => {
  //     PostBoostingService.deletePostBoostingRules(service, ids).then((response) => {
  //       dispatch(
  //         'notifications/addNotification',
  //         { id: Date.now(), body: 'Deleted success!', type: 'success' },
  //         { root: true }
  //       )
  //       resolve(response)
  //     })
  //       .catch((error) => {
  //         dispatch(
  //           'notifications/addNotification',
  //           { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
  //           { root: true }
  //         )
  //         reject(error)
  //       })
  //   })
  // }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
