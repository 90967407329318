import http, { CustomAxiosConfig } from '@/http-common'
import { AxiosResponse } from 'axios'
import IRegisterUser from '@/models/IRegisterUser'
import INotificationChange from '@/models/INotificationChange'
import urlGen from '@/utils/urlGen'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  register (data: IRegisterUser): Promise<AxiosResponse> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return http.post('/users', data, { _withoutToken: true })
  },

  toggle_notifications_email_news (data : INotificationChange) {
    return http.patch('/users', data)
  },

  get_profile (params = {}): Promise<AxiosResponse> {
    const url = new URL(baseUrl + 'users')
    const urlParams = urlGen.addSearch(params)
    return http.get(url.toString() + '?' + urlParams.toString())
    // return http.get('/users?include[]=current_plan&include[]=billing_info&include[]=summary&include[]=agreement&include[]=plan_boost')
  },
  update_profile (data: any): Promise<AxiosResponse> {
    return http.patch('/users', data)
  },
  get_delete_reasons_list () : Promise<AxiosResponse> {
    return http.get('/users/delete_reasons')
  },
  delete_profile (data: any) : Promise<AxiosResponse> {
    return http.delete('/users', {
      data
    })
  },
  account_recovery () : Promise<AxiosResponse> {
    return http.post('/users/recovery')
  },
  get_billing_info (): Promise<AxiosResponse> {
    return http.get('/profile?billing_info=true')
  },

  update_billing_info (data: any): Promise<AxiosResponse> {
    return http.post('/profile/update', data)
  },
  unconfirmed_change_email (email: string): Promise<AxiosResponse> {
    return http.post('/users/change-email', { email: email })
  },
  resend_confirmation_email (): Promise<AxiosResponse> {
    return http.post('/users/send_confirmation_email', { })
  },
  sendConfirmEmailRequest (payload): Promise<AxiosResponse> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return http.post('/users/confirm_email', payload, { _withoutToken: true })
  }
}
