import adsCampaign from '@/services/api/AdsManagerCampaignService'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import ISaveFilterRequestBody from '@/models/AdsManager/ISaveFilterRequestBody'
import {
  SET_SAVED_FILTERS,
  ADD_SAVED_FILTER,
  SET_DATE_FILTERS,
  RESET_FILTER_STATE,
  SET_FILTERS_META,
  ADD_FILTER,
  SET_FILTERS
} from '@/store/mutationsConstants/adsManagerMutations/mutationFilter'
import { IFilterState, TFilterType } from '@/models/AdsManager/IFilterState'
import { Filter, IFilterEntry } from '@/utils/Filter'
import store from '@/store'

const initialState = (): IFilterState => ({
  savedFilters: null,
  filtersMeta: null,
  filters: new Filter([]),
  dateFilters: new Filter([])
})

const getters: GetterTree<IFilterState, IFilterState> = {
  getSavedFilters (state: IFilterState): IFilterState['savedFilters'] {
    return state.savedFilters
  },
  getFiltersMeta (state: IFilterState): IFilterState['filtersMeta'] {
    return state.filtersMeta
  },
  getFilters (state: IFilterState): IFilterState['filters'] {
    return state.filters
  },
  getFilterPayload (state: IFilterState): string {
    const allFilters = new Filter([
      ...state.dateFilters.entries,
      ...state.filters.entries
    ])

    if (!allFilters.empty) {
      return '&' + allFilters.toString()
    }
    return ''
  }
}

const mutations: MutationTree<IFilterState> = {
  [SET_SAVED_FILTERS] (state: IFilterState, savedFilters: IFilterState['savedFilters']): void {
    state.savedFilters = savedFilters
  },
  [ADD_SAVED_FILTER] (state: IFilterState, savedFilter: IFilterState['savedFilters'][number]) {
    state.savedFilters.push(savedFilter)
  },
  [SET_FILTERS_META] (state: IFilterState, meta: IFilterState['filtersMeta']): void {
    state.filtersMeta = meta
  },
  [SET_DATE_FILTERS] (state: IFilterState, entries: IFilterState['dateFilters']['entries']): void {
    state.dateFilters.set(entries)
  },
  [RESET_FILTER_STATE] (state: IFilterState): void {
    state = initialState()
  },
  [ADD_FILTER] (state: IFilterState, filter: IFilterEntry): void {
    state.filters.add(filter)
  },
  [SET_FILTERS] (state: IFilterState, filters: IFilterEntry[]): void {
    state.filters.set(filters)
  }
}

const actions: ActionTree<IFilterState, IFilterState> = {
  loadFilters ({ dispatch, commit }, type: TFilterType) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_filters(type).then((response) => {
        if (response.data.data) {
          commit(SET_SAVED_FILTERS, response.data.data)
        }
        if (response.data.meta) {
          commit(SET_FILTERS_META, response.data.meta)
        }
        resolve(response)
      }).catch((error) => {
        console.error('Error when loading filters', error)
        dispatch('notifications/addNotification',
          { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
          { root: true })
        reject(error)
      })
    })
  },
  saveFilter ({ dispatch, commit }, data: ISaveFilterRequestBody) {
    return new Promise((resolve, reject) => {
      adsCampaign.save_filters(data).then((response) => {
        if (response.data.data) {
          commit(ADD_SAVED_FILTER, response.data.data)
        }
        resolve(response)
      }).catch((error) => {
        console.error('Error when saving filter', error)
        store.dispatch('notifications/addNotification', {
          type: 'danger',
          body: error?.response?.data?.name[0] || 'Something went wrong! Please retry later'
        })
        reject(error)
      })
    })
  },
  deleteFilter ({ dispatch, commit, state }, filterId: string) {
    return new Promise((resolve, reject) => {
      adsCampaign.delete_filter(filterId).then((response) => {
        commit(SET_SAVED_FILTERS, state.savedFilters.filter(filter => filter.id !== filterId))
        resolve(response)
      }).catch((error) => {
        console.error('Error when deleting filter', error)
        store.dispatch('notifications/addNotification', {
          type: 'danger',
          body: error?.response?.data?.name[0] || 'Something went wrong! Please retry later'
        })
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
