import { AxiosResponse } from 'axios'
import http from '@/http-common'
import urlGen from '@/utils/urlGen'
import IRule from '@/models/Rules/IRule'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  getRules (service: string, search = {}, page = 1): Promise<AxiosResponse> {
    const url = new URL(baseUrl + `automated_rules/${service}`)
    const params = urlGen.addSearch({ ...search, page: page })
    return http.get(url.toString() + '?' + params.toString())
  },
  getRule (service: string, ruleId: number, search = {}): Promise<AxiosResponse> {
    const url = new URL(baseUrl + `automated_rules/${service}/${ruleId}`)
    const params = urlGen.addSearch({ ...search })
    return http.get(url.toString() + '?' + params.toString())
  },
  getRuleLogs (service: string, ruleData: Record<string, any>) {
    const url = new URL(baseUrl + `automated_rules/${service}/${ruleData.ruleId}/logs?page=${ruleData.page}&per_page=${ruleData.showPages}`)
    return http.get(url.toString())
  },
  changeStatuses (service: string, data) {
    const url = new URL(baseUrl + `automated_rules/${service}/status`)
    return http.patch(url.toString(), data)
  },
  getTriggers (service: string): Promise<AxiosResponse> {
    return http.get(`/automated_rules/${service}/evaluation_fields`)
  },
  getStrategyTriggers (service: string): Promise<AxiosResponse> {
    return http.get(`/ad_strategies/${service}/evaluation_fields`)
  },
  getTaskActions (service: string): Promise<AxiosResponse> {
    return http.get(`/automated_rules/${service}/task_actions`)
  },
  getTimeRanges (service: string): Promise<AxiosResponse> {
    return http.get(`/automated_rules/${service}/time_ranges`)
  },
  getCustomMetrics (service: string, search = {}, page = 1): Promise<AxiosResponse> {
    const url = new URL(baseUrl + `automated_rules/${service}/custom_metrics`)
    const params = urlGen.addSearch({ ...search, page: page })
    return http.get(url.toString() + '?' + params.toString())
  },
  getStrategyCustomMetrics (service: string, search = {}, page = 1): Promise<AxiosResponse> {
    const url = new URL(baseUrl + `ad_strategies/${service}/custom_metrics`)
    const params = urlGen.addSearch({ ...search, page: page })
    return http.get(url.toString() + '?' + params.toString())
  },
  createRule (service: string, rule: IRule): Promise<AxiosResponse> {
    return http.post(`/automated_rules/${service}`, rule)
  },
  updateRule (service: string, rule: IRule): Promise<AxiosResponse> {
    return http.patch(`/automated_rules/${service}/${rule.id}`, rule)
  },
  updateRuleStatus (service: string, data) {
    return http.patch(`/automated_rules/${service}/${data.ruleId}`, { status: data.status })
  },
  getServerData (service: string, route: string) {
    const url = `/automated_rules/${service}/${route}`
    return http.get(url)
  },
  deleteRule (service: string, id) {
    const url = `/automated_rules/${service}/${id}`
    return http.delete(url)
  },
  duplicateRule (service: string, id) {
    const url = `/automated_rules/${service}/${id}/duplicate`
    return http.post(url)
  },
  deleteMany (service: string, ids) {
    const url = `/automated_rules/${service}/delete`
    return http.post(url, { ids })
  },
  // custom metrics
  createCustomMetric (service: string, data) {
    const url = `/automated_rules/${service}/custom_metrics`
    return http.post(url, data)
  },
  updateCustomMetric (service: string, id, data) {
    const url = `/automated_rules/${service}/custom_metrics/${id}`
    return http.patch(url, data)
  },
  moveRulesToFolder (service: string, data: { ids: [number], folder_id: string }) {
    return http.patch(`/automated_rules/${service}/folder`, data)
  },
  selectionFiltersEstimatedMatch (service: string, data: { ad_account_id: number|string, entity_type: string, selection_filters: []}) {
    return http.post(`/automated_rules/${service}/selection_filters/estimated_match`, data)
  }
}
