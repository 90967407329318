interface IBrowserData {
  cookieEnabled: boolean | null
  hardwareConcurrency: number | null
  language: string | null
  webdriver: boolean | null
  platform: string | null
  vendor: string | null
  timezone: string | null
}

export function getData (): IBrowserData {
  return {
    cookieEnabled: navigator.cookieEnabled,
    hardwareConcurrency: navigator.hardwareConcurrency,
    language: navigator.language,
    webdriver: navigator.webdriver,
    platform: navigator.platform,
    vendor: navigator.vendor,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }
}
