














import Vue from 'vue'
export default Vue.extend({
  name: 'DebugPanel',
  data () {
    return {
      showPanel: false
    }
  },
  props: ['title']
})
