import { SET_AUTH_MANUALLY } from '@/store/mutationsConstants/mutationAuth'
import { middlewareContext } from '@/router'

export default function redirectAuthorized ({ next, store, to, nextMiddleware }: middlewareContext): void | Promise<unknown> {
  if (store.getters['auth/is_authorized'] === null) {
    store.dispatch('auth/refresh').then(() => {
      checkAuthState({ next: next, store: store, to: to, nextMiddleware: nextMiddleware })
    }).catch(() => {
      return nextMiddleware()
    })
  } else {
    checkAuthState({ next: next, store: store, to: to, nextMiddleware: nextMiddleware })
  }
}

function checkAuthState ({ next, store, to, nextMiddleware }) {
  if (store.getters['auth/is_authorized']) {
    if (to.query?.auth_manually) {
      store.commit('auth/' + SET_AUTH_MANUALLY, true)
    }
    return next({ name: 'Profile' })
  }
  return nextMiddleware()
}
