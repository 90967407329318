import Jsona from 'jsona'
import adsCampaign from '@/services/api/AdsManagerCampaignService'
import IDeleteCampaign from '@/models/AdsManager/IDeleteCampaign'
import IUpdateStatusCampaign from '@/models/AdsManager/IUpdateStatusCampaign'
import iDeleteAdSet from '@/models/AdsManager/iDeleteAdSet'
import IUpdateStatusAdSet from '@/models/AdsManager/IUpdateStatusAdSet'
import IAdsManagerState from '@/models/AdsManager/IAdsManagerState'
import adDesign from '@/store/modules/AdsManager/AdDesignStep/adDesign'
import campaignDashboard from '@/store/modules/AdsManager/CampaignDashboard/campaignDashboard'
import Vue from 'vue'
import CampaignsService from '@/services/api/CampaignsService'
import ILocale from '@/models/AdsManager/ILocale'
import {
  SET_CAMPAIGNS,
  SET_AD_SETS,
  SET_AD_SET,
  SET_INIT_AD_SET,
  SET_FILTER,
  SET_CAMPAIGN,
  SET_INIT_CAMPAIGN_DATA,
  SET_STATE_FIELD,
  SET_STATE_PROP,
  SET_STATE_FIELD_PROP,
  CHANGE_INCLUSION_TARGETING,
  SET_AUDIENCE_INSPECTOR_PROP,
  SET_CAMPAIGN_PERFOMANCE,
  SET_CAMPAIGN_PERFOMANCE_META,
  SET_OBJECTIVE,
  SET_SPECIAL_AD_CATEGORIES,
  SET_CUSTOM_AUDIENCES,
  SET_LOCATION_TYPES,
  SET_LOCALES,
  SET_TARGETING,
  SET_LOCAL_AUDIENCES_DATA,
  SET_CAMPAIGN_OBJECTIVE,
  SET_AUDIENCES_DATA,
  SET_AUDIENCES_PROPERTY,
  SET_ALL_ADS_AD_SETS,
  SET_CAMPAIGN_STATUSES,
  CLEAR_ADS_MANAGER,
  SET_SELECTED_AD_ACCOUNT,
  TOGGLE_CHANGES,
  TOGGLE_AD_SET_CHANGES,
  SET_FILTER_DASHBOARD
} from '@/store/mutationsConstants/adsManagerMutations/mutationsAdsManager'
import { AxiosResponse } from 'axios'
import IAdAccount from '@/models/IAdAccount'

const filters = {
  status: true,
  type: true,
  createdin: true,
  id: true,
  budget: true,
  adaccount: true,
  ads: true,
  impr: true,
  image: true,
  actions: true,
  bid: true,
  start: true,
  end: true,
  clicks: true,
  spent: true,
  freq: true,
  reach: true,
  ctr: true,
  cpm: true,
  delivery: true
}

const modules = {
  // campaign dashboard (not step)
  campaignDashboard,
  // step 2 (AdDesign)
  adDesign
}

const initialState = (): IAdsManagerState => (
  {
    campaignChanges: false,
    adSetChanges: false,
    campaigns: null,
    campaign: null,
    initCampaignData: null,
    created_campaign: null,

    filter: { ...filters },
    filterDashboard: { ...filters },
    pages: [],
    adSets: [],
    initAdSetData: null,
    adSet: null,
    objective: [],
    special_ad_categories: [],
    custom_audiences: [],
    location_types: [],
    campaign_audiences: [],
    locales: [],
    targeting: null,
    local_audiences_data: null,
    allAds_adSets: [],
    campaign_statuses: null,
    deliveries_api: null,
    selected_ad_account: null
  })

const getters = {
  getCampaigns (state: IAdsManagerState) {
    return state.campaigns
  },
  getAdSets (state: IAdsManagerState) {
    return state.adSets
  },
  getAdsetTargetingData (state) {
    return function (field) {
      return state.adSets.data.map(v => v.targeting[field])
    }
  },
  getFilterDashboard (state: IAdsManagerState) {
    return state.filterDashboard
  },
  getFilter (state: IAdsManagerState) {
    return state.filter
  },
  getCampaignStatuses (state: IAdsManagerState) {
    return state.campaign_statuses
  },
  getObjective (state: IAdsManagerState) {
    return state.objective
  },

  getObjectiveName: (state: IAdsManagerState) => (
    id: string
  ): string | null => {
    return state.objective.find(o => o.id === id)?.name
  },

  getSpecialAdCategories (state: IAdsManagerState) {
    return state.special_ad_categories
  },

  getCreatedCampaign (state: IAdsManagerState) {
    return state.campaign
  },

  getCampaign (state: IAdsManagerState) {
    return state.campaign
  },

  getInitCampaignData (state) {
    return state.initCampaignData
  },

  getAdSet (state: IAdsManagerState) {
    return state.adSet
  },

  getInitAdSetData (state: IAdsManagerState) {
    return state.initAdSetData
  },

  getDeliveriesApi (state: IAdsManagerState) {
    return state.deliveries_api
  },

  getCustomAudiences (state: IAdsManagerState) {
    return state.custom_audiences
  },

  getLocationTypes (state: IAdsManagerState) {
    return state.location_types
  },

  getLocales (state: IAdsManagerState): ILocale[] {
    return state.locales
  },

  getTargeting (state: IAdsManagerState) {
    return state.targeting
  },

  getTargetingAudiencesData (state: IAdsManagerState) {
    return state.targeting?.audiences_data || []
  },
  getLocalAudiencesData (state: IAdsManagerState) {
    return state.local_audiences_data
  },
  getTargetingIncludedLocations: state => audience => {
    return audience.locations.filter(l => l.is_included)
  },
  getTargetingExcludedLocations: state => audience => {
    return audience.locations.filter(l => !l.is_included)
  },
  getCampaignObjective (state: IAdsManagerState) {
    return state.campaign.objective
  },
  getCampaignBudget (state: IAdsManagerState) {
    return state.campaign?.budget
  },
  getCampaignCurrencySymbol (state: IAdsManagerState) {
    return state.campaign?.budget?.currency?.data?.symbol
  },
  getCampaignAdSets (state: IAdsManagerState) {
    return state.campaign?.ad_sets
  },
  getAllAds_AdSets  (state: IAdsManagerState) {
    return state.allAds_adSets
  },
  getCampaignChanges (state) {
    return state.campaignChanges
  },
  getAdSetChanges (state) {
    return state.adSetChanges
  },
  getSelectedAdAccount (state: IAdsManagerState) {
    return state.selected_ad_account
  }
}

const mutations = {
  [TOGGLE_CHANGES] (state, value) {
    state.campaignChanges = value
  },
  [TOGGLE_AD_SET_CHANGES] (state, value) {
    state.adSetChanges = value
  },
  [SET_STATE_FIELD] (state, { field, value }) {
    Vue.set(state, field, value)
  },
  [SET_CAMPAIGNS] (state: IAdsManagerState, campaigns: any) {
    campaigns.data.forEach(campaigns => {
      campaigns.isSelected = false
      campaigns.show = false
    })
    state.campaigns = campaigns
  },
  [SET_STATE_PROP] (state, { prop, value }) {
    Vue.set(state, prop, value)
  },
  [SET_STATE_FIELD_PROP] (state, { field, prop, value }) {
    if (Array.isArray(field)) {
      Vue.set(state[field[0]][field[1]], prop, value)
    } else {
      Vue.set(state[field], prop, value)
    }
  },
  [CHANGE_INCLUSION_TARGETING] (state, { id, value }) {
    let inclusion_targeting_item = state.inclusion_targeting.find(v => v.id === id)
    inclusion_targeting_item = value
  },
  [SET_AUDIENCE_INSPECTOR_PROP] (state, { prop, value }) {
    Vue.set(state.campaignPerformanceAudienceInspector, prop, value)
  },
  [SET_CAMPAIGN] (state: IAdsManagerState, campaign: any) {
    campaign.isSelected = false
    state.campaign = campaign
  },
  [SET_INIT_CAMPAIGN_DATA] (state, campaign) {
    state.initCampaignData = JSON.parse(JSON.stringify(campaign))
  },
  [SET_CAMPAIGN_PERFOMANCE] (state, data) {
    state.campaignPerformance = data
  },
  [SET_CAMPAIGN_PERFOMANCE_META] (state, data) {
    state.campaignPerformanceMeta = data
  },
  [SET_AD_SETS] (state: IAdsManagerState, adSets: any) {
    state.adSets = adSets
  },
  [SET_AD_SET] (state: IAdsManagerState, adSet: any) {
    state.adSet = adSet
  },
  [SET_INIT_AD_SET] (state: IAdsManagerState, adSet: any) {
    state.initAdSetData = JSON.parse(JSON.stringify(adSet))
  },
  [SET_FILTER] (state: IAdsManagerState, key: string) {
    state.filter[key] = !state.filter[key]
  },

  [SET_FILTER_DASHBOARD] (state: IAdsManagerState, key: string) {
    state.filterDashboard[key] = !state.filterDashboard[key]
  },

  [SET_OBJECTIVE] (state: IAdsManagerState, objective) {
    state.objective = objective
  },

  [SET_SPECIAL_AD_CATEGORIES] (state: IAdsManagerState, categories) {
    state.special_ad_categories = categories
  },

  [SET_CUSTOM_AUDIENCES] (state: IAdsManagerState, audiences) {
    state.custom_audiences = audiences
  },

  [SET_LOCATION_TYPES] (state: IAdsManagerState, types) {
    state.location_types = types
  },

  [SET_LOCALES] (state: IAdsManagerState, locales) {
    state.locales = locales
  },

  [SET_TARGETING] (state: IAdsManagerState, targeting) {
    if (targeting.audiences_data) {
      let i = 1
      targeting.audiences_data = targeting.audiences_data.map(a => {
        i += 1
        if (!a.id) {
          return { ...a, id: Date.now() + i }
        } else {
          return a
        }
      })
    }
    state.targeting = targeting
  },
  [SET_LOCAL_AUDIENCES_DATA] (state: IAdsManagerState, data) {
    state.local_audiences_data = data
  },
  [SET_CAMPAIGN_OBJECTIVE] (state: IAdsManagerState, objective) {
    state.campaign.objective = objective
  },
  [SET_AUDIENCES_DATA] (state: IAdsManagerState, data) {
    if (state.targeting) {
      state.targeting.audiences_data = data
    }
  },
  [SET_AUDIENCES_PROPERTY] (state: IAdsManagerState, { audienceId, property, value }) {
    if (state.targeting && state.targeting.audiences_data) {
      const audience = state.targeting.audiences_data.find(a => a.id === audienceId)
      if (audience) {
        Vue.set(audience, property, value)
      }
    }
  },

  [SET_ALL_ADS_AD_SETS] (state: IAdsManagerState, response) {
    response.selected = false
    response.forEach((campaign: Record<string, any>) => {
      campaign.viewTarget = false
      campaign.viewAds = false
      campaign.selected = false
      campaign.ads.forEach((ads: Record<string, any>) => {
        ads.selected = false
        ads.showDetails = false
      })
    })
    state.allAds_adSets = response
    state.deliveries_api = response.meta.deliveries
  },
  [SET_CAMPAIGN_STATUSES] (state: IAdsManagerState, statuses) {
    state.campaign_statuses = statuses
  },
  [SET_SELECTED_AD_ACCOUNT] (state: IAdsManagerState, adAccount: IAdAccount | null) {
    state.selected_ad_account = adAccount
  }
}

const actions = {
  [CLEAR_ADS_MANAGER] ({ commit, state }) {
    const cleanState = initialState()
    const modulesKeys = Object.keys(modules)

    for (const field in state) {
      if (modulesKeys.includes(field)) {
        commit(field + '/clearState')
      } else {
        state[field] = cleanState[field]
      }
    }
  },
  // * campaign
  load_campaigns ({ dispatch }, requestBody: Record<string, any>) {
    const formatter = new Jsona()
    return new Promise((resolve, reject) => {
      adsCampaign.get_campaigns(requestBody).then((response) => {
        const cp = { ...response.data.meta.statuses }
        const result = {
          data: formatter.deserialize(response.data),
          meta: response.data.meta
        }
        dispatch('set_campaign_statuses', cp)
        dispatch('setCampaigns', (result))
        resolve(response)
      }).catch((error) => {
        console.error('Error when getting campaigns', error)
        reject(error)
      })
    })
  },
  /*
  async load_campaigns ({ dispatch }, requestBody: Record<string, any>) {
    const formatter = new Jsona()
    try {
      const response = await adsCampaign.get_campaigns(requestBody)
      const cp = { ...response.data.meta.statuses }
      const result = {
        data: formatter.deserialize(response.data),
        meta: response.data.meta
      }
      dispatch('set_campaign_statuses', cp)
      dispatch('setCampaigns', (result))
    } catch (e) {
      console.log(e)
    }
  },
  */
  async delete_campaign ({ dispatch }, requestBody: IDeleteCampaign) {
    return new Promise((resolve, reject) => {
      adsCampaign.delete_campaign(requestBody)
        .then(response => {
          resolve(response)
        })
        .catch(er => reject(er))
    })
  },

  async update_status_campaign ({ dispatch }, requestBody: IUpdateStatusCampaign) {
    return new Promise((resolve, reject) => {
      adsCampaign.update_status_campaign(requestBody)
        .then(response => {
          resolve(response)
        })
        .catch(er => reject(er))
    })
  },
  publish_campaign ({ dispatch }, campaignId: number) {
    return new Promise((resolve, reject) => {
      adsCampaign.publish_campaign(campaignId)
        .then(response => {
          const formatter = new Jsona()
          dispatch('setCampaign', formatter.deserialize(response.data))
          resolve(response)
        })
        .catch(er => reject(er))
    })
  },

  saving_changes_published_campaign ({ dispatch }, { campaignId, data = {} }) {
    return new Promise((resolve, reject) => {
      adsCampaign.save_changes_published_campaign(campaignId, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          error.response.data.errors.forEach(err => {
            dispatch('notifications/addNotification',
              { id: Date.now(), body: err.detail[0], type: 'danger' },
              { root: true })
          })
          console.log(error)
          reject(error)
        })
    })
  },
  saving_changes_published_ad_set ({ dispatch }, { campaignId, adSetId, data = {} }) {
    return new Promise((resolve, reject) => {
      adsCampaign.save_changes_published_ad_set(campaignId, adSetId, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          error.response.data.errors.forEach(err => {
            dispatch('notifications/addNotification',
              { id: Date.now(), body: err.detail[0], type: 'danger' },
              { root: true })
          })
          console.log(error)
          reject(error)
        })
    })
  },

  async create_budget ({ dispatch }, requestBody) {
    try {
      await adsCampaign.create_budget(requestBody)
    } catch (e) {
      console.log(e)
    }
  },

  _getCampaigns (context: Record<any, any>, campaigns: Record<any, any>) {
    context.commit(SET_CAMPAIGNS, campaigns)
  },

  async load_campaign ({ dispatch }, { campaignId, params }) {
    const response = await CampaignsService.getCampaign(campaignId, params)
    const dataFormatter = new Jsona()
    const campaignData = dataFormatter.deserialize(response.data)
    if (campaignData.budget) {
      dispatch('budget/_setCampaignBudgetDetailsMeta', response.data.meta, { root: true })
    }
    dispatch('setCampaign', campaignData)
    dispatch('setInitCampaignData', campaignData)
    return response
  },

  setCampaigns (context: Record<any, any>, campaign: Record<any, any>) {
    context.commit(SET_CAMPAIGNS, campaign)
  },

  // * adSet

  /*
  async load_allAds_adSets ({ dispatch }, payload: Record<string, any>) {
    const formatter = new Jsona()
    return new Promise((resolve, reject) => {
      adsCampaign.load_allAds_adSets(payload).then(response => {
        const result = formatter.deserialize(response.data)
        result.meta = response.data.meta
        dispatch('_set_allAds_adSets', result)
        resolve(response)
      }).catch(error => reject(error))
    })
    */
  load_allAds_adSets ({ dispatch }, payload: Record<string, any>): Promise<AxiosResponse> {
    const formatter = new Jsona()
    return new Promise((resolve, reject) => {
      adsCampaign.load_allAds_adSets(payload)
        .then((response) => {
          const result = formatter.deserialize(response.data)
          result.meta = response.data.meta
          dispatch('_set_allAds_adSets', result)
          resolve(response)
        })
        .catch((error) => {
          console.error('Error when getting campaigns', error)
          reject(error)
        })
    })
  },

  _set_allAds_adSets ({ commit }, response) {
    commit(SET_ALL_ADS_AD_SETS, response)
  },
  load_ad_set ({ dispatch }, { campaignId, adSetId }) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_campaign_ad_set(campaignId, adSetId)
        .then(response => {
          const formatter = new Jsona()
          const result = formatter.deserialize(response.data)
          result.meta = response.data.meta
          dispatch('_setAdSet', result)
          dispatch('_setInitAdSet', result)
          dispatch('set_locales', response.data.meta.locales)
          resolve(response)
        })
        .catch(er => reject(er))
    })
  },
  load_ad_sets ({ dispatch }, campaignId: number) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_ad_sets(campaignId)
        .then(response => {
          const formatter = new Jsona()
          dispatch('_setAdSets', formatter.deserialize(response.data))
          resolve(response)
        })
        .catch(er => reject(er))
    })
  },

  async delete_adSet_ad ({ dispatch }, requestBody: iDeleteAdSet) {
    return new Promise((resolve, reject) => {
      adsCampaign.delete_adSet_ad(requestBody).then((response) => {
        resolve(response)
      })['catch']((error) => {
        reject(error)
      })
    })
  },

  async update_status_adSet_ad ({ dispatch }, requestBody: IUpdateStatusAdSet) {
    return new Promise((resolve, reject) => {
      adsCampaign.update_status_adSet_ad(requestBody).then((response) => {
        resolve(response)
      })['catch']((error) => {
        reject(error)
      })
    })
  },

  async load_campaign_objective ({ dispatch }) {
    adsCampaign.get_campaign_objective().then(response => {
      dispatch('setObjective', response.data.data)
    })
  },

  async load_special_ad_categories ({ dispatch }) {
    adsCampaign.get_special_ad_categories().then(response => {
      dispatch('setSpecialAdCategories', response.data.data)
    })
  },

  async load_custom_audiences ({ dispatch }, campaignId: number) {
    adsCampaign.get_custom_audiences(campaignId).then(response => {
      dispatch('setCustomAudiences', response.data.data)
    })
  },

  store_campaign ({ dispatch }, { data, campaignId }) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .store_campaign(data, campaignId)
        .then(response => {
          const dataFormatter = new Jsona()
          const campaign = dataFormatter.deserialize(response.data)
          dispatch('setCampaign', campaign)
          resolve(response.data.data)
        })['catch'](error => reject(error))
    })
  },

  setObjective ({ commit }, objective) {
    commit(SET_OBJECTIVE, objective)
  },

  setSpecialAdCategories ({ commit }, categories) {
    commit(SET_SPECIAL_AD_CATEGORIES, categories)
  },

  _setAdSets (context: Record<any, any>, adSets: Record<any, any>) {
    context.commit(SET_AD_SETS, adSets)
  },

  _setAdSet (context: Record<any, any>, adSet: Record<any, any>) {
    context.commit(SET_AD_SET, adSet)
  },
  _setInitAdSet (context: Record<any, any>, adSet: Record<any, any>) {
    context.commit(SET_INIT_AD_SET, adSet)
  },
  // * columns table
  _setFilter (context: Record<any, any>, key: string) {
    context.commit(SET_FILTER, key)
  },
  _setFilterDashboard (context: Record<any, any>, key: string) {
    context.commit(SET_FILTER_DASHBOARD, key)
  },
  setCampaign ({ commit }, campaign) {
    commit(SET_CAMPAIGN, campaign)
  },
  setInitCampaignData ({ commit }, campaign) {
    commit(SET_INIT_CAMPAIGN_DATA, campaign)
  },
  setCampaignObjective ({ commit }, objective) {
    commit(SET_CAMPAIGN_OBJECTIVE, objective)
  },
  setCustomAudiences ({ commit }, audiences) {
    commit(SET_CUSTOM_AUDIENCES, audiences)
  },
  async load_location_types ({ dispatch }, campaignId) {
    adsCampaign.get_location_types(campaignId).then(response => {
      const dataFormatter = new Jsona()
      dispatch('setLocationTypes', dataFormatter.deserialize(response.data))
    })
  },
  setLocationTypes ({ commit }, types) {
    commit(SET_LOCATION_TYPES, types)
  },
  get_geo_locations (context, { campaignId, query }) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .get_geo_locations(campaignId, query)
        .then(response => resolve(response.data))['catch'](error => reject(error))
    })
  },
  get_interests (context, { campaignId, query }) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .get_interests(campaignId, query)
        .then(response => resolve(response.data))['catch'](error => reject(error))
    })
  },
  get_reach_estimate (context, { campaignId, payload }) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .get_reach_estimate(campaignId, payload)
        .then(response => resolve(response.data))['catch'](er => reject(er))
    })
  },
  create_audience (context, { campaignId, payload }) {
    return new Promise((resolve, reject) => {
      adsCampaign
        .create_audience(campaignId, payload)
        .then(response => resolve(response.data))['catch'](er => reject(er))
    })
  },

  load_campaign_targeting ({ dispatch }, campaignId: number) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_targeting(campaignId)
        .then(response => {
          const dataFormatter = new Jsona()
          Promise.all([
            dispatch('set_targeting', dataFormatter.deserialize(response.data)),
            dispatch('set_locales', response.data.meta.locales)
          ]).then(() => resolve(response))
            .catch(err => reject(err))
        })
    })
  },

  set_locales ({ commit }, locales) {
    commit(SET_LOCALES, locales)
  },

  set_targeting ({ commit }, targeting) {
    commit(SET_TARGETING, targeting)
  },
  set_audiences_data ({ commit }, data) {
    commit(SET_AUDIENCES_DATA, data)
  },
  set_audience_property ({ commit }, data) {
    commit(SET_AUDIENCES_PROPERTY, data)
  },
  set_local_audiences_data ({ commit }, data) {
    commit(SET_LOCAL_AUDIENCES_DATA, data)
  },
  set_campaign_statuses ({ commit }, statuses) {
    commit(SET_CAMPAIGN_STATUSES, statuses)
  },
  set_selected_ad_account ({ commit }, adAccount) {
    commit(SET_SELECTED_AD_ACCOUNT, adAccount)
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
  modules
}
