import AdsManagerCampaignService from '@/services/api/AdsManagerCampaignService'
import PostBoostingService from '@/services/api/PostBoostingService'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { UnionServices } from '@/models/IUserUses'
import {
  CONDITION_TYPE_CONDITION,
  CONDITION_TYPE_GROUP,
  METRIC_TYPE_SIMPLE,
  OPERATOR_GREATER_THAN
} from '@/constants/FbAutomatedRule'
import Vue from 'vue'
import IAdAccount from '@/models/IAdAccount'
import IExecutionSpec from '@/models/Rules/IExecutionSpec'
import IScheduleSpec from '@/models/Rules/IScheduleSpec'
import Jsona from 'jsona'

const defaultGroup = {
  type: CONDITION_TYPE_GROUP,
  level: 1,
  id: null,
  uid: null,
  operator: 'AND',
  conditions: null
}

const generateConditionWithId = (id = Date.now() + 1) => {
  return {
    id,
    uid: id,
    type: CONDITION_TYPE_CONDITION,
    metric_type: METRIC_TYPE_SIMPLE,
    metric: null,
    custom_metric_id: null,
    value: null,
    operator: OPERATOR_GREATER_THAN,
    time_range: 'LIFETIME'
  }
}

interface NewPostBoostingState {
  rule: {
    id?: number
    name?: string
    description?: string
    ad_account_id?: number
    ad_account_external_id?: string
    status?: string
    ad_account?: IAdAccount,
    entity_type?: string,
    execution_spec?: IExecutionSpec
    conditions?: {conditions}
    folder_id?: number
    last_triggered?: number
    next_state?: string | null
    schedule_spec?: IScheduleSpec
    created_at?: string | null
    updated_at?: string | null
    user_id?: number | null,
    post_types?: Array<string>
  },
  types: Array<{value: string, label: string}>
  evaluationFields: [],
  time_range: [],
  triggerBasedRules: [],
  notAvailableFirstMetrics: [],
  metricsWithSchedule: []
}

const initialState = () : NewPostBoostingState => ({
  rule: {},
  types: [],
  evaluationFields: [],
  time_range: [],
  triggerBasedRules: [],
  notAvailableFirstMetrics: [],
  metricsWithSchedule: []
})

const mutations: MutationTree<NewPostBoostingState> = {
  setPostTypes (state, data) {
    state.types = data.map((el: {value: string, title: string}): { label: string; title: string; value: string } => {
      return { ...el, label: el.title }
    })
  },
  setSelectedType (state, data: { label: string; title: string; value: string }) {
    state.rule.post_types.push(data.value)
  },
  deleteSelectedType (state, start) {
    state.rule.post_types.splice(start, 1)
  },
  setEvaluationFields (state, data) {
    state.evaluationFields = data
    data.map(group => {
      group.fields.map((metric) => {
        if (metric.trigger && !metric.monitoring) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.triggerBasedRules.push(metric.field)
        }

        if (metric.trigger_filter) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.notAvailableFirstMetrics.push(metric.field)
        }

        if (!metric.trigger) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.metricsWithSchedule.push(metric.field)
        }
      })
    })
  },
  addNewBoostRule (state) {
    const id = Date.now()
    state.rule = {
      ...state.rule,
      description: 'description',
      conditions: {
        ...defaultGroup,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id,
        uid: id,
        conditions: { [id + 1]: generateConditionWithId(id + 1) }
      }
    }
  },
  addDefaultCondition (state) {
    const id = Date.now()
    Vue.set(state.rule.conditions.conditions, id, generateConditionWithId(id))
  },
  deleteBoostCondition (state, payload) {
    const deleteGroupRecursive = (obj, groupId) => {
      for (const prop in obj) {
        if (String(prop) === String(groupId)) {
          Vue['delete'](obj, prop)
        } else if (obj[prop].type === CONDITION_TYPE_GROUP) {
          deleteGroupRecursive(obj[prop].conditions, groupId)
        }
      }
    }

    const deleteItemRecursive = (obj, itemId) => {
      for (const prop in obj) {
        if (obj[prop].type !== CONDITION_TYPE_CONDITION) {
          deleteItemRecursive(obj[prop].conditions, itemId)
        } else if (String(prop) === String(itemId)) {
          Object.keys(obj).length === 1
            ? deleteGroupRecursive(state.rule.conditions.conditions, payload.groupId)
            : Vue['delete'](obj, prop)
        }
      }
    }
    deleteItemRecursive(state.rule.conditions.conditions, payload.itemId)
  },
  setGroupProp (state, { groupId, type, value, level }) {
    function setPropsRecursive (obj, groupId) {
      for (const prop in obj) {
        if (String(prop) === String(groupId)) {
          Vue.set(obj[prop], type, value)
        } else if (obj[prop].type !== CONDITION_TYPE_CONDITION) {
          setPropsRecursive(obj[prop].conditions, groupId)
        }
      }
    }

    if (level === 1) Vue.set(state.rule.conditions, type, value)
    else setPropsRecursive(state.rule.conditions.conditions, groupId)
  },
  setBoostingCondition (state, payload) {
    function setPropsRecursive (obj, itemId) {
      for (const prop in obj) {
        if (obj[prop] && obj[prop].type !== CONDITION_TYPE_CONDITION) {
          setPropsRecursive(obj[prop].conditions, itemId)
        } else if (String(prop) === String(itemId)) {
          Vue.set(obj[prop], payload.type, payload.value)
        }
      }
    }
    setPropsRecursive(state.rule.conditions.conditions, payload.itemId)
  },
  setTimeRangeBoosting (state, data) {
    state.time_range = data
  },
  updateRule (state, data) {
    state.rule = { ...state.rule, ...data }
  },
  addDefaultGroup (state, payload) {
    const id = Date.now()
    const defaultGroup = {
      type: CONDITION_TYPE_GROUP,
      operator: 'AND',
      level: payload.level + 1,
      id,
      uid: id,
      conditions: {
        [id + 1]: generateConditionWithId(id + 1),
        [id + 2]: generateConditionWithId(id + 2)
      }
    }

    function addGroupRecursive (obj, groupId) {
      for (const prop in obj) {
        if (obj[prop].conditions && String(prop) !== String(groupId)) {
          addGroupRecursive(obj[prop].conditions, groupId)
        } else if (String(prop) === String(groupId)) {
          Vue.set(obj[prop].conditions, id, defaultGroup)
        }
      }
    }
    if (payload.level === 0) {
      Vue.set(state.rule, 'conditions', {
        ...defaultGroup,
        id,
        uid: id,
        conditions: {
          [id + 1]: generateConditionWithId(id + 1)
        }
      })
      console.log('check')
    }

    if (Number(payload.level) === 1) {
      Vue.set(state.rule.conditions.conditions, id, defaultGroup)
    } else {
      addGroupRecursive(state.rule.conditions.conditions, payload.groupId)
    }
  },
  setBoostRule (state, data) {
    state.rule = data.data
  },
  clearRule (state) {
    state.rule = {}
  }
}

const actions: ActionTree<NewPostBoostingState, unknown> = {
  async loadPostTypes ({ commit }, service: UnionServices) {
    const res:{ data } = await PostBoostingService.getPostTypes(service)
    commit('setPostTypes', res.data.data)
  },
  async loadEvaluationFields ({ commit }, service: UnionServices) {
    const res:{ data } = await PostBoostingService.getEvaluationFields(service)
    commit('setEvaluationFields', res.data.data)
  },
  async loadTimeRangeBoosting ({ commit }, service: UnionServices) {
    const res:{ data } = await PostBoostingService.getTimeRangeBoosting(service)
    commit('setTimeRangeBoosting', res.data.data)
  },
  async updatePostBoosting ({ commit, dispatch }, { service, data, id }) {
    try {
      const res:{ data } = await PostBoostingService.updatePostBoostingRule(service, data, id)
      commit('updateRule', res.data.data.attributes)
      dispatch(
        'notifications/addNotification',
        { id: Date.now(), body: 'Updated success!', type: 'success' },
        { root: true }
      )
    } catch (e) {
      dispatch(
        'notifications/addNotification',
        { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
        { root: true }
      )
    }
  },
  async createPostBoosting ({ commit, dispatch }, { service, data }) {
    try {
      const res:{ data } = await PostBoostingService.createPostBoostingRule(service, data)
      commit('updateRule', res.data.data.attributes)
      return res.data.data.id
    } catch (e) {
      dispatch(
        'notifications/addNotification',
        { id: Date.now(), body: e, type: 'danger' },
        { root: true }
      )
      throw (e)
    }
  },
  loadPostBoostingRule ({ commit }, { service, id }: { service: UnionServices, id: number }): Promise<void> {
    return new Promise(() => {
      PostBoostingService.getPostBoostingRule(service, id)
        .then((response) => {
          const dataFormatter = new Jsona()
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const dataDeserialized = { data: dataFormatter.deserialize(response.data), meta: response.data.meta }
          commit('setBoostRule', dataDeserialized)
        })
    })
  },
  async loadCampaignObjective () {
    return AdsManagerCampaignService.get_campaign_objective()
  }
}

const getters: GetterTree<NewPostBoostingState, unknown> = {
  getPostTypes (state) {
    return state.types
  },
  getSelectType (state) {
    return state.rule.post_types
  },
  getEvaluationFields (state) {
    return state.evaluationFields
  },
  getCreateBoostRule (state) {
    return state.rule
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
