import http from '@/http-common'
import { AxiosResponse } from 'axios'
import urlGen from '@/utils/urlGen'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  get_available_plans (): Promise<AxiosResponse> {
    return http.get('/billing/plans')
  },
  get_billing_invoices (params = {}): Promise<AxiosResponse> {
    const url = new URL(baseUrl + 'billing/invoices')
    const urlParams = urlGen.addSearch(params)
    return http.get(url.toString() + '?' + urlParams.toString())
  },
  get_billing_coupons (): Promise<AxiosResponse> {
    return http.get('billing/coupons')
  },
  activate_coupons (code): Promise<AxiosResponse> {
    return http.patch('billing/coupons', {
      code
    })
  },
  subscribe (ps, frequency, planId): Promise<AxiosResponse> {
    return http.post('/billing/subscribe', { frequency: frequency, plan_id: planId, ps: ps })
  },
  execute_subscription (hash: string, token: string): Promise<AxiosResponse> {
    return http.post('/billing/subscription_status', { hash: hash, token: token })
  },
  unsubscribe (reason: number, reason_text: string): Promise<AxiosResponse> {
    return http.delete('/billing/unsubscribe', { data: { reason: reason, reason_text: reason_text } })
  }
}
