import IntegrationService from '@/services/api/IntegrationService'
import { Jsona } from 'jsona'
import {
  SET_FACEBOOK,
  SET_SNAPCHAT,
  SET_TIKTOK,
  SET_LINKEDIN,
  SET_GOOGLE,
  SET_TWITTER,
  SET_SLACK,
  SET_SERVICE_PAGES,
  DISABLE_SERVICE_PAGE,
  REPLACE_SERVICE_PAGE, SET_SERVICE_ACCOUNT
} from '@/store/mutationsConstants/mutationIntegrations'
import IStateIntegrations, { IStateIntegrationDataWithPages } from '@/models/Integrations/IStateIntegrations'
import IListIntegrationAccount from '@/models/Integrations/IListIntegrationAccount'
import { ActionTree, MutationTree } from 'vuex'
import {
  FACEBOOK_SERVICE,
  SLACK_SERVICE,
  SNAPCHAT_SERVICE,
  TIKTOK_SERVICE,
  LINKEDIN_SERVICE,
  GOOGLE_SERVICE,
  TWITTER_SERVICE
} from '@/constants/FbAutomatedRule'
import { AxiosResponse } from 'axios'

/* eslint dot-notation: ["error", { "allowPattern": "^[a-z]+(_[a-z]+)+$" }] */

interface ICreateAdAccount {
    service: string,
    data: {
      accounts: {
        [key: string]: {
          on: boolean,
          name: string
        }
      },
      d_token: string
    }
}

const initialState = (): IStateIntegrations => ({
  facebook: { data: [], meta: null, pages: null },
  slack: { data: [], meta: null },
  snapchat: { data: [], meta: null },
  tiktok: { data: [], meta: null },
  linkedin: { data: [], meta: null },
  google: { data: [], meta: null },
  twitter: { data: [], meta: null }
})

const getters = {
  getSnapchat (state: IStateIntegrations): IStateIntegrations['snapchat'] {
    return state.snapchat
  },
  getFacebook (state: IStateIntegrations): IStateIntegrations['facebook'] {
    return state.facebook
  },
  getSlack (state: IStateIntegrations): IStateIntegrations['slack'] {
    return state.slack
  },
  getTiktok (state: IStateIntegrations): IStateIntegrations['tiktok'] {
    return state.tiktok
  },
  getLinkedin (state: IStateIntegrations): IStateIntegrations['linkedin'] {
    return state.linkedin
  },
  getGoogle (state: IStateIntegrations): IStateIntegrations['google'] {
    return state.google
  },
  getTwitter (state: IStateIntegrations): IStateIntegrations['twitter'] {
    return state.twitter
  }
}

const mutations = <MutationTree<IStateIntegrations>> {
  [SET_SNAPCHAT] (state: IStateIntegrations, { data, meta }): void {
    state.snapchat = {
      data: data,
      meta: meta
    }
  },
  [SET_FACEBOOK] (state: IStateIntegrations, { data, meta }): void {
    state.facebook = {
      ...state.facebook,
      data: data,
      meta: meta
    }
  },
  [SET_SLACK] (state: IStateIntegrations, { data, meta }): void {
    state.slack = {
      data: data,
      meta: meta
    }
  },
  [SET_TIKTOK] (state: IStateIntegrations, { data, meta }): void {
    state.tiktok = {
      data: data,
      meta: meta
    }
  },
  [SET_LINKEDIN] (state: IStateIntegrations, { data, meta }): void {
    state.linkedin = {
      data: data,
      meta: meta
    }
  },
  [SET_GOOGLE] (state: IStateIntegrations, { data, meta }): void {
    state.google = {
      data: data,
      meta: meta
    }
  },
  [SET_TWITTER] (state: IStateIntegrations, { data, meta }): void {
    state.twitter = {
      data: data,
      meta: meta
    }
  },
  [SET_SERVICE_PAGES] (state: IStateIntegrations, { service, pages }: { service: string, pages: IStateIntegrationDataWithPages['pages'] }) {
    state[service].pages = pages || null
  },
  [REPLACE_SERVICE_PAGE] (state: IStateIntegrations, { service, page }: {
    service: string
    page: IStateIntegrationDataWithPages['pages'][number]
  }) {
    const newArray = [...state[service].pages]
    const index = state[service].pages.indexOf(state[service].pages.find((p) => p.id === page.id))

    if (index !== -1 && state[service].pages) {
      newArray[index] = page
      state[service].pages = newArray
    }
  },
  [DISABLE_SERVICE_PAGE] (
    state: IStateIntegrations,
    { service, page_id }: {
      service: string,
      page_id: IStateIntegrationDataWithPages['pages'][number]['id']
    }
  ): void {
    const page = state[service]?.pages?.find((p) => p.id === page_id)

    if (page) {
      page.attributes.disabled = true
    }
  },
  [SET_SERVICE_ACCOUNT] (
    state: IStateIntegrations,
    { service, userId, newAccount }: { service: string, userId: string, newAccount: IListIntegrationAccount }
  ): void {
    const oldAccount = state[service]?.data.find(_ => _.id === userId)

    if (!oldAccount) {
      return
    }

    state[service].data[state[service].data.indexOf(oldAccount) as number] = { ...oldAccount, ...newAccount }
    state[service].data = [...state[service].data]
  }
}

const actions = <ActionTree<IStateIntegrations, unknown>> {
  async patchToggle ({ dispatch, commit }, data: { service: string, id: string, isEnabled: boolean}): Promise<AxiosResponse> {
    try {
      const axiosResponse = await IntegrationService.toggleAdAccount(data.service, data.id, data.isEnabled)

      if (axiosResponse?.data?.data?.attributes) {
        commit(SET_SERVICE_ACCOUNT, {
          service: data.service,
          userId: data.id,
          newAccount: axiosResponse.data.data.attributes
        })
      }

      dispatch('load_ad_accounts', { service: data.service })

      return axiosResponse
    } catch (error) {
      error?.response?.data && dispatch(
        'notifications/addExceptions',
        error.response.data,
        { root: true }
      )
      throw error
    }
  },
  load_ad_accounts ({ dispatch }, { service, data }): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      (
        service !== SLACK_SERVICE
          ? IntegrationService.get_ad_accounts(service, data)
          : IntegrationService.get_slack_workspace(service, data)
      ).then(adAccounts => {
        const formatter = new Jsona()
        const data = formatter.deserialize(adAccounts.data)
        dispatch('setAdAccounts', {
          service: service,
          data: service === SLACK_SERVICE && data ? [data] : data,
          meta: adAccounts.data.meta
        }).then(() => {
          resolve(adAccounts)
        })
      }).catch(error => reject(error))
    })
  },

  async create_ad_account (ctx, data: ICreateAdAccount) {
    await IntegrationService.create_ad_account(data.service, data.data)
  },
  async add_pages (ctx, data) {
    try {
      await IntegrationService.add_pages({
        service: data.service,
        pages: data.pages,
        token: data.token
      })
    } catch (e) {
      console.log(e)
    }
  },
  delete_ad_account (ctx, data: {id: string, service: string}) {
    return new Promise((resolve, reject) => {
      (data.service !== SLACK_SERVICE
        ? IntegrationService.delete_ad_account(data)
        : IntegrationService.delete_slack_workspace(data))
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*
  async delete_ad_account ({ dispatch }, data: {id: string, service: string}) {
    try {
      await IntegrationService.delete_ad_account(data)
    } catch (e) {
      console.log(e)
    }
  },
  */

  _setSnapchat (context, data: Array<IListIntegrationAccount>) {
    context.commit(SET_SNAPCHAT, data)
  },
  setAdAccount ({ commit }, { service, data, meta }) {
    switch (service) {
      case FACEBOOK_SERVICE:
        commit(SET_FACEBOOK, { data: data, meta: meta })
        break
      case SLACK_SERVICE:
        commit(SET_SLACK, { data: data, meta: meta })
        break
      case SNAPCHAT_SERVICE:
        commit(SET_SNAPCHAT, { data: data, meta: meta })
        break
      case TIKTOK_SERVICE:
        commit(SET_TIKTOK, { data: data, meta: meta })
        break
      case LINKEDIN_SERVICE:
        commit(SET_LINKEDIN, { data: data, meta: meta })
        break
      case GOOGLE_SERVICE:
        commit(SET_GOOGLE, { data: data, meta: meta })
        break
      case TWITTER_SERVICE:
        commit(SET_TWITTER, { data: data, meta: meta })
        break
    }
  },
  setAdAccounts ({ commit }, { service, data, meta }) {
    switch (service) {
      case FACEBOOK_SERVICE:
        commit(SET_FACEBOOK, { data: data, meta: meta })
        break
      case SLACK_SERVICE:
        commit(SET_SLACK, { data: data, meta: meta })
        break
      case SNAPCHAT_SERVICE:
        commit(SET_SNAPCHAT, { data: data, meta: meta })
        break
      case TIKTOK_SERVICE:
        commit(SET_TIKTOK, { data: data, meta: meta })
        break
      case LINKEDIN_SERVICE:
        commit(SET_LINKEDIN, { data: data, meta: meta })
        break
      case GOOGLE_SERVICE:
        commit(SET_GOOGLE, { data: data, meta: meta })
        break
      case TWITTER_SERVICE:
        commit(SET_TWITTER, { data: data, meta: meta })
        break
    }
  },
  getServicePages (ctx, service: string) {
    ctx.commit(SET_SERVICE_PAGES, { service, pages: null })
    return IntegrationService.getServicePages(service)
      .then((res) => {
        ctx.commit(SET_SERVICE_PAGES, { service, pages: res.data.data })
        return res
      })
      .catch((res) => {
        ctx.dispatch(
          'notifications/addNotification',
          { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
          { root: true }
        )
        throw res
      })
  },
  getServicePagesExternal (ctx, { service, token }) {
    ctx.commit(SET_SERVICE_PAGES, { service, pages: null })
    return IntegrationService.getServicePagesExternal(service, token)
      .then((res) => {
        ctx.commit(SET_SERVICE_PAGES, { service, pages: res.data.data })
        return res
      })
      .catch((res) => {
        ctx.dispatch(
          'notifications/addNotification',
          { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
          { root: true }
        )
        throw res
      })
  },

  toggleServicePage (ctx, { service, page_id, is_enabled }) {
    ctx.commit(DISABLE_SERVICE_PAGE, { service, pages: null })
    return IntegrationService.enablingPages(service, page_id, is_enabled)
      .then((res) => {
        ctx.commit(REPLACE_SERVICE_PAGE, { service, page: res.data.data })
        ctx.dispatch(
          'notifications/addNotification',
          {
            id: Date.now(),
            body: `Page "${res.data.data.attributes.name || 'Unknown'}" was ${is_enabled ? 'enabled' : 'disabled'}.`,
            type: 'success'
          },
          { root: true }
        )

        return res
      })
      .catch((res) => {
        ctx.dispatch(
          'notifications/addNotification',
          {
            id: Date.now(),
            body: `Failed to ${is_enabled ? 'enable' : 'disable'} the page!`,
            type: 'danger'
          },
          { root: true }
        )
        throw res
      })
  },
  updateAdAccountTimezone (
    context,
    { service, userId, timezoneId }: { service: string, userId: string, timezoneId: string }
  ) {
    return new Promise((resolve, reject) => {
      IntegrationService.updateAdAccountTimezone(service, userId, timezoneId)
        .then((response) => {
          if (response?.data?.data?.attributes) {
            context.commit(SET_SERVICE_ACCOUNT, {
              service,
              userId,
              newAccount: response.data.data.attributes
            })
          }
          return response
        })
        .then(resolve)
        .catch((error) => {
          context.dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Something went wrong!', type: 'danger' },
            { root: true }
          )
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
