import { AxiosResponse } from 'axios'
import http from '@/http-common'
import urlGen from '@/utils/urlGen'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  getAdAccounts (search = {}, page = 1): Promise<AxiosResponse> {
    const url = new URL(baseUrl + 'marketing/ad_accounts')
    const params = urlGen.addSearch({ ...search, page: page })
    return http.get(url.toString() + '?' + params.toString())
  }
}
