import { ActionTree, MutationTree } from 'vuex'
import Vue from 'vue'
import Jsona from 'jsona'
import IRulesMeta from '@/models/IRulesMeta'
import ITrigger from '@/models/RulesMeta/ITrigger'
import RulesService from '@/services/api/RulesService'
import ITaskAction from '@/models/RulesMeta/ITaskAction'
import ITimeRange from '@/models/RulesMeta/ITimeRange'
import ICustomMetric from '@/models/ICustomMetric'
import _ from 'lodash'
import {
  CONDITION_TYPE_CONDITION,
  ENTITY_TYPE_AD,
  ENTITY_TYPE_ADSET,
  ENTITY_TYPE_CAMPAIGN,
  ENTITY_TYPE_CAMPAIGN_GROUP,
  ENTITY_TYPE_EXTENSION,
  ENTITY_TYPE_KEYWORD,
  ENTITY_TYPE_SEARCH_TERM,
  LINKEDIN_SERVICE,
  METRIC_TYPE_CUSTOM
} from '@/constants/FbAutomatedRule'

const initialState = () : IRulesMeta => ({
  triggers: null,
  customMetrics: null,
  taskActions: null,
  timeRanges: null,
  triggerBasedRules: [],
  notAvailableFirstMetrics: [],
  metricsWithSchedule: [],
  serverData: []
})

const mutations = <MutationTree<IRulesMeta>> {
  setTriggers (state: IRulesMeta, data: Record<string, ITrigger[]>) {
    state.triggers = data.data
    data.data.map(group => {
      group.fields.map((metric) => {
        if (metric.trigger && !metric.monitoring) {
          state.triggerBasedRules.push(metric.field)
        }

        if (metric.trigger_filter) {
          state.notAvailableFirstMetrics.push(metric.field)
        }

        if (!metric.trigger) {
          state.metricsWithSchedule.push(metric.field)
        }
      })
    })
  },

  setTaskActions (state: IRulesMeta, data: {data: ITaskAction[]}) {
    state.taskActions = data.data
  },
  setTimeRanges (state: IRulesMeta, data: {data: ITimeRange[]}) {
    state.timeRanges = data.data
  },
  setCustomMetrics (state: IRulesMeta, data: {data: ICustomMetric[] }) {
    state.customMetrics = data.data
  },
  addCustomMetrics (state: IRulesMeta, data: {data: ICustomMetric[] }) {
    if (state.customMetrics) {
      state.customMetrics = state.customMetrics.concat(data.data)
    } else state.customMetrics = data.data
  },
  setStateProp (state, { prop, value }) {
    Vue.set(state, prop, value)
  },
  setServerData (state, { field, data }) {
    switch (field) {
      case 'triggers':
        Vue.set(state.serverData, field, data)
        data.map(group => {
          group.fields.map((metric) => {
            if (metric.trigger && !metric.monitoring) {
              state.triggerBasedRules.push(metric.field)
            }
            if (metric.trigger_filter) {
              state.notAvailableFirstMetrics.push(metric.field)
            }
            if (!metric.trigger) {
              state.metricsWithSchedule.push(metric.field)
            }
          })
        })
        break

      case 'customMetrics':
        Vue.set(state.serverData, field, data.data)
        break

      default:
        Vue.set(state.serverData, field, data)
        break
    }
  }
}
const actions = <ActionTree<IRulesMeta, any>>{
  async loadTriggers ({ dispatch, rootGetters }, { requestType = 'rules' }) {
    try {
      let response
      if (requestType === 'rules') {
        response = await RulesService.getTriggers(rootGetters['adService/getService'])
      }
      if (requestType === 'strategies') {
        response = await RulesService.getStrategyTriggers(rootGetters['adService/getService'])
      }
      // response = await RulesService.getTriggers()
      dispatch('setTriggers', { data: response.data.data })
    } catch (e) {
      console.log(e)
    }
  },
  async loadTaskActions ({ dispatch, rootGetters }) {
    try {
      const response = await RulesService.getTaskActions(rootGetters['adService/getService'])
      dispatch('setTaskActions', { data: response.data.data })
    } catch (e) {
      console.log(e)
    }
  },
  async loadTimeRanges ({ dispatch, rootGetters }) {
    try {
      const response = await RulesService.getTimeRanges(rootGetters['adService/getService'])
      dispatch('setTimeRanges', { data: response.data.data })
    } catch (e) {
      console.log(e)
    }
  },
  async loadCustomMetrics ({ dispatch, rootGetters }, { from = 'rules', page = 1 }) {
    try {
      let response
      if (from === 'rules') {
        response = await RulesService.getCustomMetrics(rootGetters['adService/getService'], {}, page)
      }
      if (from === 'strategies') {
        response = await RulesService.getStrategyCustomMetrics(rootGetters['adService/getService'], {}, page)
      }

      const dataFormatter = new Jsona()
      const callback = async () => {
        if (response.data.meta.pagination.current_page < response.data.meta.pagination.total_pages) {
          dispatch('loadCustomMetrics', { page: page + 1 })
        }
      }
      if (page === 1) {
        dispatch('setCustomMetrics', { data: dataFormatter.deserialize(response.data) }).then(callback)
      } else dispatch('addCustomMetrics', { data: dataFormatter.deserialize(response.data) }).then(callback)
    } catch (e) {
      console.log(e)
    }
  },
  async loadAllData ({ dispatch }, { requestType = 'rules' }) {
    dispatch('loadTriggers', { requestType: requestType })
    dispatch('loadTaskActions')
    dispatch('loadTimeRanges')
  },
  getServerData ({ commit }, { service, route, field }) {
    return RulesService.getServerData(service, route).then(res => {
      commit('setServerData', { field, data: res.data.data })
      return res.data.data
    })
  },
  setTriggers (context, data: ITrigger[]) {
    context.commit('setTriggers', data)
  },
  setTaskActions (context, data: ITaskAction[]) {
    context.commit('setTaskActions', data)
  },
  setTimeRanges (context, data: ITimeRange[]) {
    context.commit('setTimeRanges', data)
  },
  setCustomMetrics (context, data: ICustomMetric[]) {
    context.commit('setCustomMetrics', data)
  },
  addCustomMetrics (context, data: ICustomMetric[]) {
    context.commit('addCustomMetrics', data)
  }
}
const getters = {
  loaded (state: IRulesMeta): boolean {
    return Object.values(state).every(item => item)
  },
  getTriggers (state: IRulesMeta): ITrigger[] {
    return state.triggers
  },
  getTaskActions (state: IRulesMeta): ITaskAction[] {
    return state.taskActions
  },
  getTaskByFrontKey: state => key => {
    let task = null
    _.each(state.taskActions, group => {
      task = group.actions.find(f => f.key === key)
      if (task) return false
    })
    return task
  },
  getNameCustomMetric: state => customMetricId => {
    // only for users custom metrics (not custom metrics from strategy)
    if (state.triggers !== null) {
      return state.triggers
        .filter(v => v.group === 'Custom metrics')[0].fields
        .find(val => Number(val.id) === Number(customMetricId))?.name
    }
  },
  selectedTrigger: (state: IRulesMeta) => (key: string): ITrigger => {
    let res = null

    state.triggers &&
    state.triggers.map(group => {
      group.fields.map(item => {
        if (item.field === key) res = item
      })
      if (res) return null
    })

    return res
  },
  selectedCustomMetric: (state: IRulesMeta) => (id: number): ICustomMetric => {
    return (
      state.customMetrics &&
      state.customMetrics.filter(item => String(item.id) === String(id))[0]
    )
  },
  selectedMetrics: (state: IRulesMeta, getters, rootState, rootGetters) => (ruleId: number): ITrigger[] => {
    const res = []
    const rule = rootGetters['rules/getRuleById'](ruleId)
    function getMetricRecursive (obj) {
      for (const prop in obj) {
        if (obj[prop] && obj[prop].type !== CONDITION_TYPE_CONDITION) {
          getMetricRecursive(obj[prop].conditions)
        } else if (obj[prop].type === CONDITION_TYPE_CONDITION) {
          if (obj[prop].metric_type === METRIC_TYPE_CUSTOM) {
            res.push(obj[prop].custom_metric_id)
          } else {
            res.push(obj[prop].metric)
          }
        }
      }
    }

    getMetricRecursive(rule.conditions.conditions)
    return res
  },
  metricAvailableSingle: (state: IRulesMeta) => (ruleId: number, trigger: ITrigger): boolean => {
    return !!trigger.available
  },
  metricAvailable: (state: IRulesMeta) => (
    {
      trigger,
      metric_entity_type,
      service
    }): boolean => {
    switch (service) {
      case LINKEDIN_SERVICE:
        switch (metric_entity_type) {
          case ENTITY_TYPE_AD:
            return trigger.available.includes('ad')
          case ENTITY_TYPE_ADSET:
          case ENTITY_TYPE_CAMPAIGN:
            return trigger.available.includes('ad_set')
          case ENTITY_TYPE_CAMPAIGN_GROUP:
            return trigger.available.includes('campaign')
          default:
            return false
        }
      default:
        switch (metric_entity_type) {
          case ENTITY_TYPE_AD:
            return trigger.available.includes('ad')
          case ENTITY_TYPE_ADSET:
            return trigger.available.includes('ad_set')
          case ENTITY_TYPE_CAMPAIGN:
            return trigger.available.includes('campaign')
          case ENTITY_TYPE_EXTENSION:
            return trigger.available.includes('extension')
          case ENTITY_TYPE_SEARCH_TERM:
            return trigger.available.includes('search_term')
          case ENTITY_TYPE_KEYWORD:
            return trigger.available.includes('keyword')
          default:
            return false
        }
    }
  },
  getTimeRange: (state: IRulesMeta) => (key: string) => {
    let timeRange = null
    state.timeRanges.some((group) => {
      group.fields.some((field) => {
        if (field.field === key) {
          timeRange = field
          return true
        }
        return false
      })
      return !!timeRange
    })
    return timeRange
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
