export default {
  addSearch (search: Record<string, any>): URLSearchParams {
    const params = new URLSearchParams()
    for (const [key, value] of Object.entries(search)) {
      if (Array.isArray(value)) value.forEach(item => params.append(key + '[]', item))
      else if (value !== null) params.append(key, value.toString())
    }
    return params
  }
}
