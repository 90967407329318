












import { mapGetters } from 'vuex'
import Vue from 'vue'

export default Vue.extend({
  name: 'Logo',
  computed: {
    ...mapGetters(['get_sidebar_open_state', 'get_width']),
    landingUrl () {
      return process.env.VUE_APP_LANDING_URL
    }
  }
})
