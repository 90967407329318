import Vue from 'vue'
import Jsona from 'jsona'
import adsCampaign from '@/services/api/AdsManagerCampaignService'
import IAdDesign from '@/models/AdsManager/AdDesignStep/IAdDesign'
import {
  SET_STATE_FIELD,
  SET_STATE_FIELD_PROP,
  ADD_ERROR,
  REMOVE_ERROR,
  REMOVE_ERROR_ANY,
  CHANGE_CHILD_ATTACHMENT,
  RESET_CHILD_ATTACHMENTS,
  ACTIVATE_CHILD_ATTACHMENT,
  DELETE_CHILD_ATTACHMENT,
  OVERRIDING_ID,
  AD_IMAGE_IN_ATTACHMENT,
  TOGGLE_PLACEMENT,
  GENERATE_VARIATIONS,
  RESET_AD_DESIGN_DATA,
  CHANGE_HEADLINE_STATE,
  CHANGE_DESCRIPTION_STATE,
  CHANGE_PRIMARY_TEXT_STATE,
  CHANGE_CALL_ACTION_STATE,
  CHANGE_TARGET_LINK_STATE,
  CHANGE_DISPLAY_LINK_STATE,
  REMOVE_IMAGE_FROM_AD_DESIGN_DATA,
  REMOVE_ERROR_BY_DETAIL,
  SET_EDITABLE_CREATIVE,
  RESET_EDITABLE_CREATIVE,
  CLEAR_STATE
} from '@/store/mutationsConstants/adsManagerMutations/mutationAdDesign'

const initialState = (): IAdDesign => (
  {
    media: [],
    loading: false,
    brand_awareness_optional_fields_toggle: false,
    placements_saving_type: 'custom',
    lead_method: 'INSTANT_FORM',
    adDesignServerData: {
      data: {
        id: 27,
        created_at: '',
        updated_at: '',
        fb_actor_id: '',
        campaign_id: 150,
        status: 1,
        creative_format: 'carousel',
        multi_share_optimized: 1,
        multi_share_end_card: 0,
        ad_name_template: '',
        instagram_id: null,
        ad_text: [],
        url: [],
        media: [],
        child_attachments: []
      },
      country_codes: [],
      templates: [],
      pages: [],
      instagram_accounts: [],
      placements: [],
      call_to_actions: [],
      instant_forms: []
    },
    adDesignData: {
      media: 'images',
      mediaForUploading: [],
      link_target: 'link',
      link_url: [],
      link_view: [],
      headline: [],
      description: [],
      ad_text: [],
      page_id: '',
      instagram_id: '',
      ad_placement: {},
      new_ad_placement: {
        devices: [],
        platforms: [],
        positions: []
      },
      call_to_action: [],
      ad_name_template: '',
      images: [],
      videos: [],
      creative_format: 'normal',
      status: 1,
      multi_share_optimized: false,
      multi_share_end_card: false,
      lead_gen_form_ids: [],
      phones: [],
      saved_placements: [],
      // carousel
      child_attachments: [
        {
          id: 0,
          image: null,
          state: 'not-included',
          active: false,
          url: { url: '' },
          headline: { headline: '' },
          description: { description: '' },
          ad_text: { ad_text: '' },
          call_to_actions: {}
        },
        {
          id: 1,
          image: null,
          state: 'not-included',
          active: false,
          url: { url: '' },
          headline: { headline: '' },
          description: { description: '' },
          ad_text: { ad_text: '' },
          call_to_actions: {}
        },
        {
          id: 2,
          image: null,
          state: 'not-included',
          active: false,
          url: { url: '' },
          headline: { headline: '' },
          description: { description: '' },
          ad_text: { ad_text: '' },
          call_to_actions: {}
        },
        {
          id: 3,
          image: null,
          state: 'not-included',
          active: false,
          url: { url: '' },
          headline: { headline: '' },
          description: { description: '' },
          ad_text: { ad_text: '' },
          call_to_actions: {}
        },
        {
          id: 4,
          image: null,
          state: 'not-included',
          active: false,
          url: { url: '' },
          headline: { headline: '' },
          description: { description: '' },
          ad_text: { ad_text: '' },
          call_to_actions: {}
        }
      ]
    },
    variations: [],
    errors: [],
    headline: null,
    description: null,
    primaryText: null,
    callAction: null,
    targetLink: null,
    displayLink: null,
    ad_preview: {
      list: null,
      preview: null,
      loadingList: true,
      loadingPreview: true
    },
    editable_creative: {
      creative_format: 'unknown'
    }
  }
)

const getters = {
  getList (state: IAdDesign): Array<Array<any>> {
    return state.ad_preview.list
  },
  getPreview (state: IAdDesign): Array<any> {
    return state.ad_preview.preview
  },
  getLoadingList (state: IAdDesign): boolean {
    return state.ad_preview.loadingList
  },
  getLoadingPreview (state: IAdDesign): boolean {
    return state.ad_preview.loadingPreview
  },
  getPixelList (state: IAdDesign): Array<any> {
    return state.pixel_list
  },
  getConversionEvents (state: IAdDesign): Array<any> {
    return state.conversion_events
  },
  getError (state) {
    return function (errorField) {
      return state.errors
        .find(v => v.field === errorField)?.detail
    }
  },
  getInstantFormByFbId (state) {
    return function (fbId) {
      return state.adDesignServerData.instant_forms.data
        ? state.adDesignServerData.instant_forms?.data.find(v => v.attributes.external_id === fbId)?.id
        : null
    }
  },
  getABTestingInfo (state: IAdDesign) {
    const initial = [
      { name: 'Primary text', store_key: 'ad_text' },
      { name: 'Headline', store_key: 'headline' },
      { name: 'Image', store_key: 'images' },
      { name: 'Description', store_key: 'description' },
      { name: 'URL', store_key: 'link_url' },
      { name: 'Call to action', store_key: 'call_to_action' }]
    const info = { data: [], total: 1 }
    // если появятся новые форматы рекламы - переделать под switch
    if (state.adDesignData.creative_format === 'normal') {
      info.data = []
      initial.forEach(field => {
        info.data.push({ name: field.name, quantity: state.adDesignData[field.store_key].length, store_key: field.store_key })
        info.total *= state.adDesignData[field.store_key].length > 0 ? state.adDesignData[field.store_key].length : 1
      })
      if (info.data.every(elem => elem.quantity === 0)) { info.total = 0 }
    } else {
      info.data = []
      info.data.push({ name: 'Description', quantity: state.adDesignData.ad_text.length, store_key: 'ad_text' })
      info.total = state.adDesignData.ad_text.length
    }
    return info
  },
  savingDataNormalCreative (state: IAdDesign, getters, rootState) {
    interface requestBody {
      ad_name_template: string
      ad_placements?: Record<string, string> | []
      creative_format: string
      page_id: string
      instagram_id: string
      description: Array<{description: string}>
      media: string
      link_target: string
      images: Array<string>
      ad_text: Array<{ad_text: string}>
      link_url?: Array<{url: string, link_view: string | null}>
      headline: Array<{headline: string}>
      call_to_actions: Array<{type: string}>
      pixel_id?: string | undefined
      conversion_event?: string | undefined
    }
    const objective = rootState.adsManager.campaign.objective
    const data: requestBody = {
      ad_name_template: state.adDesignData.ad_name_template,
      ad_text: state.adDesignData.ad_text,
      description: state.adDesignData.description,
      creative_format: state.adDesignData.creative_format,
      page_id: state.adDesignData.page_id,
      instagram_id: state.adDesignData.instagram_id,
      media: state.adDesignData.media,
      link_target: state.adDesignData.link_target,
      images: state.adDesignData.images,
      headline: state.adDesignData.headline,
      call_to_actions: state.adDesignData.call_to_action.map(v => { return { type: v.type } })
    }
    if (state.placements_saving_type === 'custom') {
      data.ad_placements = state.adDesignData.new_ad_placement
    }
    if (objective === 'CONVERSIONS') {
      data.pixel_id = state.adDesignData.pixel_id
      data.conversion_event = state.adDesignData.conversion_event
    }
    if (state.headline) {
      const checkEntryDublicateValue = data.headline.find((headline) => headline.headline === state.headline)
      if (!checkEntryDublicateValue) {
        data.headline.push({
          headline: state.headline
        })
      }
    }
    if (state.primaryText) {
      const checkEntryDublicateValue = data.ad_text.find((text) => text.ad_text === state.primaryText)
      if (!checkEntryDublicateValue) {
        data.ad_text.push({
          ad_text: state.primaryText
        })
      }
    }
    if (state.description) {
      const checkEntryDublicateValue = data.description.find((text) => text.description === state.description)
      if (!checkEntryDublicateValue) {
        data.description.push({
          description: state.description
        })
      }
    }
    const checkNoButton = getters.getCallToActionSettings.find((action) => action.key === 'NO_BUTTON')
    if (data.call_to_actions.length === 0 && checkNoButton && state.brand_awareness_optional_fields_toggle) {
      data.call_to_actions.push({
        type: 'NO_BUTTON'
      })
    }
    if (objective === 'LEAD_GENERATION') {
      if (state.lead_method === 'INSTANT_FORM') {
        data['lead_gen_form_ids'] = state.adDesignData.lead_gen_form_ids
      }
      if (state.lead_method === 'CALLS') {
        data['phones'] = state.adDesignData.phones
        data['call_to_actions'] = [{ type: 'CALL_ME' }]
      }
      data['link_url'] =
        state.adDesignData.link_url
          .map(v => {
            return {
              url: null,
              link_view: v.link_view ? v.link_view : v
            }
          })
      if (state.displayLink) {
        const checkEntryDublicateValue = data.link_url.find((link) => link.link_view === state.displayLink)
        if (!checkEntryDublicateValue) {
          data.link_url.push({
            url: null,
            link_view: state.displayLink
          })
        }
      }
    }
    if (objective !== 'LEAD_GENERATION') {
      data['link_url'] = state.adDesignData.link_url.map(v => { return { url: v.url ? v.url : '', link_view: v.link_view ? v.link_view : null } })
      if (state.displayLink && state.targetLink) {
        const checkEntryDublicateValue = data.link_url.find((link) => link.url === state.targetLink)
        if (!checkEntryDublicateValue) {
          data.link_url.push({
            url: state.targetLink,
            link_view: state.displayLink
          })
        }
      }
    }
    return data
  },
  savingDataCarouselCreative (state: IAdDesign, getters, rootState) {
    interface requestBody {
      ad_name_template: string
      ad_placements?: Record<string, string> | []
      creative_format: string
      page_id: string
      instagram_id: string
      ad_text: Array<{ad_text: string}>
      child_attachments: Array<{
        image: { hash: string },
        url: string,
        headline: string,
        call_to_actions: {type :string},
        ad_text: string
      }>
      multi_share_optimized: boolean
      multi_share_end_card: boolean
      pixel_id?: string | undefined
      conversion_event?: string | undefined
    }

    const objective = rootState.adsManager.campaign.objective
    const data: requestBody = {
      ad_name_template: state.adDesignData.ad_name_template,
      // ad_placements: state.placements_saving_type === 'custom' ? state.adDesignData.new_ad_placement : [],
      ad_text: state.adDesignData.ad_text,
      child_attachments: state.adDesignData.child_attachments.filter(c => c.state === 'included').map(v => {
        return {
          image: { hash: v.image?.hash || '' },
          url: v.url,
          headline: v.headline,
          call_to_actions: { type: v.call_to_actions.key },
          ad_text: v.ad_text
        }
      }),
      creative_format: state.adDesignData.creative_format,
      page_id: state.adDesignData.page_id,
      multi_share_optimized: !!state.adDesignData.multi_share_optimized,
      multi_share_end_card: !!state.adDesignData.multi_share_end_card,
      instagram_id: state.adDesignData.instagram_id
    }
    if (state.placements_saving_type === 'custom') {
      data.ad_placements = state.adDesignData.new_ad_placement
    }
    if (objective === 'CONVERSIONS') {
      data.pixel_id = state.adDesignData.pixel_id
      data.conversion_event = state.adDesignData.conversion_event
    }
    if (state.primaryText) {
      const checkEntryDublicateValue = data.ad_text.find((text) => text.ad_text === state.primaryText)
      if (!checkEntryDublicateValue) {
        data.ad_text.push({
          ad_text: state.primaryText
        })
      }
    }
    if (objective === 'LEAD_GENERATION') {
      if (state.lead_method === 'INSTANT_FORM') {
        data['lead_gen_form_ids'] = state.adDesignData.lead_gen_form_ids
      }
      if (state.lead_method === 'CALLS') {
        data['phones'] = state.adDesignData.phones
      }
    }
    return data
  },
  getAdDesignData (state: IAdDesign) {
    return state.adDesignData
  },
  getPlacementType (state: IAdDesign) {
    return state.placements_saving_type
  },
  getCallToActions (state: IAdDesign) {
    return state.adDesignServerData?.call_to_actions['INSTANT_FORM']
      ? state.adDesignServerData?.call_to_actions['INSTANT_FORM'].concat(state.adDesignServerData?.call_to_actions['CALL'])
      : state.adDesignServerData?.call_to_actions
  },
  getCallToActionSettings (state: IAdDesign) {
    if (state.adDesignServerData.call_to_actions['WEBSITE_WEBSITE']) {
      return state.adDesignServerData.call_to_actions['WEBSITE_WEBSITE']
    }
    if (state.adDesignServerData.call_to_actions['INSTANT_FORM']) {
      if (state.lead_method === 'INSTANT_FORM') {
        return state.adDesignServerData.call_to_actions['INSTANT_FORM']
      }
      if (state.lead_method === 'CALLS') {
        return state.adDesignServerData.call_to_actions['CALL']
      }
    } else {
      return state.adDesignServerData.call_to_actions
    }
    return true
  },
  getCreativeFormat (state: IAdDesign) {
    return state.adDesignData.creative_format
  },
  getFbPageById (state) {
    return function (fbId) {
      return state.adDesignServerData.pages
        .find(v => String(v.attributes.fb_id) === String(fbId)).id
    }
  }
}

const mutations = {
  [SET_STATE_FIELD] (state: IAdDesign, { field, value }) {
    Vue.set(state, field, value)
  },
  [SET_STATE_FIELD_PROP] (state: IAdDesign, { field, prop, value }) {
    if (Array.isArray(field)) {
      Vue.set(state[field[0]][field[1]], prop, value)
    } else {
      Vue.set(state[field], prop, value)
    }
  },
  [ADD_ERROR] (state, payload) {
    if (!state.errors.find(v => v.field === payload.field)) {
      state.errors.push(payload)
    }
  },
  [REMOVE_ERROR] (state, payload) {
    const errIndex = state.errors.indexOf(state.errors.find(v => v.field === payload.errorField))
    if (errIndex !== -1) {
      state.errors.splice(errIndex, 1)
    }
  },
  [REMOVE_ERROR_ANY] (state, payload) {
    let errIndex = state.errors.indexOf(state.errors.find(v => v.field.includes(payload.errorField)))
    while (errIndex !== -1) {
      state.errors.splice(errIndex, 1)
      errIndex = state.errors.indexOf(state.errors.find(v => v.field.includes(payload.errorField)))
    }
  },
  [REMOVE_ERROR_BY_DETAIL] (state, payload) {
    const errIndex = state.errors.indexOf(state.errors.find(v => v.detail === payload.detail))
    if (errIndex !== -1) {
      state.errors.splice(errIndex, 1)
    }
  },
  [CHANGE_CHILD_ATTACHMENT] (state: IAdDesign, { id, prop, value }) {
    Vue.set(state.adDesignData.child_attachments[id], prop, value)
  },
  [RESET_CHILD_ATTACHMENTS] (state: IAdDesign) {
    state.adDesignData.child_attachments.forEach(item => {
      item.active = false
    })
  },
  [ACTIVATE_CHILD_ATTACHMENT] (state: IAdDesign, payload) {
    const defaultCTA = payload.objective === 'LEAD_GENERATION' ? {
      key: 'SIGN_UP',
      value: 'Sign Up'
    } : {
      key: 'NO_BUTTON',
      value: 'No button'
    }
    state.adDesignData.child_attachments.find(v => v.id === payload.id).active = true
    const currentChildAttachment = state.adDesignData.child_attachments.find(v => v.id === payload.id)
    if (currentChildAttachment.state !== 'included') {
      currentChildAttachment.state = 'included'
      currentChildAttachment.call_to_actions = defaultCTA
    }
  },
  [DELETE_CHILD_ATTACHMENT] (state: IAdDesign, id) {
    const index = state.adDesignData.child_attachments.indexOf(state.adDesignData.child_attachments.find(v => v.id === id))
    state.adDesignData.child_attachments[index].state = 'not-included'
    state.adDesignData.child_attachments[index].image = ''
    state.adDesignData.child_attachments[index].active = false
    state.adDesignData.child_attachments[index - 1].active = true
  },
  [OVERRIDING_ID] (state: IAdDesign) {
    const upd = []
    state.adDesignData.child_attachments.sort(function (a, b) {
      if (a.state === 'included' && b.state === 'not-included') return -1
      if (a.state === 'not-included' && b.state === 'included') return 1
      return 0
    })
    state.adDesignData.child_attachments.forEach((item, index) => {
      item.id = index
    })
  },
  [AD_IMAGE_IN_ATTACHMENT]: (state: IAdDesign, payload) => {
    state.adDesignData.child_attachments.find(v => v.id === payload.id).image = payload.image
  },

  [TOGGLE_PLACEMENT] (state: IAdDesign, payload) {
    state.adDesignData.ad_placement[payload.ind] = payload.checkboxState
  },
  [GENERATE_VARIATIONS] (state, objective) {
    state.variations = []
    const flatten = arr => {
      return [].concat(...[], ...arr)
    }
    const cartesianProduct = (sets) => {
      return sets.reduce((acc, set) => flatten(acc.map(x => set.map(y => [...x, y]))), [[]])
    }
    let preVariations
    if (state.adDesignData.creative_format === 'normal') {
      if (objective === 'REACH' && !state.brand_awareness_optional_fields_toggle) {
        preVariations = cartesianProduct([
          state.adDesignData.images.map(v => { return { image_hash: v } }),
          state.adDesignData.ad_text.map(v => { return { ad_text: v.ad_text } })].filter(v => v.length > 0)
        )
      } else {
        preVariations = cartesianProduct([
          state.adDesignData.images.map(v => { return { image_hash: v } }),
          state.adDesignData.ad_text.map(v => { return { ad_text: v.ad_text } }),
          state.adDesignData.description.map(v => { return { description: v.description } }),
          state.adDesignData.link_url.map(v => { return { link: v.url ? v.url : v } }),
          state.adDesignData.headline.map(v => { return { headline: v.headline } }),
          state.adDesignData.call_to_action.map(v => { return { call_to_action: { type: v.type } } })].filter(v => v.length > 0)
        )
      }
      const variations = []
      preVariations.forEach(variation => {
        const t = {}
        variation.forEach(datas => {
          t[Object.keys(datas)[0]] = datas[Object.keys(datas)[0]]
        })
        variations.push(t)
      })
      state.variations = variations
    }
    let preVariationsCarousel
    if (state.adDesignData.creative_format === 'carousel') {
      const arr = []
      state.adDesignData.child_attachments.filter(c => c.state === 'included').forEach(child => {
        const it = {
          headline: '',
          ad_text: '',
          image_hash: '',
          url: '',
          call_to_action: {
            type: ''
          }
        }
        it.headline = child.headline.headline
        it.ad_text = child.ad_text.ad_text
        it.image_hash = child.image.hash
        it.url = child.url.url
        it.call_to_action.type = child.call_to_actions.key
        arr.push(it)
      })
      preVariationsCarousel = cartesianProduct([
        state.adDesignData.ad_text,
        [arr]]
      )
      const variations = []
      preVariationsCarousel.forEach(item => {
        variations.push({ ad_text: item[0].ad_text, child_attachments: item[1] })
      })
      state.variations = variations
    }
  },
  [RESET_AD_DESIGN_DATA] (state: IAdDesign) {
    state.adDesignData = initialState().adDesignData
  },
  [CHANGE_HEADLINE_STATE] (state: IAdDesign, headline) {
    state.headline = headline
  },
  [CHANGE_PRIMARY_TEXT_STATE] (state: IAdDesign, text) {
    state.primaryText = text
  },
  [CHANGE_DESCRIPTION_STATE] (state: IAdDesign, description) {
    state.description = description
  },
  [CHANGE_CALL_ACTION_STATE] (state: IAdDesign, selected) {
    state.callAction = selected
  },
  [CHANGE_TARGET_LINK_STATE] (state: IAdDesign, targetLink) {
    state.targetLink = targetLink
  },
  [CHANGE_DISPLAY_LINK_STATE] (state: IAdDesign, displayLink) {
    state.displayLink = displayLink
  },
  [REMOVE_IMAGE_FROM_AD_DESIGN_DATA] (state: IAdDesign, mediaId: string) {
    state.adDesignData.images = state.adDesignData.images.filter(i => i !== mediaId)
  },
  [SET_EDITABLE_CREATIVE] (state: IAdDesign, responseCreative: any) {
    const creative = state.editable_creative
    creative.creative_format = responseCreative.carousel.length ? 'carousel' : 'normal'
    if (creative.creative_format === 'carousel') {
      creative.ad_text = responseCreative.ad_text
      creative.multi_share_optimized = !!responseCreative.multi_share_optimized
      creative.carousel = responseCreative.carousel
    } else if (creative.creative_format === 'normal') {
      creative.ad_text = responseCreative.ad_text
      creative.description = responseCreative.description
      creative.headline = responseCreative.headline
      creative.call_to_action = {
        type: responseCreative.call_to_action?.type ||
          responseCreative.meta?.call_to_actions[0].type,
        value: responseCreative.meta?.call_to_actions
          .find((cta) => cta.key === responseCreative.call_to_action?.type)?.value ||
          responseCreative.meta?.call_to_actions[0].value
      }
      creative.media = responseCreative.media
      creative.url = responseCreative.url
    }
  },
  [RESET_EDITABLE_CREATIVE] (state: IAdDesign, responseCreative: any) {
    state.editable_creative = {
      creative_format: 'unknown'
    }
  },
  [CLEAR_STATE] (state) {
    const clearState = initialState()

    for (const field in state) {
      state[field] = clearState[field]
    }
  }
}

const actions = {
  setPlacements ({ state, commit }, payload) {
    return new Promise((resolve) => {
      const adPlacements = []
      Object.values(payload.placements[payload.creativeFormat]).forEach((item, index) => {
        adPlacements.push(Object.keys(item).map((v, index) => {
          return [v, 'on']
        }))
      })
      const obj = {}
      adPlacements.flat().forEach(item => {
        obj[item[0]] = item[1]
      })
      commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'ad_placement', value: obj })
      resolve('success')
    })
  },
  setNewPlacements ({ state, commit }, payload) {
    Object.values(payload).forEach(v => {
      if (!state.adDesignData.new_ad_placement.devices.find(d => d === v['device'])) {
        state.adDesignData.new_ad_placement.devices.push(v['device'])
      }
      if (!state.adDesignData.new_ad_placement.platforms.find(d => d === v['platform'])) {
        state.adDesignData.new_ad_placement.platforms.push(v['platform'])
      }
      if (!state.adDesignData.new_ad_placement.positions.find(d => d === v['position'])) {
        state.adDesignData.new_ad_placement.positions.push(v['position'])
      }
    })
  },
  setAttachments ({ commit, state }, childAttachments) {
    const attachments = []
    childAttachments.forEach((item, index) => {
      const call_to_actions_array: Array<{ key: string }> = Array.isArray(state.adDesignServerData.call_to_actions) ? state.adDesignServerData.call_to_actions
        : Object.values(state.adDesignServerData.call_to_actions).find(
          (array: Array<{ key: string }>) => array && array.find((v: { key: string }) => v.key === item.call_to_action.type)
        )
      const call_to_actions: { key: string } = call_to_actions_array.find((v: { key: string }) => v.key === item.call_to_action.type)
      const childAttachment = {
        id: attachments.length,
        url: { url: item.url.url },
        active: index === 0,
        headline: { headline: item.headline.headline },
        image: {
          hash: item.media.image.md5_hash,
          user_id: item.media.image.user_id,
          format: item.media.image.format,
          link: item.media.image.link
        },
        call_to_actions,
        ad_text: {
          ad_text: item.ad_text.ad_text
        }
      }
      attachments.push(childAttachment)
    })
    if (attachments.length <= 5 && attachments.length > 0) {
      for (let i = 0; i < 5; i += 1) {
        if (i < attachments.length) {
          attachments[i].state = 'included'
        } else {
          attachments.push({
            id: attachments.length,
            image: null,
            state: 'not-included',
            active: false,
            url: { url: '' },
            headline: { headline: '' },
            ad_text: { ad_text: '' },
            call_to_actions: {}
          })
        }
      }
    } else {
      for (let i = 0; i < 5; i += 1) {
        if (i < 2) {
          attachments.push({
            id: attachments.length,
            image: null,
            state: 'included',
            active: false,
            url: { url: '' },
            headline: { headline: '' },
            ad_text: { ad_text: '' },
            call_to_actions: state.adDesignServerData.call_to_actions['INSTANT_FORM']
              ? state.adDesignServerData.call_to_actions['INSTANT_FORM'][0]
              : state.adDesignServerData.call_to_actions[0]
          })
        } else {
          attachments.push({
            id: attachments.length,
            image: null,
            state: 'not-included',
            active: false,
            url: { url: '' },
            headline: { headline: '' },
            ad_text: { ad_text: '' },
            call_to_actions: {}
          })
        }
      }
    }
    attachments[0].active = true
    commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'child_attachments', value: attachments })
  },
  get_ad_set_info ({ dispatch, commit, state, getters }, campaignId) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_ad_set_design(campaignId)
        .then((response) => {
          commit(SET_STATE_FIELD_PROP, { field: 'adDesignServerData', prop: 'placements', value: response.data.meta.placements })
          commit(SET_STATE_FIELD_PROP, { field: 'adDesignServerData', prop: 'templates', value: response.data.meta.templates })
          commit(SET_STATE_FIELD_PROP, { field: 'adDesignServerData', prop: 'call_to_actions', value: response.data.meta.call_to_actions })
          commit(SET_STATE_FIELD_PROP, { field: 'adDesignServerData', prop: 'country_codes', value: response.data.meta.country_codes })
          commit(SET_STATE_FIELD_PROP, { field: 'adDesignServerData', prop: 'data', value: response.data.data.attributes })
          const creativeFormat = (response.data.data.attributes.creative_format === 'normal' || !response.data.data.attributes.creative_format) ? 'image' : response.data.data.attributes.creative_format
          dispatch('get_media').then((res) => {
            dispatch('setNewPlacements', response.data.data.attributes.placements)
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'saved_placements', value: Object.keys(response.data.data.attributes.placements) })
            dispatch('setPlacements', { creativeFormat: creativeFormat, placements: response.data.meta.placements }).then(() => {
              Object.keys(state.adDesignData.ad_placement).forEach((saved_placement, index) => {
                if (state.adDesignData.saved_placements.length > 0) {
                  if (state.adDesignData.saved_placements.find(v => v === saved_placement)) {
                    state.adDesignData.ad_placement[saved_placement] = 'on'
                  } else {
                    state.adDesignData.ad_placement[saved_placement] = 'off'
                  }
                }
              })
            })
            commit(SET_STATE_FIELD_PROP, {
              field: 'adDesignData',
              prop: 'ad_text',
              value: response.data.data.attributes.ad_text.map(v => { return { ad_text: v.ad_text } })
            })
            const calls = []

            response.data.data.attributes.call_to_action.forEach((item) => {
              let t

              if (Array.isArray(response.data.meta.call_to_actions)) {
                t = response.data.meta.call_to_actions
                  .find(v => v.key === item.type)
              } else {
                const arrays: Array<Array<any>> = Object.values(response.data.meta.call_to_actions)
                const arrayWithObjectType: Array<any> = arrays.find(array => array.find(v => v.key === item.type))
                t = arrayWithObjectType !== undefined ? arrayWithObjectType.find(v => v.key === item.type) : undefined
              }
              item.key = t.key
              item.value = t.value
              calls.push(item)
            })

            // todo Удалить если метод выше подойдет
            //
            // Не универсальный метод
            // Вызывал ошибки т.к response.data.meta.call_to_actions мог быть и массивом и объектом
            // тем самым в случае объекта вызывался метод Array.find(),
            // который не может быть применен к объектам
            //
            // response.data.data.attributes.call_to_action.forEach((item, index) => {
            //   if (item.value?.lead_gen_form_id) {
            //     state.adDesignData.lead_gen_form_ids = [item?.internal_id]
            //     state.lead_method = 'INSTANT_FORM'
            //   }
            //   if (item.value?.link) {
            //     state.adDesignData.phones = { tel: item.value.link }
            //     state.lead_method = 'CALLS'
            //   }
            //   const t = response.data.meta.call_to_actions['INSTANT_FORM']
            //     ? response.data.meta.call_to_actions['INSTANT_FORM']
            //       .find(v => v.key === item.type) ||
            //         response.data.meta.call_to_actions['CALL']
            //           .find(v => v.key === item.type)
            //     : response.data.meta.call_to_actions.find(v => v.key === item.type)
            //   console.log('t')
            //   console.log(t)
            //   item.key = t.key
            //   item.value = t.value
            //   calls.push(item)
            // })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'call_to_action', value: calls })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'headline', value: response.data.data.attributes.headline.map(v => { return { headline: v.headline } }) })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'ad_name_template', value: response.data.data.attributes.ad_name_template ? response.data.data.attributes.ad_name_template : 'Ad' })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'creative_format', value: response.data.data.attributes.creative_format ? response.data.data.attributes.creative_format : 'normal' })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'page_id', value: response.data.data.attributes.fb_actor_id })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'link_url', value: response.data.data.attributes.url })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'description', value: response.data.data.attributes.description })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'conversion_event', value: response.data.data.attributes.conversion_event })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'pixel_id', value: response.data.data.attributes.pixel_id })

            commit(SET_STATE_FIELD, { field: 'placements_saving_type', value: Array.isArray(response.data.data.attributes.placements) ? 'automatic' : 'custom' })
            dispatch('setAttachments', response.data.data.attributes.child_attachments)
            const images = []
            response.data.data.attributes.media.forEach(item => {
              images.push(res.data.data.find(v => String(v.id) === String(item.media_id)))
            })
            commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'images', value: images.map(v => v.attributes.md5_hash) })
            if (state.adDesignData.creative_format === 'normal' && state.adDesignData.headline.length > 0) {
              commit(SET_STATE_FIELD, { field: 'brand_awareness_optional_fields_toggle', value: true })
            } else {
              commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'multi_share_optimized', value: response.data.data.attributes.multi_share_optimized })
            }
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // Conversion: Pixel List & Conversion Events
  get_conversion_pixel ({ dispatch, commit }, campaignId) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_conversion_pixel(campaignId)
        .then((response) => {
          commit(SET_STATE_FIELD, { field: 'pixel_list', value: response.data.data })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  get_conversion_events ({ dispatch, commit }, campaignId) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_conversion_events(campaignId)
        .then((response) => {
          commit(SET_STATE_FIELD, { field: 'conversion_events', value: response.data.data })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  get_media ({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_media()
        .then((response) => {
          commit(SET_STATE_FIELD, { field: 'media', value: response.data.data })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  save_media ({ state, dispatch, commit }) {
    return new Promise((resolve, reject) => {
      const files = new FormData()
      const mediaType = state.adDesignData.media
      files.append('type', mediaType)
      state.adDesignData.mediaForUploading.forEach(v => {
        files.append('files[]', v.file)
      })
      adsCampaign.save_media(files)
        .then((response) => {
          dispatch('get_media')
            .then((res) => {
              if (state.adDesignData.creative_format === 'normal') {
                const images = []
                response.data.data.forEach(item => {
                  images.push(item.attributes.md5_hash)
                })
                state.adDesignData.images.forEach(item => {
                  images.push(item)
                })
                commit(SET_STATE_FIELD_PROP, { field: 'adDesignData', prop: 'images', value: images })
              }
              if (state.adDesignData.creative_format === 'carousel' && state.adDesignData.child_attachments.find(v => v.active)) {
                const carouselImages = response.data.data
                const idChildAttachment = state.adDesignData.child_attachments.find(v => v.active).id
                commit(AD_IMAGE_IN_ATTACHMENT, {
                  id: idChildAttachment,
                  image: {
                    hash: carouselImages[0].attributes.md5_hash,
                    link: carouselImages[0].attributes.link,
                    format: carouselImages[0].attributes.format,
                    user_id: carouselImages[0].attributes.user_id
                  }
                })
              }
              resolve(response)
            })
        })
        .catch((error) => {
          error.response.data.errors.forEach(e => {
            dispatch('notifications/addNotification',
              { id: Date.now(), body: e.detail[0], type: 'danger' },
              { root: true })
          })
          reject(error)
        })
    })
  },
  delete_media ({ state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      adsCampaign.delete_media(data)
        .then((response) => {
          dispatch('get_media')
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create_instant_form ({ state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_link_for_creating(data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  get_instant_forms ({ dispatch, commit, state, getters }, payload) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_instant_forms(payload)
        .then((response) => {
          commit(SET_STATE_FIELD_PROP, { field: 'adDesignServerData', prop: 'instant_forms', value: response.data })
          // if (state.adDesignData.lead_gen_form_ids[0]) {
          //   state.adDesignData.lead_gen_form_ids = [getters.getInstantFormByFbId(state.adDesignData.lead_gen_form_ids[0])]
          // }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  get_pages ({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_pages(payload.campaignId)
        .then((response) => {
          commit(SET_STATE_FIELD_PROP, { field: 'adDesignServerData', prop: 'pages', value: response.data.data })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  get_instagram_accounts ({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      adsCampaign.get_instagram_accounts(payload.campaignId, payload.pageId)
        .then((response) => {
          commit(SET_STATE_FIELD_PROP, { field: 'adDesignServerData', prop: 'instagram_accounts', value: response.data.data })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  get_live_ad_previews ({ state, dispatch, commit }, payload) {
    commit(SET_STATE_FIELD_PROP, { field: 'ad_preview', prop: 'loadingPreview', value: true })
    commit(GENERATE_VARIATIONS, payload.objective)
    return new Promise((resolve, reject) => {
      const data = payload.data
      Object.assign(data, state.variations[payload.variation])
      adsCampaign.get_live_ad_preview(payload.campaignId, data)
        .then((response) => {
          commit(SET_STATE_FIELD_PROP, { field: 'ad_preview', prop: 'loadingPreview', value: false })
          resolve(response)
        })
        .catch((error) => {
          dispatch('notifications/addNotification',
            { id: Date.now(), body: error.response.data.errors[0].detail[0], type: 'danger' },
            { root: true })
          commit(SET_STATE_FIELD_PROP, { field: 'ad_preview', prop: 'loadingPreview', value: false })
          reject(error)
        })
    })
  },
  get_ad_format ({ state, dispatch, commit, rootState }, payload) {
    commit(SET_STATE_FIELD_PROP, { field: 'ad_preview', prop: 'loadingList', value: true })
    commit(SET_STATE_FIELD_PROP, { field: 'ad_preview', prop: 'loadingPreview', value: true })
    const data = payload.data
    return new Promise((resolve, reject) => {
      adsCampaign.get_ad_format(payload.campaignId, data)
        .then((response) => {
          commit(SET_STATE_FIELD_PROP, { field: 'ad_preview', prop: 'list', value: response.data.data })
          commit(SET_STATE_FIELD_PROP, { field: 'ad_preview', prop: 'loadingList', value: false })
          const ad_format = response.data.data[Object.keys(response.data.data)[0]][0].value
          let data
          if (state.adDesignData.creative_format === 'normal') {
            data = {
              ad_format: ad_format,
              page_id: state.adDesignData.page_id,
              instagram_id: state.adDesignData.instagram_id,
              creative_format: state.adDesignData.creative_format
            }
          } else {
            data = {
              ad_format: ad_format,
              page_id: state.adDesignData.page_id,
              instagram_id: state.adDesignData.instagram_id,
              multi_share_end_card: state.adDesignData.multi_share_end_card,
              multi_share_optimized: state.adDesignData.multi_share_optimized,
              creative_format: state.adDesignData.creative_format
            }
          }
          // dispatch('get_live_ad_previews', { campaignId: payload.campaignId, data: data, variation: 0, objective: rootState.adsManager.campaign.objective })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  save_ad_design ({ dispatch, state, getters }, campaignId) {
    state.errors = []
    return new Promise((resolve, reject) => {
      const data = state.adDesignData.creative_format === 'normal' ? getters.savingDataNormalCreative : getters.savingDataCarouselCreative
      adsCampaign.save_ad_design(data, campaignId)
        .then((response) => {
          dispatch('notifications/addNotification',
            { id: Date.now(), body: 'Ad design was created', type: 'success' },
            { root: true })
          resolve(response)
        })
        .catch((error) => {
          error.response.data.errors.forEach(er => {
            state.errors.push({ field: er.source.parameter, detail: er.detail[0] })
          })
          error.response.data.errors.forEach(e => {
            dispatch('notifications/addNotification',
              { id: Date.now(), body: e.detail[0], type: 'danger' },
              { root: true })
          })
          reject(error)
        })
    })
  },
  reset_ad_design_data ({ commit }) {
    commit(RESET_AD_DESIGN_DATA)
  },
  deleteImageItemFromAdDesignData ({ commit }, mediaId: string) {
    commit(REMOVE_IMAGE_FROM_AD_DESIGN_DATA, mediaId)
  },
  // Campaign Edit
  get_ads_creative ({ commit, dispatch }, data: { campaign_id: string, ad_id: string, includes: string[] | undefined }): Promise<Response> {
    const dataFormatter = new Jsona()
    return new Promise((resolve, reject) => {
      adsCampaign.get_ads_creative(data)
        .then((response: any) => {
          commit(SET_EDITABLE_CREATIVE, { ...dataFormatter.deserialize(response.data), meta: response.data.meta })
          resolve(response)
        })
        .catch((error) => {
          dispatch('notifications/addNotification',
            { id: Date.now(), body: 'Oops! Something went wrong! Please try later', type: 'danger' },
            { root: true })
          reject(error)
        })
    })
  },
  update_ads_creative ({ dispatch }, data: { campaign_id: string, ad_id: string, body: any }): Promise<Response> {
    return new Promise((resolve, reject) => {
      adsCampaign.update_ads_creative(data)
        .then((response) => {
          dispatch('notifications/addNotification',
            { id: Date.now(), body: 'Ad was changed', type: 'success' },
            { root: true })
          resolve(response)
        })
        .catch((error) => {
          if (error?.response?.data?.errors) {
            for (const err of error.response.data.errors) {
              for (const det of err.detail) {
                dispatch('notifications/addNotification',
                  {
                    id: Date.now(),
                    body: det,
                    type: 'danger'
                  },
                  { root: true })
              }
            }
          } else {
            dispatch('notifications/addNotification',
              {
                id: Date.now(),
                body: 'Oops! Something went wrong! Please try later',
                type: 'danger'
              },
              { root: true })
          }
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
