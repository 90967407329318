










































import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'NewUiAlert',
  props: {
    type: {
      type: String as PropType<'primary' | 'success' | 'warning' | 'danger'>,
      default: 'primary'
    },
    title: String,
    body: String,
    closeButton: {
      type: String as PropType<'text' | 'icon'>,
      default: 'icon'
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
})
