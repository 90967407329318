export const ENTITY_TYPE_CAMPAIGN = 'CAMPAIGN'
export const ENTITY_TYPE_ADSET = 'ADSET'
export const ENTITY_TYPE_AD = 'AD'
export const ENTITY_TYPE_CAMPAIGN_GROUP = 'CAMPAIGN_GROUP'
export const ENTITY_TYPE_EXTENSION = 'EXTENSION'
export const ENTITY_TYPE_SEARCH_TERM = 'SEARCH_TERM'
export const ENTITY_TYPE_KEYWORD = 'KEYWORD'
export enum EntityTypesList {
  ENTITY_TYPE_CAMPAIGN = 'CAMPAIGN',
  ENTITY_TYPE_ADSET = 'ADSET',
  ENTITY_TYPE_AD = 'AD',
  ENTITY_TYPE_CAMPAIGN_GROUP = 'CAMPAIGN_GROUP',
  ENTITY_TYPE_EXTENSION = 'EXTENSION',
  ENTITY_TYPE_SEARCH_TERM = 'SEARCH_TERM',
  ENTITY_TYPE_KEYWORD = 'KEYWORD'
}

export enum SELECTION_METHODS {
  SPECIFIC_ITEMS = 'ITEMS',
  SELECTIONS_FILTER = 'FILTERS'
}

export const ENTITY_TYPES = {
  CAMPAIGN: 'Campaign',
  ADSET: 'Ad set',
  AD: 'Ad'
}
export const METRIC_TYPE_SIMPLE = 'SIMPLE'
export const METRIC_TYPE_CUSTOM = 'CUSTOM'
export const METRIC_TYPE_COMPLEX = 'COMPLEX'
export const CONDITION_TYPE_CONDITION = 'condition'
export const CONDITION_TYPE_GROUP = 'group'
export const OPERATOR_GREATER_THAN = 'GREATER_THAN'
export const OPERATOR_GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL'
export const OPERATOR_LESS_THAN = 'LESS_THAN'
export const OPERATOR_LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL'
export const OPERATOR_IN_RANGE = 'IN_RANGE'
export const OPERATOR_NOT_IN_RANGE = 'NOT_IN_RANGE'
export const OPERATOR_EQUAL = 'EQUAL'
export const OPERATOR_CONTAIN = 'CONTAIN'
export const OPERATOR_NOT_CONTAIN = 'NOT_CONTAIN'
export const OPERATOR_ANY = 'ANY'
export const OPERATOR_ALL = 'ALL'
export const OPERATOR_NONE = 'NONE'
export const OPERATOR_IN = 'IN'
export const OPERATOR_NOT_IN = 'NOT_IN'
export const OPERATOR_DOES_NOT_EQUAL = 'DOES_NOT_EQUAL'
export const OPERATOR_IS_WITHIN = 'IS_WITHIN'
export const OPERATOR_IS_NOT_WITHIN = 'IS_NOT_WITHIN'
export const COMPARE_WITH_OPERATORS = {
  IS_WITHIN: 'is within',
  IS_NOT_WITHIN: 'is not within'
}
export const OPERATORS = {
  GREATER_THAN: 'is greater than',
  GREATER_THAN_OR_EQUAL: 'is greater than or equal',
  LESS_THAN: 'is less than',
  LESS_THAN_OR_EQUAL: 'is less than or equal',
  IN_RANGE: 'is between',
  NOT_IN_RANGE: 'is not between',
  EQUAL: 'equals',
  CONTAIN: 'contains',
  NOT_CONTAIN: 'does not contain',
  ANY: 'contains any',
  ALL: 'contains all',
  NONE: 'does not contain',
  IN: 'is',
  NOT_IN: 'is not',
  IS_WITHIN: 'is within',
  IS_NOT_WITHIN: 'is not within',
  DOES_NOT_EQUAL: 'does not equal'
}
export const OPERATORS_TITLE = {
  numeric: {
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL: '>=',
    LESS_THAN: '<',
    LESS_THAN_OR_EQUAL: '<=',
    IN_RANGE: 'is between',
    NOT_IN_RANGE: 'is not between',
    EQUAL: '=',
    DOES_NOT_EQUAL: '!='
  },
  money: {
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL: '>=',
    LESS_THAN: '<',
    LESS_THAN_OR_EQUAL: '<=',
    IN_RANGE: 'is between',
    NOT_IN_RANGE: 'is not between',
    EQUAL: '=',
    DOES_NOT_EQUAL: '!='
  },
  datetime: {
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL: '>=',
    LESS_THAN: '<',
    LESS_THAN_OR_EQUAL: '<=',
    IN_RANGE: 'is between',
    NOT_IN_RANGE: 'is not between',
    DOES_NOT_EQUAL: '!='
  },
  string: {
    CONTAIN: 'contains',
    NOT_CONTAIN: 'does not contain'
  },
  percent: {
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL: '>=',
    LESS_THAN: '<',
    LESS_THAN_OR_EQUAL: '<=',
    IN_RANGE: 'is between',
    NOT_IN_RANGE: 'is not between',
    DOES_NOT_EQUAL: '!='
  },
  dropdown: {
    ANY: 'contains any',
    ALL: 'contains all',
    NONE: 'does not contain'
  }
}
export const METRIC_OPERATORS = {
  time_less_than: OPERATOR_LESS_THAN,
  time_greater_than: OPERATOR_GREATER_THAN
}
export const COMPARE_WITH_VALUE = 'VALUE'
export const COMPARE_WITH_RANKING = 'RANKING'
export const COMPARE_WITH_TOP = 'TOP'
export const COMPARE_WITH_BOTTOM = 'BOTTOM'
export const COMPARE_WITH_TOP_PERCENT = 'TOP_PERCENT'
export const COMPARE_WITH_BOTTOM_PERCENT = 'BOTTOM_PERCENT'
export const COMPARE_WITH_VALUES = {
  TOP: 'Top',
  BOTTOM: 'Bottom',
  TOP_PERCENT: '% Top',
  BOTTOM_PERCENT: '% Bottom'
}
export const FACEBOOK_SERVICE = 'facebook'
export const SLACK_SERVICE = 'slack'
export const SNAPCHAT_SERVICE = 'snapchat'
export const GOOGLE_SERVICE = 'google'
export const TIKTOK_SERVICE = 'tiktok'
export const LINKEDIN_SERVICE = 'linkedin'
export const TWITTER_SERVICE = 'twitter'

export const FACEBOOK_SERVICE_TITLE = 'Facebook'
export const SNAPCHAT_SERVICE_TITLE = 'Snapchat'
export const GOOGLE_SERVICE_TITLE = 'Google'
export const TIKTOK_SERVICE_TITLE = 'TikTok'
export const LINKEDIN_SERVICE_TITLE = 'Linkedin'
export const TWITTER_SERVICE_TITLE = 'Twitter'
export const SLACK_SERVICE_TITLE = 'Slack'

export const FACEBOOK_SERVICE_INCLUDE = 'fb_ad_strategy_rules'
export const SNAPCHAT_SERVICE_INCLUDE = 'snap_ad_strategy_rules'
export const GOOGLE_SERVICE_INCLUDE = 'google_ad_strategy_rules'
export const TIKTOK_SERVICE_INCLUDE = 'tiktok_ad_strategy_rules'
export const LINKEDIN_SERVICE_INCLUDE = 'linkedin_ad_strategy_rules'
export const TWITTER_SERVICE_INCLUDE = 'twitter_ad_strategy_rules'

export const STRATEGY_TYPE_ESSENTIAL = 'ESSENTIAL'
export const STRATEGY_TYPE_SCALE = 'SCALE'
export const STRATEGY_TYPE_PAUSE = 'PAUSE'
export const STRATEGY_TYPE_TIME = 'TIME'
export const STRATEGY_TYPE_OPTIMISE = 'OPTIMISE'

export const STRATEGY_TYPE_TITLES = {
  [STRATEGY_TYPE_TIME]: 'Time',
  [STRATEGY_TYPE_OPTIMISE]: 'Optimise',
  [STRATEGY_TYPE_ESSENTIAL]: 'Essential',
  [STRATEGY_TYPE_PAUSE]: 'Pause',
  [STRATEGY_TYPE_SCALE]: 'Scale'
}

export const SCHEDULE_TYPE_CUSTOM = 'custom'
export const SCHEDULE_TYPE_CONTINUOUSLY = 'continuously'

export const SCHEDULE_CONTINUOUSLY_INTERVALS = [
  { value: 900, label: 'every 15 minutes' },
  { value: 1800, label: 'every 30 minutes' },
  { value: 3600, label: 'every 1 hour' },
  { value: 7200, label: 'every 2 hours' },
  { value: 10800, label: 'every 3 hours' },
  { value: 14400, label: 'every 4 hours' },
  { value: 21600, label: 'every 6 hours' },
  { value: 43200, label: 'every 12 hours' },
  { value: 86400, label: 'once a day' },
  { value: 172800, label: 'once in 2 days' },
  { value: 259200, label: 'once in 3 days' }
]
