import { AxiosResponse } from 'axios'
import http from '@/http-common'
import urlGen from '@/utils/urlGen'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  getRuleCampaigns (service: string, search = {}): Promise<AxiosResponse> {
    const url = new URL(baseUrl + `automated_rules/${service}/campaigns`)
    const params = urlGen.addSearch(search)
    return http.get(url.toString() + '?' + params.toString())
  },
  getCampaigns (search = {}, filter = null): Promise<AxiosResponse> {
    const url = new URL(baseUrl + 'marketing/campaigns')
    const params = urlGen.addSearch(search)
    let link = url.toString() + '?' + params.toString()
    if (filter) {
      link += `&${filter.toString()}`
    }
    return http.get(link)
  },
  getCampaign (campaignId: number, params = {}): Promise<AxiosResponse> {
    const url = new URL(baseUrl + 'marketing/facebook/campaigns/' + campaignId)
    const query = urlGen.addSearch(params)
    return http.get(url.toString() + '?' + query.toString())
  }
}
