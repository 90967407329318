import router from '@/router'

export default function maintenanceMode ({ from, store, to, nextMiddleware }): void | Promise<unknown> {
  if (to.name === 'MaintenanceModePage') return nextMiddleware()
  store.cache.dispatch('appConfig/getMaintenanceModeFromServer')
    .then(mode => {
      if (mode) {
        return router.push({ name: 'MaintenanceModePage' })
      } else {
        return nextMiddleware()
      }
    })
    .catch(e => {
      console.log(e)
      return nextMiddleware()
    })
}
