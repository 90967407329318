import postBoosting from '@/store/modules/postBoosting'
import newPostBoosting from '@/store/modules/newPostBoosting'
import Vue from 'vue'
import Vuex from 'vuex'
import appConfig from './modules/appConfig'
import sidebarControl from './modules/sidebarControl'
import auth from './modules/auth'
import profile from './modules/profile'
import plans from './modules/plans'
import invoices from './modules/invoices'
import coupons from './modules/coupons'
import contactUs from './modules/contactUs'
import interests from './modules/interests'
import rulesFolders from '@/store/modules/rulesFolders'
import rules from '@/store/modules/rules'
import adAccounts from '@/store/modules/adAccounts'
import rulesMeta from '@/store/modules/rulesMeta'
import campaigns from '@/store/modules/campaigns'
import integrations from '@/store/modules/integrations'
import strategy from '@/store/modules/strategy'
import notifications from '@/store/modules/notifications'
import exceptions from '@/store/modules/exceptions'
import adsManager from '@/store/modules/AdsManager/adsManager'
import budget from '@/store/modules/AdsManager/budget'
import allAds from '@/store/modules/AdsManager/allAds'
import adService from '@/store/modules/adService'
import filter from '@/store/modules/AdsManager/filter'
import googleConversionActions from '@/store/modules/googleConversionActions'
import createCache from 'vuex-cache'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [createCache({ timeout: 10000 })],
  modules: {
    appConfig,
    adAccounts,
    adService,
    adsManager,
    allAds,
    auth,
    budget,
    campaigns,
    contactUs,
    coupons,
    exceptions,
    filter,
    integrations,
    interests,
    invoices,
    notifications,
    plans,
    postBoosting,
    newPostBoosting,
    profile,
    rules,
    googleConversionActions,
    rulesFolders,
    rulesMeta,
    sidebarControl,
    strategy
  }
})
