import { SET_VIEWS, SET_VISION, UPDATE_VISION, UPDATE_WIDTH } from '@/store/mutationsConstants/mutationSidebarControl'

interface ISidebarState {
  width: number
  sidebarOpenState: boolean,
  views: null
}

export default {
  state: {
    width: 0,
    sidebarOpenState: false,
    views: {}
  },
  getters: {
    get_sidebar_open_state (state: ISidebarState): boolean {
      return state.sidebarOpenState
    },
    get_width (state: ISidebarState): ISidebarState['width'] {
      return state.width
    },
    get_sidebar_views (state: ISidebarState) {
      return state.views
    }
  },
  mutations: {
    [UPDATE_VISION] (state: ISidebarState): void {
      state.sidebarOpenState = !state.sidebarOpenState
    },
    [UPDATE_WIDTH] (state: ISidebarState, data: number): void {
      state.width = data
    },
    [SET_VISION] (state: { sidebarOpenState: boolean }, value: boolean) {
      state.sidebarOpenState = value
    },
    [SET_VIEWS] (state: ISidebarState, data) {
      state.views = data
    }
  },
  actions: {
    changeVision (ctx: { commit: any }): void {
      ctx.commit(UPDATE_VISION)
    },
    setVision (ctx: { commit: any }, value: boolean) {
      ctx.commit(SET_VISION, value)
    },
    updateWidth (ctx: { commit: any }, data: number): void {
      ctx.commit(UPDATE_WIDTH, data)
    },
    setViews ({ commit }, data) {
      commit(SET_VIEWS, data)
    }
  }
}
