import { AxiosResponse } from 'axios'
import http from '@/http-common'
import urlGen from '@/utils/urlGen'
import IGoogleConversionAction from '@/models/IGoogleConversionAction'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  createConversionAction (data: IGoogleConversionAction): Promise<AxiosResponse> {
    return http.post(baseUrl + 'marketing/google/conversion_actions', data)
  },
  getConversionAction (ad_account_id: string, page: number, per_page: number) : Promise<AxiosResponse> {
    const url = new URL(baseUrl + 'marketing/google/conversion_actions/')
    const params = urlGen.addSearch({ ad_account_id, page, per_page })
    return http.get(url.toString() + '?' + params.toString())
  },
  updateConversionAction (id: string, data: IGoogleConversionAction) : Promise<AxiosResponse> {
    return http.patch(baseUrl + `marketing/google/conversion_actions/${id}`, data)
  },
  deleteConversionAction (id: string) : Promise<AxiosResponse> {
    return http.delete(baseUrl + `marketing/google/conversion_actions/${id}`)
  }
}
