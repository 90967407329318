







import Vue from 'vue'
export default Vue.extend({
  name: 'PreloaderLine',
  props: {
    maxWidth: {
      default: '100%'
    }
  }
})
