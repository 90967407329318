










import Vue from 'vue'

import Logo from '@/components/Logo.vue'
import Burger from '@/components/Burger.vue'
import HeaderAccountBlock from '@/components/HeaderAccountBlock.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'TheHeader',
  components: {
    Logo,
    Burger,
    HeaderAccountBlock
  },
  props: {
    sidebarMenuVisible: Boolean
  },
  computed: {
    ...mapGetters(['get_sidebar_open_state'])
  }
})
