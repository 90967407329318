export const SET_FACEBOOK = 'setFacebook'
export const SET_SNAPCHAT = 'setSnapchat'
export const SET_TIKTOK = 'setTiktok'
export const SET_SLACK = 'setSlack'
export const SET_LINKEDIN = 'setLinkedin'
export const SET_GOOGLE = 'setGoogle'
export const SET_TWITTER = 'setTwitter'
export const SET_SERVICE_PAGES = 'setServicePages'
export const DISABLE_SERVICE_PAGE = 'disableServicePage'
export const REPLACE_SERVICE_PAGE = 'replaceServicePage'
export const SET_SERVICE_ACCOUNT = 'setServiceAccount'
