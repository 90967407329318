import ICoupons from '@/models/ICoupons'
import { ActionTree, MutationTree } from 'vuex'
import BillingService from '@/services/api/BillingService'
import { ADD_COUPON, SET_COUPONS } from '@/store/mutationsConstants/mutationCoupons'
import Jsona from 'jsona'

const initialState = () : ICoupons => ({
  data: null
})
const mutations = <MutationTree<ICoupons>> {
  [SET_COUPONS]  (state: ICoupons, data: any) {
    state.data = data
  },
  [ADD_COUPON]  (state: ICoupons, data: any) {
    state.data.push(data)
  }
}

const actions = <ActionTree<any, any>> {
  async getBillingCoupons (context: any) {
    await BillingService.get_billing_coupons().then((res) => {
      const dataFormatter = new Jsona()
      context.commit(SET_COUPONS, dataFormatter.deserialize(res.data))
    })
  },
  async activateCoupon ({ dispatch, commit }, code) {
    await BillingService.activate_coupons(code).then((res) => {
      const dataFormatter = new Jsona()
      commit(ADD_COUPON, dataFormatter.deserialize(res.data))
      dispatch(
        'notifications/addNotification',
        { id: Date.now(), body: 'Coupons Activated!', type: 'success' },
        { root: true }
      )
    }).catch(() => {
      dispatch(
        'notifications/addNotification',
        { id: Date.now(), body: 'Coupon expired or not found', type: 'danger' },
        { root: true }
      )
    })
  }
}

const getters = {
  getCoupons (state: ICoupons) {
    return state
  }
}
export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
