import http from '@/http-common'
import urlGen from '@/utils/urlGen'
import { AxiosResponse } from 'axios'

function toQuery (query): string {
  if (query) {
    return `?${urlGen.addSearch(query)}`
  }
  return ''
}

type Data<T> = {
  data: T
}

export interface IAffiliateData {
  type: 'affiliate'
  attributes: {
    balance: number
    balance_pending: number
    earned: number
    purchases: number
    clicks: number
    min_withdrawal: number
    status: string
    link: `https://${string}`
  }
}

export interface IPagination {
  total: number,
  count: number,
  per_page: number,
  current_page: number,
  total_pages: number
}

export interface IAffiliateTransactions {
  data: Array<{
    type: 'transactions',
    attributes: Record<'status' | 'created_at' | 'payable_at', string>
      & Record<'user_id' | 'payment_id' | 'payout' | 'amount', number>
  }>,
  meta: { pagination: IPagination }
}

export interface IAffiliateWithdrawals {
  data: Array<{
    type: 'transactions',
    attributes: Record<'status' | 'created_at' | 'wallet', string>
      & Record<'amount', number>
  }>,
  meta: { pagination: IPagination }
}

interface IAffiliateClicks {
  data: Array<{
    type: 'transactions',
    attributes: Record<'ctr' | 'date', string>
      & Record<'clicks' | 'signups', number>
  }>,
  meta: { pagination: IPagination }
}

export interface IGraphicsData<T extends 'clicks' | 'sign_ups' = 'clicks' | 'sign_ups'> {
  data: Array<{
    date: string
  } & Record<T, number>>
}

const QUERY_PER_PAGE_5 = { per_page: 5 }

type CreateWithdrawalsBody = {
  wallet: string,
  wallet_data: string,
  amount: number
}

class AffiliateService {
  getAffiliate (): Promise<AxiosResponse<Data<IAffiliateData>>> {
    return http.get('/affiliate')
  }

  getTransactions (query: Record<string, string | number> = QUERY_PER_PAGE_5): Promise<AxiosResponse<IAffiliateTransactions>> {
    return http.get(`/affiliate/payments${toQuery(query)}`)
  }

  getWithdrawals (query: Record<string, string | number> = QUERY_PER_PAGE_5): Promise<AxiosResponse<IAffiliateWithdrawals>> {
    return http.get(`/affiliate/withdrawals${toQuery(query)}`)
  }

  getClicks (query?: Record<string, string | number>): Promise<AxiosResponse<IAffiliateClicks>> {
    return http.get(`/affiliate/clicks${toQuery(query)}`)
  }

  getClicksChart (): Promise<AxiosResponse<IGraphicsData<'clicks'>>> {
    return http.get('/affiliate/clicks-for-graphics')
  }

  getSignUpsChart (): Promise<AxiosResponse<IGraphicsData<'sign_ups'>>> {
    return http.get('/affiliate/sign-ups-for-graphics')
  }

  createWithdrawals (body: CreateWithdrawalsBody): Promise<AxiosResponse<IAffiliateWithdrawals['data'][number]>> {
    return http.post('/affiliate/withdrawals', body)
  }
}

export default new AffiliateService()
