












export default {
  name: 'MaintenanceModePage',
  computed: {
    landingUrl () {
      return process.env.VUE_APP_LANDING_URL
    }
  }
}
