import { MutationTree, ActionTree } from 'vuex'
import { saveTokenToCookie } from '@/http-common'
import ISessionState from '@/models/ISessionState'
import authService from '@/services/api/AuthService'
import {
  SET_SESSION,
  SET_ERRORS,
  RESET_STATE,
  SET_AUTH_MANUALLY
} from '@/store/mutationsConstants/mutationAuth'
import { RESET_PLANS } from '@/store/mutationsConstants/mutationPlans'
import { AxiosResponse } from 'axios'
import { deleteCookie } from '@/utils/cookie'

const initialState = (): ISessionState => ({
  accessToken: null,
  errors: [],
  auth_manually: window.localStorage.getItem('auth_manually') === 'true'
})

export interface ValidateResetTokenBody {
  email: string
  token: string
}

const mutations = <MutationTree<ISessionState>>{
  [SET_SESSION] (state: ISessionState, accessToken: string) {
    state.accessToken = accessToken
    saveTokenToCookie()
  },
  [SET_ERRORS] (state: ISessionState, data) {
    state.errors = data
  },
  [RESET_STATE] (state: ISessionState) {
    state.accessToken = false
    const hostname = document.location.hostname.split('.').slice(-2).join('.')
    deleteCookie({ key: 'access_token', domain: hostname })
    state.auth_manually = false
    window.localStorage.removeItem('auth_manually')
  },
  [SET_AUTH_MANUALLY] (state: ISessionState, value) {
    state.auth_manually = value
    localStorage.setItem('auth_manually', value)
  }
}

const actions = <ActionTree<ISessionState, any>>{
  async auth ({ dispatch, commit }, payload: { email: string; password: string }) {
    try {
      const response = await authService.login(payload)
      dispatch('setSession', response.data.access_token)
    } catch (e) {
      dispatch('exceptions/addExceptions', e.response.data, { root: true })
      dispatch('resetSession')
      throw e
    }
  },
  async authManual ({ dispatch }) {
    return dispatch('setAuthManually', true)
  },
  async refresh ({ dispatch }) {
    return authService.refreshToken()
      .then(response => {
        return dispatch('setSession', response.data.access_token)
      }).then(res => res)
      .catch((e) => {
        dispatch('exceptions/addExceptions', e.response.data, { root: true })
        dispatch('resetSession')
        throw e
      })
  },
  async logout ({ commit, dispatch }) {
    try {
      const response = await authService.logout()

      if (response.status === 204) {
        commit(RESET_STATE)
      }
      commit('profile/clearProfile', null, { root: true })
      dispatch('adsManager/clearAdsManager', null, { root: true })
      commit('plans/' + RESET_PLANS, null, { root: true })
    } catch (e) {
      if (e.response.status === 401) {
        commit(RESET_STATE)
      } else {
        throw e
      }
    }
  },
  sendForgotPasswordEmail (_, email: string) {
    return new Promise((resolve, reject) => {
      authService
        .sendForgotPasswordEmail(email)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  sendResetPasswordRequest (_, payload) {
    return new Promise((resolve, reject) => {
      authService
        .sendResetPasswordRequest(payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  validateResetToken (_, payload: ValidateResetTokenBody): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      authService
        .validateResetToken(payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  setErrors ({ commit }, data) {
    commit(SET_ERRORS, data)
  },
  setSession (context, session: string) {
    context.commit(SET_SESSION, session)
  },
  setAuthManually ({ commit }, value) {
    commit(SET_AUTH_MANUALLY, value)
  },
  resetSession (context) {
    context.commit(RESET_STATE)
  }
}

const getters = {
  access_token (state: ISessionState): string | null | false {
    return state.accessToken
  },
  is_authorized (state: ISessionState): boolean | null {
    return state.accessToken === null ? null : Boolean(state.accessToken)
  },
  auth_manually (state: ISessionState): boolean {
    return state.auth_manually
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
