import { AxiosResponse } from 'axios'
import http from '@/http-common'
// import IStrategy from '@/models/IStrategy'

export default {
  getFolders (service: string, page = 1): Promise<AxiosResponse> {
    return http.get(`/rules_folders/${service}?include[]=rules&page=${page}`)
  },
  renameFolder (service: string, data) {
    return http.patch(`/rules_folders/${service}/${data.id}`, { name: data.name })
  },
  createFolder (service: string, data) {
    return http.post(`/rules_folders/${service}`, data)
  },
  deleteFolder (service: string, data) {
    return http.delete(`/rules_folders/${service}/${data.id}`)
  }
}
