import { ActionTree, MutationTree } from 'vuex'
import Jsona from 'jsona'
import AdAccountsService from '@/services/api/AdAccountsService'
import IAdAccounts from '@/models/IAdAccounts'
import IAdAccount from '@/models/IAdAccount'
import _ from 'lodash'
import { ADD_ACCOUNTS, SET_ACCOUNTS, SET_LOADER, SET_SERVICE, RESET_STATE } from '@/store/mutationsConstants/mutationAdAccounts'

const initialState = (): IAdAccounts => ({ data: [], meta: null, loader: true, service: null })

const mutations = <MutationTree<IAdAccounts>>{
  [SET_ACCOUNTS] (state: IAdAccounts, data: IAdAccounts) {
    state.data = data.data
    state.meta = data.meta
  },
  [ADD_ACCOUNTS] (state: IAdAccounts, data: IAdAccounts) {
    state.data = state.data.concat(data.data)
    state.meta = data.meta
  },
  [SET_LOADER] (state: IAdAccounts, value: boolean) {
    state.loader = value
  },
  [SET_SERVICE] (state: IAdAccounts, value: string | null) {
    state.service = value
  },
  [RESET_STATE] (state: IAdAccounts) {
    state.data = initialState().data
    state.meta = initialState().meta
    state.loader = initialState().loader
    state.service = initialState().service
  }
}
const actions = <ActionTree<IAdAccounts, any>>{
  async loadAccounts ({ dispatch, rootGetters }, params = {}) {
    try {
      const page = params.page || 1
      params.search.service = params.search?.service || rootGetters['adService/getService']
      const response = await AdAccountsService.getAdAccounts(params.search, page)
      const dataFormatter = new Jsona()
      const callback = async () => {
        await dispatch('setService', params.search.service)
        if (
          response.data.meta.pagination.current_page <
          response.data.meta.pagination.total_pages
        ) {
          await dispatch('loadAccounts', { search: params.search, page: page + 1 })
        } else {
          await dispatch('setLoader', false)
        }
      }
      if (page === 1) {
        await dispatch('setAccounts', {
          data: dataFormatter.deserialize(response.data),
          meta: response.data.meta
        }).then(callback)
      } else {
        await dispatch('addAccounts', {
          data: dataFormatter.deserialize(response.data),
          meta: response.data.meta
        }).then(callback)
      }
    } catch (e) {
      console.log(e)
    }
  },
  setAccounts (context, rules: IAdAccounts) {
    context.commit(SET_ACCOUNTS, rules)
  },
  addAccounts (context, rules: IAdAccounts) {
    context.commit(ADD_ACCOUNTS, rules)
  },
  setLoader (context, value: boolean) {
    context.commit(SET_LOADER, value)
  },
  setService ({ commit }, value) {
    commit(SET_SERVICE, value)
  },
  resetState ({ commit }) {
    commit(RESET_STATE)
  }
}
const getters = {
  getLoader (state: IAdAccounts) {
    return state.loader
  },
  getAllAccounts (state: IAdAccounts): IAdAccount[] {
    return state.data
  },
  pagination (state: IAdAccounts): Record<string, any> {
    return state.meta
  },
  getById: (state: IAdAccounts) => (id: number): IAdAccount => {
    // eslint-disable-next-line eqeqeq
    return state.data.find(a => a.id == id)
  },
  activeAccounts (state) {
    return _.filter(state.data, a => a.status === 1000)
  },
  getService (state: IAdAccounts): string | null {
    return state.service
  }
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
