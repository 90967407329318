import type { Timezone } from '@/constants/Timezones'
import type { AxiosResponse } from 'axios'
import http from '@/http-common'
import ICreateAdAccount from '@/models/ICreateAdAccount'
import IAddPages from '@/models/IAddPages'

type DeleteData = {
  id: string,
  service: string
}

export default {
  get_ad_accounts (service: string, requestBody = {}): Promise<AxiosResponse> {
    return http.get(`/marketing/${service}/ad-accounts`, { params: requestBody })
  },
  get_slack_workspace (service: string, requestBody = {}): Promise<AxiosResponse> {
    return http.get(`/integrations/${service}/workspace`, { params: requestBody })
  },
  get_slack_channels (): Promise<AxiosResponse> {
    return http.get('/integrations/slack/channels')
  },
  create_ad_account (service: string, data: ICreateAdAccount): Promise<AxiosResponse> {
    return http.post(`/marketing/${service}/ad-accounts`, data)
  },
  delete_ad_account (data: DeleteData): Promise<AxiosResponse> {
    return http.delete(`/marketing/${data.service}/ad-accounts/${data.id}`)
  },
  add_pages (data: {service: string, pages: IAddPages, token: string}): Promise<AxiosResponse> {
    return http.post('/marketing/' + data.service + '/pages/', { pages: data.pages, t: data.token })
  },
  getServicePages (service: string, queryString?: string): Promise<AxiosResponse> {
    return http.get(`/marketing/${service}/pages${queryString || ''}`)
  },
  getServicePagesExternal (service: string, token: string): Promise<AxiosResponse> {
    return http.get(`/marketing/${service}/pages/from-external?t=` + token)
  },
  enablingPages (service: string, page_id: `${number}` | number, is_enabled: boolean): Promise<AxiosResponse> {
    return http.patch(`/marketing/${service}/pages/${page_id}`, { is_enabled })
  },
  delete_slack_workspace (data: DeleteData): Promise<AxiosResponse> {
    return http.delete(`/integrations/${data.service}/workspace/${data.id}`)
  },
  updateAdAccountTimezone (service: string, adAccountId: string, timezone: Timezone['id']): Promise<AxiosResponse> {
    return http.patch(`marketing/${service}/ad-accounts/${adAccountId}`, { timezone })
  },
  toggleAdAccount (service: string, id: string, is_enabled: boolean): Promise<AxiosResponse> {
    return http.patch(`marketing/${service}/ad-accounts/${id}`, { is_enabled })
  }
}
