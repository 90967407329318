var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.get_sidebar_open_state),expression:"!get_sidebar_open_state"}],staticClass:"blackout",on:{"click":function($event){return _vm.changeVision()}}}),_c('div',{staticClass:"sidebar",class:{ 'sidebar-minimize': _vm.get_sidebar_open_state, 'sidebar-without-menu': !_vm.menuVisible }},[_c('div',{staticClass:"sidebar_header"},[_c('Logo'),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.get_sidebar_open_state),expression:"!get_sidebar_open_state"}],staticClass:"burger_menu_img",attrs:{"src":require("../assets/img/burger.svg"),"alt":""},on:{"click":function($event){return _vm.changeVision()}}})],1),_c('HeaderAccountBlock'),(_vm.menuVisible)?_c('div',{staticClass:"sidebar__menu"},[_c('ul',{staticClass:"menu__list d-flex flex-column align-items-start"},[_vm._l((_vm.menuListFiltered),function(item,index){return _c('li',{key:index,staticClass:"menu__list--item d-flex flex-column align-items-start",on:{"click":function($event){return _vm.go_to(item, index)}}},[_c('router-link',{staticClass:"title d-flex",class:{ active: _vm.check_route(item) },style:({ background: _vm.check_route(item) ? item.color : '' }),attrs:{"to":{ path: item.submenu.length ? item.submenu[0].href : item.href },"target":"_self"}},[_c('div',{staticClass:"icon d-flex align-items-center"},[_c('img',{attrs:{"src":item.icon,"alt":"account"}})]),_c('div',{staticClass:"text d-flex align-items-center justify-content-between",class:{
                'text-hide': _vm.get_sidebar_open_state,
                'text-show': !_vm.get_sidebar_open_state
              }},[_c('span',[_vm._v(_vm._s(item.title))])])]),(!!item.submenu)?_c('ul',{staticClass:"submenu d-flex flex-column",class:{
              'minimize-submenu':
                item.submenu.length > 0 && _vm.get_sidebar_open_state
            },style:({
              height: item.submenu_state ? item.submenu.length * 45 + 'px' : '0'
            }),on:{"click":function($event){return _vm.go_to_second_level()}}},_vm._l((item.submenu),function(submenu_item,index){return _c('router-link',{key:index,staticClass:"submenu__item d-flex align-items-center",class:{
                active:
                  '/' + _vm.routePathName.split('/')[1].toLowerCase() ===
                    item.href &&
                  _vm.routePathName.split('/')[2] ===
                    submenu_item.href.split('/')[2]
              },attrs:{"to":{ path: submenu_item.href },"target":"_self"}},[_c('p',[_vm._v(_vm._s(submenu_item.title))])])}),1):_vm._e()],1)}),_c('li',{staticClass:"menu__safari-padding"})],2)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }