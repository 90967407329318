export const SET_STATE_FIELD = 'setStateField'
export const SET_STATE_PROP = 'setStateProp'
export const SET_STATE_FIELD_PROP = 'setStateFieldProp'
export const SET_AUDIENCE_INSPECTOR_PROP = 'setAudienceInspectorProp'
export const SET_CAMPAIGN_PERFORMANCE = 'setCampaignPerformance'
export const SET_CAMPAIGN_PERFORMANCE_META = 'setCampaignPerformanceMeta'
export const UPDATE_CHARTS = 'updateCharts'
export const OVERRIDE_PERFORMANCE_CHART_TITLES = 'overridePerformanceChartTitles'
export const ADD_CHART = 'addChart'
export const REMOVE_CHART = 'removeChart'
export const CHANGE_SHOW_STATE = 'changeShowState'
export const CAMPAIGN_PERFORMANCE_CHART_TITLES = 'campaignPerformanceChartTitles'
export const CLEAR_STATE = 'clearState'
