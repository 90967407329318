






import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Burger',
  methods: {
    changeVision () {
      this.$store.dispatch('changeVision')
    }
  },
  data () {
    return {
      deviceWidth: 0
    }
  },
  created () {
    this.deviceWidth = window.innerWidth
  },
  watch: {
    deviceWidth (deviceWidth) {
      if (deviceWidth < 1250) {
        this.$store.dispatch('changeVision')
      }
    }
  },
  computed: {
    ...mapGetters(['get_sidebar_open_state'])
  }
})
