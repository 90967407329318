












import Vue from 'vue'

export default Vue.extend({
  name: 'WithoutAdAccount',
  props: ['service'],
  methods: {
    serviceToUpper () {
      return this.selectService[0].toUpperCase() + this.selectService.slice(1)
    }
  },
  computed: {
    selectService () {
      return this.service ? this.service : 'facebook'
    }
  }
})
