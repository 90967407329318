import IGoogleConversionAction from '@/models/IGoogleConversionAction'
import { ActionTree, MutationTree } from 'vuex'
import Jsona from 'jsona'
import GoogleConversionActionsService from '@/services/api/GoogleConversionActionsService'

interface IPagination {
  total: number,
  count: number,
  per_page: number,
  current_page: number,
  total_pages: number
}

interface IInitState {
  data: Array<IGoogleConversionAction>
  pagination: IPagination
}

const initState: IInitState = {
  data: [],
  pagination: {
    total: 1,
    count: 1,
    per_page: 10,
    current_page: 1,
    total_pages: 1
  }
}

const initialState = () => (initState)

const mutations = <MutationTree<IInitState>>{
  setConversionAction (state, data) {
    state.data = data.data
    state.pagination = data.meta
  }
}
const actions = <ActionTree<IInitState, any>> {
  async createConversionAction ({ dispatch, commit }, action) {
    return new Promise((resolve, reject) => {
      GoogleConversionActionsService.createConversionAction(action)
        .then((response) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Conversion action created!', type: 'success' },
            { root: true }
          )
          const dataFormatter = new Jsona()
          const dataDeserialized = { data: dataFormatter.deserialize(response.data) }
          resolve(dataDeserialized.data)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  async updateConversionAction ({ dispatch, commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      GoogleConversionActionsService.updateConversionAction(id, data)
        .then((response) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Conversion action update!', type: 'success' },
            { root: true }
          )
          resolve(response.status)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  async loadConversionAction ({ commit }, { ad_account_id, page = 1, per_page = 10 }) {
    return new Promise((resolve, reject) => {
      GoogleConversionActionsService.getConversionAction(ad_account_id, page, per_page)
        .then((response) => {
          const dataFormatter = new Jsona()
          const dataDeserialized = { data: dataFormatter.deserialize(response.data), meta: response.data.meta.pagination }
          commit('setConversionAction', dataDeserialized)
          resolve(response.status)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async deleteConversionAction ({ dispatch, commit }, id: string) {
    return new Promise((resolve, reject) => {
      GoogleConversionActionsService.deleteConversionAction(id)
        .then((response) => {
          dispatch(
            'notifications/addNotification',
            { id: Date.now(), body: 'Conversion action delete!', type: 'success' },
            { root: true }
          )
          resolve(response.status)
        }).catch((error) => {
          reject(error)
        })
    })
  }
}
const getters = {
  getConversionAction (state: IInitState): Array<IGoogleConversionAction> {
    return state.data
  },
  getConversionActionPagination (state: IInitState): IPagination {
    return state.pagination
  },
  getById: (state: IInitState) => (id:string):IGoogleConversionAction => {
    return state.data.find((el) => el.id === id)
  }
}
export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}
