import http from '@/http-common'
import URI from 'urijs'

const baseUrl = String(process.env.VUE_APP_API_BASE_URL)

export default {
  getLocales () {
    return http.get('/fb_interests/locales')
  },
  getAdsets (data) {
    return http.get('/fb_interests/adsets?ad_account=' + data.ad_account + '&campaign=' + data.campaign)
  },
  getCampaigns (data) {
    return http.get(new URI(baseUrl + 'fb_interests/get_campaigns').addSearch(data).toString())
  },
  getCollections (data) {
    return http.get(new URI(baseUrl + 'fb_interests/collections_list_quantity').addSearch(data).toString())
  },
  getCollectionData (data) {
    return http.post('/fb_interests/get_collection/' + data.id)
  },
  createCollection (data) {
    return http.post('/fb_interests/create_collection', data)
  },
  cloneCollection (data) {
    return http.post('/fb_interests/cloning_collection', data)
  },
  deleteCollection (data) {
    return http.post('/fb_interests/delete_collection', data)
  },
  searchInterests (data) {
    return http.post('/fb_interests/search', data)
  },
  searchSuggestions (data) {
    return http.post('/fb_interests/suggestion', data)
  },
  updateCollection (data) {
    return http.post('/fb_interests/update_collection', data)
  },
  mergeCollections (data) {
    return http.post('/fb_interests/merge_collection', data)
  }
}
